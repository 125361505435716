import React from "react";
import { ProgressBar } from "react-bootstrap";
import {
  CustomerType,
  OrderedCustomerRegistrationStatus,
} from "../domainObject/DomainDataType";

const CustomerRegProgressBarType = {
  PHONE_NUMBER_VERIFICATION: "Phone number verification",
  ACCOUNT_CREATION: "Account creation",
  COMPANY_REGISTRATION: "Company registration",
  PERSONAL_INFO_REGISTRATION: "Personal Information",
  PERSONAL_ATTACHMENT_REGISTRATION: "Upload personal documents",
  COMPANY_ATTACHMENT_REGISTRATION: "Upload company documents",
};

export const CustomerRegistrationProgressBar = (props) => {
  const Active_MARK = "info";
  const FINISHED_MARK = "success";
  const FUTURE_MARK = "danger";
  const COMPANY_REGISTRATION_BAR_SIZE = 17;
  const PERSONAL_REGISTRATION_BAR_SIZE = 25;

  function getRegistrationStatusOrdinal() {
    switch (props.registrationStatus) {
      case OrderedCustomerRegistrationStatus.NEW_REGISTRATION:
      case OrderedCustomerRegistrationStatus.OTP_GENERATION: {
        return 0;
      }
      case OrderedCustomerRegistrationStatus.OTP_VERIFICATION: {
        return 1;
      }
      case OrderedCustomerRegistrationStatus.PHONE_NUMBER_REGISTERED: {
        return 2;
      }
      case OrderedCustomerRegistrationStatus.COMPANY_REGISTERED: {
        return 3;
      }
      case OrderedCustomerRegistrationStatus.PERSONAL_INFO_REGISTERED: {
        return 4;
      }
      case OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED: {
        return 5;
      }

      case OrderedCustomerRegistrationStatus.COMPANY_ATTACHMENT_REGISTERED: {
        return 6;
      }

      default: {
        return -1;
      }
    }
  }

  function getProgressBar(barType, size, key) {
    let status = getRegistrationStatusOrdinal();
    let bar = null;
    switch (barType) {
      case CustomerRegProgressBarType.PHONE_NUMBER_VERIFICATION: {
        if (status === 0) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.PHONE_NUMBER_VERIFICATION}
              variant={Active_MARK}
              now={size}
              key={key}
            />
          );
        } else {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.PHONE_NUMBER_VERIFICATION}
              variant={FINISHED_MARK}
              now={size}
              key={key}
            />
          );
        }
        break;
      }
      case CustomerRegProgressBarType.ACCOUNT_CREATION: {
        if (status === 0) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.ACCOUNT_CREATION}
              variant={FUTURE_MARK}
              now={size}
              key={key}
            />
          );
        } else if (status === 1) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.ACCOUNT_CREATION}
              variant={Active_MARK}
              now={size}
              key={key}
            />
          );
        } else {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.ACCOUNT_CREATION}
              variant={FINISHED_MARK}
              now={size}
              key={key}
            />
          );
        }
        break;
      }
      case CustomerRegProgressBarType.COMPANY_REGISTRATION: {
        if (status < 2) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.COMPANY_REGISTRATION}
              variant={FUTURE_MARK}
              now={size}
              key={key}
            />
          );
        } else if (status === 2) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.COMPANY_REGISTRATION}
              variant={Active_MARK}
              now={size}
              key={key}
            />
          );
        } else {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.COMPANY_REGISTRATION}
              variant={FINISHED_MARK}
              now={size}
              key={key}
            />
          );
        }
        break;
      }
      case CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION: {
        if (status <= 1) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION}
              variant={FUTURE_MARK}
              now={size}
              key={key}
            />
          );
        } else if (status > 1 && status < 4) {
          if (status === 2 && props.customerType === CustomerType.INDIVIDUAL) {
            bar = (
              <ProgressBar
                animated
                label={CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION}
                variant={Active_MARK}
                now={size}
                key={key}
              />
            );
          } else if (
            status === 3 &&
            props.customerType === CustomerType.CORPORATE
          ) {
            bar = (
              <ProgressBar
                animated
                label={CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION}
                variant={Active_MARK}
                now={size}
                key={key}
              />
            );
          } else {
            bar = (
              <ProgressBar
                animated
                label={CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION}
                variant={FUTURE_MARK}
                now={size}
                key={key}
              />
            );
          }
        } else {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION}
              variant={FINISHED_MARK}
              now={size}
              key={key}
            />
          );
        }
        break;
      }
      case CustomerRegProgressBarType.PERSONAL_ATTACHMENT_REGISTRATION: {
        if (status < 4) {
          bar = (
            <ProgressBar
              animated
              label={
                CustomerRegProgressBarType.PERSONAL_ATTACHMENT_REGISTRATION
              }
              variant={FUTURE_MARK}
              now={size}
              key={key}
            />
          );
        } else if (status === 4) {
          bar = (
            <ProgressBar
              animated
              label={
                CustomerRegProgressBarType.PERSONAL_ATTACHMENT_REGISTRATION
              }
              variant={Active_MARK}
              now={size}
              key={key}
            />
          );
        } else {
          bar = (
            <ProgressBar
              animated
              label={
                CustomerRegProgressBarType.PERSONAL_ATTACHMENT_REGISTRATION
              }
              variant={FINISHED_MARK}
              now={size}
              key={key}
            />
          );
        }
        break;
      }
      case CustomerRegProgressBarType.COMPANY_ATTACHMENT_REGISTRATION: {
        if (status < 5) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.COMPANY_ATTACHMENT_REGISTRATION}
              variant={FUTURE_MARK}
              now={size}
              key={key}
            />
          );
        } else if (status === 5) {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.COMPANY_ATTACHMENT_REGISTRATION}
              variant={Active_MARK}
              now={size}
              key={key}
            />
          );
        } else {
          bar = (
            <ProgressBar
              animated
              label={CustomerRegProgressBarType.COMPANY_ATTACHMENT_REGISTRATION}
              variant={FINISHED_MARK}
              now={size}
              key={key}
            />
          );
        }
        break;
      }
      default:
        return null;
    }
    return bar;
  }
  function getCorporateRegistrationProgressBar() {
    return (
      <ProgressBar>
        {getProgressBar(
          CustomerRegProgressBarType.PHONE_NUMBER_VERIFICATION,
          COMPANY_REGISTRATION_BAR_SIZE,
          1
        )}
        {getProgressBar(
          CustomerRegProgressBarType.ACCOUNT_CREATION,
          COMPANY_REGISTRATION_BAR_SIZE,
          2
        )}
        {getProgressBar(
          CustomerRegProgressBarType.COMPANY_REGISTRATION,
          COMPANY_REGISTRATION_BAR_SIZE,
          3
        )}
        {getProgressBar(
          CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION,
          COMPANY_REGISTRATION_BAR_SIZE,
          4
        )}
        {getProgressBar(
          CustomerRegProgressBarType.PERSONAL_ATTACHMENT_REGISTRATION,
          COMPANY_REGISTRATION_BAR_SIZE,
          5
        )}
        {getProgressBar(
          CustomerRegProgressBarType.COMPANY_ATTACHMENT_REGISTRATION,
          COMPANY_REGISTRATION_BAR_SIZE,
          6
        )}
      </ProgressBar>
    );
  }

  function getIndividualRegistrationProgressBar() {
    return (
      <ProgressBar>
        {getProgressBar(
          CustomerRegProgressBarType.PHONE_NUMBER_VERIFICATION,
          PERSONAL_REGISTRATION_BAR_SIZE,
          1
        )}
        {getProgressBar(
          CustomerRegProgressBarType.ACCOUNT_CREATION,
          PERSONAL_REGISTRATION_BAR_SIZE,
          2
        )}
        {getProgressBar(
          CustomerRegProgressBarType.PERSONAL_INFO_REGISTRATION,
          PERSONAL_REGISTRATION_BAR_SIZE,
          3
        )}
        {getProgressBar(
          CustomerRegProgressBarType.PERSONAL_ATTACHMENT_REGISTRATION,
          PERSONAL_REGISTRATION_BAR_SIZE,
          4
        )}
      </ProgressBar>
    );
  }

  function getProgress() {
    let bars;
    if (props.customerType === CustomerType.CORPORATE) {
      bars = getCorporateRegistrationProgressBar();
    } else {
      bars = getIndividualRegistrationProgressBar();
    }
    return bars;
  }

  return <div>{getProgress()}</div>;
};
