import React from "react";

import {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import { CustomerSupportFilter } from "../customerPortal/CustPortalSupportListDataInfo";


import { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";

function buttonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.EMPLOYEE_PORTAL.CUSTOMER_SUPPORT_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>
  );
}

export const EmplPorCustSupportListDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.SUPPORT.SEARCH,
  SelectFilterOption: CustomerSupportFilter,

  defaultSorted: [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "createdDate",
      text: "Creation time",
      filter: dateFilter(),
      sort: true,
    },

    {
      dataField: "reference",
      text: "Reference",
      filter: textFilter(),
    },
    {
      dataField: "customerInfo.user.userName",
      text: "Username",
      filter: textFilter(),
    },
    {
      dataField: "customerInfo.personalInfo.name",
      text: "Name",
      filter: textFilter(),
    },
    {
      dataField: "customerInfo.companyInfo.name",
      text: "Company name",
      filter: textFilter(),
    },
    {
      dataField: "connectionInfo.iptspNumber",
      text: "Connection number",
      filter: textFilter(),
    },
    {
      dataField: "type",
      text: "Issue type",
      filter: selectFilter({
        options: CustomerSupportFilter.IssueType.option,
      }),
    },
    {
      dataField: "summery",
      text: "Issue summery",
      filter: selectFilter({
        options: CustomerSupportFilter.IssueSummery.option,
      }),
      sort: true,
    },
    // {
    //   dataField: "status",
    //   text: "Issue status",
    //   filter: selectFilter({
    //     options: CustomerSupportFilter.IssueStatus.option,
    //   }),
    //   sort: true,
    // },

    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: buttonAdd,
    },
  ],
};

export default EmplPorCustSupportListDataInfo;
