import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../portal/route/Routes";

import pclLogo from "../portal/image/pcl.png";
import pclTagline from "../portal/image/tagline.png";
import sampleImage from "./image/sampleImage.jpg";
import underConstruction from "./image/under_construction.png";

const PromotionalWebsite = (props) => {
  return (
    <div>
      <header>
        <nav class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0">
          <a class="navbar-light col-sm-3 col-md-2 mr-0" href="#">
            <img src={pclLogo} width="180" />
          </a>
          <img src={pclTagline} />
          <ul class="navbar-nav px-3">
            <li class="nav-item text-nowrap"></li>
          </ul>
        </nav>
      </header>
      <img src={sampleImage} width="100%" />
      <br></br>
      <br></br>
      <h2 className="text-xl-center font-weight-bold">
        Our homepage is under construction. But you can use our IPTSP services.
        <br></br>
        Request new connection :{" "}
        <Link to={Routes.REGISTRATION.CUSTOMER} target="_blank">
          Register
        </Link>
        <br></br>
        Login to portal:{" "}
        <Link to={Routes.LOG_IN} target="_blank">
          Login
        </Link>
      </h2>
      <br></br>
      <br></br>
      <img src={underConstruction} width="100%" />
    </div>
  );
};

export default PromotionalWebsite;
