import React from "react";
import { withRouter } from "react-router";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import CustomerCallSummaryReportDataInfo from "./CustomerCallSummayrReportDataInfo";


class CustomerCallSummaryReportPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() { }



    render() {
        return (
            <div>
                <ReportWithSearchAndFilterTable
                    dataInfo={CustomerCallSummaryReportDataInfo} />
            </div>
        );
    }
}

export default withRouter(CustomerCallSummaryReportPanel);
