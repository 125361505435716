class Division {
  id;
  name;
  bengaliName;
}

class Zone {
  id;
  name;
}

class District {
  id;
  name;
  Division;
  bengaliName;
}

class Upazila {
  id;
  name;
  District;
  Zone;
  bengaliName;
}

const LocationDomain = {
  Division,
  Zone,
  District,
  Upazila,
};

export default LocationDomain;
