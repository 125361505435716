import React from "react";
import { Card, Table } from "react-bootstrap";
import { VerticalTableRow } from "../../common/VerticalTableRow";

export const CustomerActivationData = (props) => {
  function printData() {
    let row = [];
    for (const [key, value] of Object.entries(props.data)) {
      if (key === "destination") {
        //SPECIAL Case for Inbound Did destination
        row.push(<VerticalTableRow name={key} data={"SIP/" + props.number} />);
      } else {
        if (props.numberFields.indexOf(key) > -1) {
          row.push(<VerticalTableRow name={key} data={props.number} />);
        }  else {
          row.push(<VerticalTableRow name={key} data={value} />);
        }
      }
    }
    return row;
  }
  return (
    <div class="d-flex justify-content-center">
      <Card className="card w-75" body>
        <Table responsive striped bordered hover size="sm">
          <tbody>{printData()}</tbody>
        </Table>
      </Card>
    </div>
  );
};
