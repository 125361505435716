import React from "react";

import pclLogo from "../image/pcl.png";
import pclTagline from "../image/tagline.png";
import ProfileMenu from "./ProfileMenu";

export const CommonHeader = (props) => {
  return (
    <header>
      <nav class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0">
        <a class="navbar-light col-sm-3 col-md-2 mr-0" >
        <div style={{textAlign:"center",padding:"5px"}} ><img src={pclLogo} width="180" /></div>
        </a>
        <span className="d-none-xxs"><img src={pclTagline} /></span>
        <span className="d-none-xxs">
        <ul class="navbar-nav px-3">
          <li class="nav-item text-nowrap"></li>
          <ProfileMenu  />
        </ul>
        </span>
      </nav>
    </header>
  );
};
