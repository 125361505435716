import { textFilter } from "react-bootstrap-table2-filter";
import { RemoteURL } from "../helper/RestService";

const ActiveRegistrationDataInfo = {
  remoteApiUrl: RemoteURL.BTRC_PANEL.ACTIVE_REGISTRATIONS,
 
  defaultSorted: [
    {
      dataField: "username",
      order: "asc",
    },
  ],
  columns: [
    {
      dataField: "username",
      text: "User name",
      style: { "white-space": "wrap" },
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "ipAddress",
      text: "IP Address",
      filter: textFilter(),
      sort: true,
    },

    {
      dataField: "userAgent",
      text: "User Agent",
      filter: textFilter(),
      sort: true,
    },
  ],
};

export default ActiveRegistrationDataInfo;
