
import React from "react";
import { withRouter } from "react-router";
import { RemoteURL } from "../helper/RestService";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { numberNotAssignedFormatter } from "../customerPortal/CustomerConnectionPanel";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import { CONNECTION_TABLE_DEFAULT_SORTED, CONNECTION_TABLE_FILTER_OPTION, employeeConnectionDetailButtonAdd } from "./EmployeeConnectionListPanel";

class EmployeeDiscardedConnectionListPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  

  EMPLOYEE_CONNECTION_LIST_DATA_INFO = {
    remoteApiUrl: RemoteURL.CONNECTION.DISCARDED_LIST,
    SelectFilterOption: CONNECTION_TABLE_FILTER_OPTION,

    defaultSorted: CONNECTION_TABLE_DEFAULT_SORTED,
    columns: [
      {
        dataField: "createdDate",
        text: "Creation time",
        filter: dateFilter(),
        sort: true,
      },
      {
        dataField: "statusTimestamp",
        text: "Discarded time",
        filter: dateFilter(),
        sort: true,
      },
      {
        dataField: "customer.user.userName",
        text: "Customer id",
        sort: true,
        formatter: numberNotAssignedFormatter,
        filter: textFilter(),
      },
      {
        dataField: "iptspNumber",
        text: "IPTSP number",
        sort: true,
        formatter: numberNotAssignedFormatter,
        filter: textFilter(),
      },
      {
        dataField: "productInfo.accountType",
        text: "Product Type",
        sort: true,
        filter: selectFilter({
          options: CONNECTION_TABLE_FILTER_OPTION.AccountType.option,
        }),
      },

      {
        dataField: "status",
        text: "Status",
      },

      {
        dataField: "",
        text: "Detail",
        isDummyField: true,
        formatter: employeeConnectionDetailButtonAdd
      },
    ],
  }


  render() {
    return (
      <div>
        <div>
          <ReportWithSearchAndFilterTable
            dataInfo={this.EMPLOYEE_CONNECTION_LIST_DATA_INFO}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(EmployeeDiscardedConnectionListPanel);
