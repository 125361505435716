import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { REGEX } from "../../helper/Utility";
import { RequiredMarker } from "./RequiredMarker";

export const PhoneNumberForm = (props) => {
  const [isInvalid, setIsInvalid] = useState(props.isInvalid);

  function checkAndSetContactNo(value) {
    if (
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
    ) {
      if (value.length >= 11) {
        value = value.slice(0, 11);
      }
      props.setValue(value);
    }
  }

  return (
    <div>
      <Form.Group controlId="formGridAddress2">
        <Form.Label>
          {props.label != null ? props.label : "Phone number"}
        </Form.Label>
        {" "}<RequiredMarker/>
        <Form.Control
          type="tel"
          isInvalid={isInvalid}
          value={props.value}
          onChange={(e) => {
            setIsInvalid(false);
            checkAndSetContactNo(e.target.value);
          }}
          placeholder="Contact No"
          required
          pattern={REGEX.PHONE_NUMBER_CHECK}
          readOnly={props.readOnly != null ? props.readOnly : false}
        />
        <Form.Control.Feedback type="invalid">
          {isInvalid
            ? "Phone number already exist!"
            : "Please input valid phone number"}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
