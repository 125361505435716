import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import { RequiredMarker } from "../common/form/RequiredMarker";
import { MAX_FILE_UPLOAD_SIZE_IN_BYTES } from "../config/Config";

export const CompanyAttachmentForm = (props) => {
  const [isAuthorizationInvalid, setIsAuthorizationInvalid] = useState(false);
  const [isTradeLicenseInvalid, setIsTradeLicenseInvalid] = useState(false);
  const [isTinInvalid, setIsTinInvalid] = useState(false);
  const [isBinInvalid, setIsBinInvalid] = useState(false);

  function checkFileTypePDFAndSize(inputFile, setterFunction, propsSetterFunction) {
    if (inputFile != null) {
      let fileExtension = inputFile.type;
      let fileSize = inputFile.size;
      let validType = fileExtension.includes("pdf");
      if (!validType || fileSize > MAX_FILE_UPLOAD_SIZE_IN_BYTES) {
        setterFunction(true);
        propsSetterFunction(true);
      } else {
        setterFunction(false);
        propsSetterFunction(false);
      }
    }
  }

  function checkAuthorizationToPersonnel(inputFile) {
    checkFileTypePDFAndSize(inputFile, setIsAuthorizationInvalid,props.setIsAuthorizationToPersonnelInvalid);
  }

  function checkTradeLicense(inputFile) {
    checkFileTypePDFAndSize(inputFile, setIsTradeLicenseInvalid, props.setIsTradeLicenseInvalid);
  }

  function checkTin(inputFile) {
    checkFileTypePDFAndSize(inputFile, setIsTinInvalid, props.setIsTinInvalid);
  }

  function checkBin(inputFile) {
    checkFileTypePDFAndSize(inputFile, setIsBinInvalid, props.setIsBinInvalid);
  }

  return (
    <div>
      <Form.Group controlId="formFile" className="singleRowField mb-3">
        <Form.Label>
          Authorization to Personnel PDF(Size must be within 3MB)
        </Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isAuthorizationInvalid}
          onChange={(e) => {
            let inputFile = e.target.files[0];
            props.setAuthorizationToPersonnel(inputFile);
            checkAuthorizationToPersonnel(inputFile);
          }}
          required
        />
        <Form.Control.Feedback type="invalid">
          {props.authorizationToPersonnel === null
            ? "Please upload Authorization to personnel"
            : "Authorization to personnel must be pdf . Size must be within 3MB."}
        </Form.Control.Feedback>
      </Form.Group>
      <br></br>
      <Form.Group controlId="formFile" className="singleRowField mb-3">
        <Form.Label>Trade License PDF(Size must be within 3MB)</Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isTradeLicenseInvalid}
          onChange={(e) => {
            let inputFile = e.target.files[0];
            props.setTradeLicense(inputFile);
            checkTradeLicense(inputFile);
          }}
          required
        />
        <Form.Control.Feedback type="invalid">
          {props.tradeLicense === null
            ? "Please upload Trade license"
            : "Trade license must be pdf . Size must be within 3MB."}
        </Form.Control.Feedback>
      </Form.Group>
      <br></br>
      <Form.Group controlId="formFile" className="singleRowField mb-3">
        <Form.Label> TIN Certificate PDF(Size must be within 3MB)</Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isTinInvalid}
          onChange={(e) => {
            let inputFile = e.target.files[0];
            props.setTin(inputFile);
            checkTin(inputFile);
          }}
          required
        />
        <Form.Control.Feedback type="invalid">
          {props.tin === null
            ? "Please upload TIN certificate"
            : "TIN certificate must be pdf . Size must be within 3MB."}
        </Form.Control.Feedback>
      </Form.Group>
      <br></br>
      <Form.Group controlId="formFile" className="singleRowField mb-3">
        <Form.Label> BIN Certificate PDF(Size must be within 3MB)</Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isBinInvalid}
          onChange={(e) => {
            let inputFile = e.target.files[0];
            props.setBin(inputFile);
            checkBin(inputFile);
          }}
          required
        />
        <Form.Control.Feedback type="invalid">
          {props.bin === null
            ? "Please upload BIN certificate"
            : "BIN certificate must be pdf . Size must be within 3MB."}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
