import React from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import MonthlyReportTable from "./MonthlyReportTable";
import SubscriberDataInfo from "./SubscriberDataInfo";
import IptspIptspCallDataInfo from "./IptspIptspCallDataInfo";
import IcxIptspCallDataInfo from "./IcxIptspCallDataInfo";
import CapacityOfInstallationReport from "./CapacityOfInstallationReport";
import { MonthName } from "../../helper/Utility";
import moment from "moment";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const TabList = {
  ACTIVE_SUBSCRIBER: "ACTIVE_SUBSCRIBER",
  PROVISIONAL_SUBSCRIBER: "PROVISIONAL_SUBSCRIBER",
  IPTSP_IPTSP_CALL_INFO: "IPTSP_IPTSP_CALL_INFO",
  ICX_IPTSP_CALL_INFO: "ICX_IPTSP_CALL_INFO",
  CAPACITY_OF_INSTALLATION: "CAPACITY_OF_INSTALLATION",
};

const Body = (props) => <div>{props.children}</div>;
class MonthlyReport extends React.Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setSpin = this.setSpin.bind(this);
    var currentTime = new Date();
    var currentYear = currentTime.getFullYear();
    var currentMonth = currentTime.getMonth() + 1;
    this.state = {
      selectedYear: currentYear,
      selectedMonth: currentMonth,
      selectedTab: TabList.ACTIVE_SUBSCRIBER,
      spin: false,
      selectedTabContent: (
        <MonthlyReportTable
          remoteApiUrl={SubscriberDataInfo.getActiveSubscriberDataRemoteUrl()}
          thead={SubscriberDataInfo.thead}
          transformRemoteDataToTableRows={
            SubscriberDataInfo.transformRemoteDataToTableRows
          }
          year={currentYear}
          month={currentMonth}
          setSpinChild={this.setSpin}
        />
      ),
    };
  }

  handleDateChange(event) {
    var year = event.format("Y");
    var month = event.format("M");
    let Type = this.state.selectedTabContent.type;
    let props = this.state.selectedTabContent.props;
    let currentTab = <Type {...props} {...{ year: year, month: month }}></Type>;
    this.setState({
      selectedYear: year,
      selectedMonth: month,
      selectedTabContent: currentTab,
    });
  }

  handleTabChange(activeTab, activeTabContent, event) {
    this.setState({
      selectedTab: activeTab,
      selectedTabContent: activeTabContent,
    });
  }

  checkActiveTab(callingTab) {
    if (callingTab === this.state.selectedTab) {
      return "active";
    }
    return "";
  }

  validDate(current) {
    var openingDate = moment("2021-10-01T00:00:00");
    return current.isAfter(openingDate);
  }

  setSpin(spin) {
    this.setState({
      spin: spin
    })
  }

  render() {
    return (
      <div>
        <div class="card w-50">
          <div class="card-body">
            <p>
              Name of IPTSP Operator:{" "}
              <strong>Premium Connectivity Limited</strong>
            </p>
            <p>
              Type of IPTSP License: <strong>Nationwide</strong>
            </p>
            <p>
              Operation Start Data: <strong> 06 October, 2021 </strong>
            </p>
            <p>
              Reporting Month:{" "}
              <strong> {MonthName[this.state.selectedMonth - 1]} </strong> ;
              Year: <strong>{this.state.selectedYear}</strong>
            </p>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <Card>
              <Card.Header>
                <div class="row">
                  <div class=" row col-md-12 boxlayout">
                    <div class=" col-md-4"> <span >
                      <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
                    </span>
                    </div>
                    <div class=" col-md-6">
                      <div style={{ maxWidth: 200, display: 'flex', alignItems: 'center' }}>
                        Month: <Datetime
                          inputProps={{ placeholder: "Select a month" }}
                          dateFormat="YYYY-MMM"
                          timeFormat={false}
                          closeOnSelect={true}
                          onChange={this.handleDateChange}
                          value={moment(this.state.selectedMonth + "-" + this.state.selectedYear, "M-YYYY")}
                          isValidDate={this.validDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div>
                  <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="btn-group mr-2">
                      <button
                        className={
                          "btn btn-sm btn-outline-secondary " +
                          this.checkActiveTab(TabList.ACTIVE_SUBSCRIBER)
                        }
                        onClick={this.handleTabChange.bind(
                          this,
                          TabList.ACTIVE_SUBSCRIBER,
                          <MonthlyReportTable
                            setSpinChild={this.setSpin}
                            remoteApiUrl={SubscriberDataInfo.getActiveSubscriberDataRemoteUrl()}
                            thead={SubscriberDataInfo.thead}
                            transformRemoteDataToTableRows={
                              SubscriberDataInfo.transformRemoteDataToTableRows
                            }
                            year={this.state.selectedYear}
                            month={this.state.selectedMonth}
                          />
                        )}
                      >
                        Active Subscriber
                      </button>
                      <button
                        className={
                          "btn btn-sm btn-outline-secondary " +
                          this.checkActiveTab(TabList.PROVISIONAL_SUBSCRIBER)
                        }
                        onClick={this.handleTabChange.bind(
                          this,
                          TabList.PROVISIONAL_SUBSCRIBER,
                          <MonthlyReportTable
                            remoteApiUrl={SubscriberDataInfo.getProvisionalSubscriberDataRemoteUrl()}
                            thead={SubscriberDataInfo.thead}
                            transformRemoteDataToTableRows={
                              SubscriberDataInfo.transformRemoteDataToTableRows
                            }
                            year={this.state.selectedYear}
                            month={this.state.selectedMonth}
                            setSpinChild={this.setSpin}
                          />
                        )}
                      >
                        Subscriber Provisioned
                      </button>
                      <button
                        className={
                          "btn btn-sm btn-outline-secondary " +
                          this.checkActiveTab(TabList.IPTSP_IPTSP_CALL_INFO)
                        }
                        onClick={this.handleTabChange.bind(
                          this,
                          TabList.IPTSP_IPTSP_CALL_INFO,
                          <MonthlyReportTable
                            remoteApiUrl={IptspIptspCallDataInfo.getRemoteUrl()}
                            thead={IptspIptspCallDataInfo.thead}
                            transformRemoteDataToTableRows={
                              IptspIptspCallDataInfo.transformRemoteDataToTableRows
                            }
                            year={this.state.selectedYear}
                            month={this.state.selectedMonth}
                            setSpinChild={this.setSpin}
                          />
                        )}
                      >
                        IPTSP-IPTSP Call
                      </button>
                      <button
                        class="btn btn-sm btn-outline-secondary"
                        className={
                          "btn btn-sm btn-outline-secondary " +
                          this.checkActiveTab(TabList.ICX_IPTSP_CALL_INFO)
                        }
                        onClick={this.handleTabChange.bind(
                          this,
                          TabList.ICX_IPTSP_CALL_INFO,
                          <MonthlyReportTable
                            remoteApiUrl={IcxIptspCallDataInfo.getRemoteUrl()}
                            thead={IcxIptspCallDataInfo.thead}
                            transformRemoteDataToTableRows={
                              IcxIptspCallDataInfo.transformRemoteDataToTableRows
                            }
                            year={this.state.selectedYear}
                            month={this.state.selectedMonth}
                            setSpinChild={this.setSpin}
                          />
                        )}
                      >
                        ICX-IPTSP Call
                      </button>
                      <button
                        className={
                          "btn btn-sm btn-outline-secondary " +
                          this.checkActiveTab(TabList.CAPACITY_OF_INSTALLATION)
                        }
                        onClick={this.handleTabChange.bind(
                          this,
                          TabList.CAPACITY_OF_INSTALLATION,
                          <CapacityOfInstallationReport
                            setSpinChild={this.setSpin}
                          />
                        )}
                      >
                        Capacity Of Installation
                      </button>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <Body children={this.state.selectedTabContent} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div >
      </div>
    );
  }
}

export default MonthlyReport;
