import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { CurrentYear, currentMonth, getApplicationStartDate, getMonthFromNumber } from "../helper/Utility";
import ReactDatetimeClass from "react-datetime";
import { CustomerType } from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import NotificationService, { NotificationType } from "../helper/NotificationService";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
    selectFilter,
    numberFilter,
    textFilter
} from "react-bootstrap-table2-filter";
import CustomModal, { ModalType } from "../common/modal/CustomModal";
import moment from "moment";
import { Routes } from "../route/Routes";
import { InvoiceTypeSelectFilterOption } from "../customerPortal/CustomerInvoiceListDataInfo";


class EmployeeMonthlyBillList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: currentMonth(),
            selectedYear: CurrentYear(),
            customerType: null,
            spin: false,
            regenrate: true,
            loadingModalShow: false,
            data: []
        }
    }

    componentDidMount() {
        this.showList();
    }

    showList() {
        this.setState({
            data: [],
            spin: true,
        });
        let fullUrl = RemoteURL.INVOICE.MONTHLY_BILL_LIST
            + "?year=" + this.state.selectedYear
            + "&month=" + this.state.selectedMonth;
        if (this.state.customerType != null) {
            fullUrl += "&customerType=" + this.state.customerType;
        }
        RestService.get(
            fullUrl,
            this.extractShowList.bind(this),
            this.errorResponse.bind(this)
        );
    }

    extractShowList(result) {
        this.setState({
            data: result,
            spin: false
        })
    }

    generateAll() {
        this.setState({
            loadingModalShow: true
        });
        let fullUrl = RemoteURL.INVOICE.CREATE.ALL_MONTHLY_BILL
        let req = {};
        req.customerType = this.state.customerType;
        req.year = parseInt(this.state.selectedYear);
        req.month = parseInt(this.state.selectedMonth);
        RestService.post(
            fullUrl, req,
            this.generateResponse.bind(this),
            this.errorResponse.bind(this)
        )
    }

    generateSingle(customerInfo) {
        this.setState({
            loadingModalShow: true
        });
        let req = {};
        req.customerId = customerInfo.id;
        req.year = parseInt(this.state.selectedYear);
        req.month = parseInt(this.state.selectedMonth);
        req.regenerate = this.state.regenrate;
        let fullUrl = RemoteURL.INVOICE.CREATE.SINGLE_MONTHLY_BILL
        RestService.post(
            fullUrl, req,
            this.generateResponse.bind(this),
            this.errorResponse.bind(this)
        )
    }

    generateResponse(result) {
        NotificationService.show("Generation successful", NotificationType.SUCCESS);
        this.showList();
        this.setState({
            loadingModalShow: false,
        });
    }

    errorResponse(error) {
        NotificationService.showRestServiceError(error);
        this.setState({
            spin: false,
            loadingModalShow: false
        });
    }


    getGenerateAllConfirmationModalMessage() {
        return (
            <div>
                <h4>Do you Want you generate all monthly bill for:</h4>
                <p>Year: <b> {this.state.selectedYear}</b></p>
                <p>Month: <b> {getMonthFromNumber(this.state.selectedMonth)}</b></p>
                <p>Customer Type:<b>  {this.state.customerType == null ? "All" : this.state.customerType}</b> </p>
            </div>
        )
    }

    getGenerateSingelCustomerMessage(customerInfo) {
        return (
            <div>
                {customerInfo != null ?
                    <div>
                        <h4>Do you Want you generate monthly bill for:</h4>

                        <p>UserName: <b> {customerInfo.user.userName}</b></p>
                        <p>name: <b> {customerInfo.personalInfo.name}</b></p>
                        <p>Customer Type: <b> {customerInfo.customerType}</b></p>
                        <p>Year: <b> {this.state.selectedYear}</b></p>
                        <p>Month: <b> {getMonthFromNumber(this.state.selectedMonth)}</b></p>
                    </div> : null}

            </div>
        )
    }

    buttonAdd(cell, row) {
        if (row.invoiceInfo != null) {
            return (
                <Link
                    to={{
                        pathname: Routes.EMPLOYEE_PORTAL.INVOICE_DETAIL + "/" + row.invoiceInfo.id,
                    }}
                >
                    Detail
                </Link>
            );
        }
    }

    showSingleGenerationButton(cell, row) {

        return (
            <CustomModal
                buttonText={'Generate'}
                message={this.getGenerateSingelCustomerMessage(row.customerInfo)}
                successButtonText={"Generate"}
                modalType={ModalType.CONFIRMATION_MODAL}
                confiramtionFunction={this.generateSingle.bind(this, row.customerInfo)} />
        )

    }

    MONTHLY_BILL_LIST_TABLE_COLUMNS = [
        {
            dataField: "customerInfo.user.userName",
            text: "User name",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "customerInfo.personalInfo.name",
            text: "Customer Name",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "customerInfo.companyInfo.name",
            text: "Company Name",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "customerInfo.personalInfo.contactNo",
            text: "Phone number",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "customerInfo.billingPostpaidConnectionCount",
            text: "Num. Of Post Paid connections",
            sort: true,
            filter: numberFilter(),
        },
        {
            dataField: "invoiceInfo.reference",
            text: "Invoice id",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "invoiceInfo.paymentStatus",
            text: "Payment Status",
            filter: selectFilter({
                options: InvoiceTypeSelectFilterOption.PaymentStatus.option,
            }),
            sort: true,
        },
        {
            dataField: "invoiceInfo.amount",
            text: "Total amount",
            filter: numberFilter(),
            sort: true,
        },
        {
            dataField: "invoiceInfo.remainingAmount",
            text: "Unpaid amount",
            filter: numberFilter(),
            sort: true,
        },
        {
            dataField: "",
            text: "Detail",
            isDummyField: true,
            formatter: this.buttonAdd.bind(this),
        },
        {
            dataField: "",
            text: "Generate",
            isDummyField: true,
            formatter: this.showSingleGenerationButton.bind(this),
        },
    ];

    MONTHLY_BILL_LIST_TABLE_SORTED = [
        {
            dataField: "connectionInfo.iptspNumber",
            order: "desc",
        },
    ];

    noData() {
        return "No data found";
    }

    handleCustomerTypeChange(event) {
        if (event.target.value == "ALL") {
            this.setState({
                customerType: null
            })
        } else {
            this.setState({
                customerType: event.target.value
            })
        }
    }

    handleDateChange(event) {
        var year = event.format("Y");
        var month = event.format("M");
        this.setState({
            selectedYear: year,
            selectedMonth: month
        });
    }

    validDate(current) {
        var openingDate = moment(getApplicationStartDate());
        return current.isAfter(openingDate);
    }

    getNotGeneratedCount() {
        let count = 0;
        if (this.state.data != null) {
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].invoiceInfo === null) {
                    count++;
                }
            }
        }
        return count;
    }


    render() {
        return (
            <div>
                <Card>
                    <Card.Header>
                        <div class="row">
                            <div class=" row col-md-12 boxlayout">
                                <div class=" col-md-3"> <span >
                                    <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
                                </span>
                                </div>
                                <div class="col-md-3">
                                    <div style={{ maxWidth: 200, display: 'flex', alignItems: 'center' }}>
                                        Month: <ReactDatetimeClass
                                            inputProps={{ placeholder: "Select a month" }}
                                            dateFormat="YYYY-MMM"
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={this.handleDateChange.bind(this)}
                                            value={moment(this.state.selectedMonth + "-" + this.state.selectedYear, "M-YYYY")}
                                            isValidDate={this.validDate}
                                            closeOnClickOutside={true}
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <div style={{ maxWidth: 200, display: 'flex', alignItems: 'center' }}>
                                            Customer: <select onChange={(e) => this.handleCustomerTypeChange(e)} class="custom-select" id="customerType">
                                                <option selected value="ALL">ALL</option>
                                                <option value={CustomerType.CORPORATE}>{CustomerType.CORPORATE}</option>
                                                <option value={CustomerType.INDIVIDUAL}>{CustomerType.INDIVIDUAL}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-md-3">
                                    <div >
                                        <Button variant="primary" onClick={this.showList.bind(this)}> Show</Button>
                                    </div>
                                </div>

                                <div class=" col-md-3">
                                    <div >
                                        <CustomModal
                                            buttonText={'Generate All'}
                                            message={this.getGenerateAllConfirmationModalMessage()}
                                            successButtonText={"Generate"}
                                            modalType={ModalType.CONFIRMATION_MODAL}
                                            confiramtionFunction={this.generateAll.bind(this)}
                                        />
                                    </div>
                                    {this.state.data != null ?
                                        <div>
                                            <p><b>Total:{this.state.data.length}</b></p>
                                            <p><b>Not generated:{this.getNotGeneratedCount()}</b></p>
                                        </div> : null}
                                </div>

                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <BootstrapTable
                            bootstrap4
                            keyField={"id"}
                            data={this.state.data}
                            columns={this.MONTHLY_BILL_LIST_TABLE_COLUMNS}
                            defaultSorted={this.MONTHLY_BILL_LIST_TABLE_SORTED}
                            filter={filterFactory()}
                            filterPosition="top"
                            pagination={paginationFactory({
                                showTotal: true,
                                hideSizePerPage: true,
                            })}
                            bodyStyle={{ overflow: "overlay" }}
                            wrapperClasses="table-responsive"
                            noDataIndication={this.noData} />
                    </Card.Body>
                </Card>
                <CustomModal
                    openControl={this.state.loadingModalShow}
                    modalType={ModalType.LOADING_MODAL}
                />
            </div>
        );
    }
}

export default EmployeeMonthlyBillList;
