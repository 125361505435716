import React, { useState } from "react";
import { Button, Form, Modal, NavDropdown } from "react-bootstrap";
import authService from "../login/LoginService";
import { withRouter } from "react-router";
import { Routes } from "../route/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { PasswordForm } from "./form/PasswordForm";
import RestService, { RemoteURL } from "../helper/RestService";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";

// To show pic https://stackoverflow.com/questions/43479577/add-a-image-to-react-bootstrap-dropdown

const ProfileMenu = (props) => {
  const [showMenu, setShowMenu] = useState(authService.isLoggedIn());
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    authService.logout(logoutCleanUp);
  };

  const logoutCleanUp = (e) => {
    setShowMenu(false);
    props.history.push(Routes.LOG_IN);
  };

  const handleChangePassword = (e) => {
    setShowChangePasswordModal(true);
  };

  function extractChangePasswordResponse(response) {
    if (response.isTrue === true) {
      NotificationService.show(
        NotificationMessage.PASSWORD_CHANGE_SUCCESSFUL,
        NotificationType.SUCCESS
      );
    } else {
      NotificationService.show(
        NotificationMessage.PASSWORD_CHANGE_FAILED,
        NotificationType.ERROR
      );
    }
    setShowChangePasswordModal(false);
  }

  function changePassword() {
    let request = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    RestService.post(
      RemoteURL.USER.CHANGE_PASSWORD,
      request,
      extractChangePasswordResponse,
      NotificationService.showRestServiceError
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      changePassword();
    }
  }

  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  function getChangePasswordModal() {
    return (
      <Modal show={showChangePasswordModal} onHide={closeChangePasswordModal}>
        <Modal.Header closeButton>Change Password</Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <PasswordForm
              value={oldPassword}
              setValue={setOldPassword}
              label={"Old password"}
            />

            <PasswordForm
              value={newPassword}
              setValue={setNewPassword}
              label={"New password"}
            />

            <Button block size="lg" type="submit">
              Change
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  const navDropdownTitle = (
    <FontAwesomeIcon icon={faUserCircle} size="2x" transform="grow-4" />
  );

  return (
    <div>
      {showMenu ? (
        <NavDropdown title={navDropdownTitle} id="collasible-nav-dropdown">
          <NavDropdown.Header>
            {authService.getUserInfo().userName}
          </NavDropdown.Header>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={handleChangePassword}>
            Change password
          </NavDropdown.Item>
          <NavDropdown.Item onClick={handleLogout}>Log out</NavDropdown.Item>
        </NavDropdown>
      ) : null}
      {getChangePasswordModal()}
    </div>
  );
};

export default withRouter(ProfileMenu);
