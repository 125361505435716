import React from "react";

import {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import {
  AccountType,
  InvoiceType,
  PaymentStatus,
} from "../domainObject/DomainDataType";
import { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";

function buttonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.CUSTOMER.PORTAL.INVOICE_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>
  );
}

export const InvoiceTypeSelectFilterOption = {
  InvoiceType: {
    key: "type",
    option: {
      0: InvoiceType.SECURITY_DEPOSIT,
      1: InvoiceType.SET_UP_CHARGE,
    },
  },
  PaymentStatus: {
    key: "paymentStatus",
    option: {
      0: PaymentStatus.UNPAID,
      1: PaymentStatus.PARTIAL_PAID,
    },
  },
  ACCOUNT_TYPE: {
    option: [
      {
        value: AccountType.PRE_PAID,
        label: "Prepaid",
      },
      {
        value: AccountType.POST_PAID,
        label: "Postpaid",
      },
    ],
  },
};

export const CustomerExistingConnectionReqDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.GET.INVOICE_SEARCH,
  SelectFilterOption: InvoiceTypeSelectFilterOption,
  withDefaultFilter: true,

  defaultSorted: [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "createdDate",
      text: "Creation time",
      filter: dateFilter(),
      sort: true,
    },

    {
      dataField: "reference",
      text: "Invoice Reference",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "type",
      text: "Invoice type",
      sort: true,
      filter: selectFilter({
        options: InvoiceTypeSelectFilterOption.InvoiceType.option,
        defaultValue: InvoiceType.SECURITY_DEPOSIT,
        withoutEmptyOption: true,
      }),
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      filter: selectFilter({
        options: InvoiceTypeSelectFilterOption.PaymentStatus.option,
        defaultValue: PaymentStatus.UNPAID,
        withoutEmptyOption: true,
      }),
      sort: true,
    },
    {
      dataField: "invoiceDetails.length",
      text: "No. of Connection",
      sort: true,
    },
    {
      dataField: "invoiceDetails[0].connectionInfo.productInfo.accountType",
      text: "Product type",
      sort: true,
    },
    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: buttonAdd,
    },
  ],
};

export default CustomerExistingConnectionReqDataInfo;
