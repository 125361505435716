import React from "react";
import { withRouter } from "react-router";
import {
  AccountType,
  ConnectionStatus,
  CustomerType,
} from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import authService from "../login/LoginService";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const ADDRESS_PANEL_DEFAULT_SORTED = [
  {
    dataField: "createdDate",
    order: "desc",
  },
];
const ADDRESS_PANEL_EMPTY_TABLE_MESSAGE = () => {
  return "No address found";
};

function valueNotAssignedFormatter(cell, row) {
  if (cell != null) {
    return cell;
  } else {
    return "";
  }
}
const CUSTOMER_ADDRESS_PANEL_COLUMNS = [
  {
    dataField: "reference",
    text: "Address Id",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "flatNo",
    text: "Flat no",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "houseNo",
    text: "House no",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "roadNo",
    text: "Road no",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "area",
    text: "Area",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "upazila.name",
    text: "Upazila",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "upazila.district.name",
    text: "District",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "upazila.district.division.name",
    text: "Division",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "postCode",
    text: "Postcode",
    sort: true,
    formatter: valueNotAssignedFormatter,
    filter: textFilter(),
  },
];

class CustomerConnectionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
    };
  }

  extractCustomerDetailResponse(response) {
    let addressList = response.otherAddresses;
    if (response.customerType === CustomerType.INDIVIDUAL) {
      addressList.push(response.presentAddress);
    } else {
      addressList.push(response.companyInfo.address);
    }
    this.setState({
      tableData: addressList,
    });
  }

  getCustomerInfo() {
    let userInfo = authService.getUserInfo();
    RestService.get(
      RemoteURL.CUSTOMER.GET.Detail + "/" + userInfo.id,
      this.extractCustomerDetailResponse.bind(this)
    );
  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  render() {
    return (
      <div>
        <div>
          <BootstrapTable
            bootstrap4
            keyField={"id"}
            hideSizePerPage={true}
            data={this.state.tableData}
            columns={CUSTOMER_ADDRESS_PANEL_COLUMNS}
            defaultSorted={ADDRESS_PANEL_DEFAULT_SORTED}
            filter={filterFactory()}
            filterPosition="top"
            pagination={paginationFactory({
              showTotal: true,
              hideSizePerPage: true,
            })}
            bodyStyle={{ overflow: "overlay" }}
            wrapperClasses="table-responsive"
            noDataIndication={ADDRESS_PANEL_EMPTY_TABLE_MESSAGE}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerConnectionPanel);
