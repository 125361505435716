import React from "react";
import { Route, Switch } from "react-router";
import CompanyPortal from "../companyPortal/CompanyPortalPage";
import { Routes } from "./Routes";

export const CompanyPortalRoutes = (props) => {
  return (
    <div>
      <Switch>
        <Route path={Routes.COMPANY.PORTAL.HOME} exact>
          <CompanyPortal />
        </Route>
      </Switch>
    </div>
  );
};
