import React from "react";
import { Form } from "react-bootstrap";

export const TermsAndConditionsForm = (props) => {
  return (
    <div>
      <Form.Check
        required
        label="Agree to terms and conditions"
        feedback="You must agree before submitting."
        feedbackType="invalid"
      />
      <a href={props.url} download>
        Download Terms and Conditions
      </a>
    </div>
  );
};
