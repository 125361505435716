export const Routes = {
  HOME: "/",
  LOG_IN: "/portal/login",
  FORGET_PASSWORD: "/portal/forgetPassword",
  OTP: {
    GENERATION: "/portal/customer/otp/generation",
    VERIFICATION: "/portal/customer/otp/verification",
  },
  BTRC_PORTAL: {
    HOME: "/portal/btrc",
    MONTHLY_REPORT: "/portal/btrc/monthlyReport",
    CDR_REPORT: "/portal/btrc/cdrReport",
    CLIENT_REPORT: "/portal/btrc/clientReport",
    ACTIVE_REGISTRATION: "/portal/btrc/activeRegistration",
    ACTIVE_CALLS: "/portal/btrc/activeCalls",
    UTILIZATION: "/portal/btrc/utilization",
  },
  EMPLOYEE_PORTAL: {
    HOME: "/portal/employee",
    CUSTOMER_LIST: "/portal/employee/customers",
    CUSTOMER_VERIFICATION_REQUEST:
      "/portal/employee/customer/verification/list",
    CUSTOMER_DETAIL: "/portal/employee/customer/detail",
    CONNECTION_LIST: "/portal/employee/connection/list",
    CONNECTION_DISCARDED_LIST: "/portal/employee/connection/discarded/list",
    CONNECTION_PREPAID_LIST:"/portal/employee/connection/prepaid/list",
    CONNECTION_DETAIL: "/portal/employee/connection/detail",
    CONNECTION_ACTIVATION_REQUEST:
      "/portal/employee/connections/activation/list",
    ADD_CONNECTION: "/portal/employee/connections/add",
    CUSTOMER_ACTIVATION: "/portal/employee/customer/activate",
    CUSTOMER_REGISTRATION: "/portal/employee/customer/registration",
    MONTHLY_BILL_LIST: "/portal/employee/monthlyBill",
    INVOICE_LIST: "/portal/employee/invoices",
    UNPAID_INVOICE_LIST: "/portal/employee/invoice/list/unpaid",
    CUSTOMER_INVOICE_LIST: "/portal/employee/invoices/customer",
    INVOICE_DETAIL: "/portal/employee/invoice/detail",
    CUSTOMER_SUPPORT_LIST: "/portal/employee/supports",
    CUSTOMER_SUPPORT_DETAIL: "/portal/employee/support",
    BTRC_UTILIZATION: "/portal/employee/btrc/utilization",
  },
  REGISTRATION: {
    CUSTOMER: "/portal/registration/customer",
  },
  CUSTOMER: {
    PORTAL: {
      HOME: "/portal/customer",
      CONNECTION_LIST: "/portal/customer/connections",
      CONNECTION_DETAIL: "/portal/customer/connection",
      ADDRESS_LIST: "/portal/customer/addresses",
      REQUEST_CONNECTION: "/portal/customer/connection/request",
      EXISTING_CONNECTION_REQUEST: "/portal/customer/connections/pending",
      REGISTER_ADDRESS: "/portal/customer/address/register",
      INVOICE_LIST: "/portal/customer/invoices",
      INVOICE_DETAIL: "/portal/customer/invoice",
      REFILL: "/portal/customer/refill",
      CDR: "/portal/customer/cdr",
      CALL_SUMMARY:"/portal/customer/call/summary",
      SUPPORT_REQUEST: "/portal/customer/support/request",
      SUPPORT_LIST: "/portal/customer/supports",
      SUPPORT_DETAIL: "/portal/customer/support",
    },
  },
  COMPANY: {
    PORTAL: {
      HOME: "/portal/company",
    },
  },
};
