import React from "react";
import moment from 'moment';

export const CommonFooter = (props) => {
  return (
    <footer class="fixed-bottom footer bg-secondary text-center text-lg-start ">
      <div class=" text-center p-3 text-white">
        © {moment().format('YYYY')} Copyright:
        <a class="text-white pl-2" style={{paddingLeft:"10px"}} href="https://iptsp.pmcon.net" target="_blank" rel="noopener noreferrer" >
           Premium Connectivity Ltd.
        </a>
      </div>
    </footer>
  );
};
