import React from "react";
import {
  CallNetworkType,
  CallType,
  CallMinuteInfoTopicName,
} from "../../domainObject/DomainDataType";
import { RemoteURL } from "../../helper/RestService";

const IptspIptspCallDataInfo = {
  thead: (
    <thead>
      <tr>
        <td rowspan="1"></td>
        <th colspan="2" scope="colgroup">
          Outgoing Billling Calls and Minutes
        </th>
        <th colspan="2" scope="colgroup">
          Incoming Billling Calls and Minutes
        </th>
      </tr>
      <tr>
        <th>Topic</th>
        <th>IPTSP(On net: Within the same network)</th>
        <th>IPTSP-IPTSP(Other IPTSP net)</th>
        <th>IPTSP(On net: Within the same network)</th>
        <th>IPTSP-IPTSP(Other IPTSP net)</th>
      </tr>
    </thead>
  ),

  transformRemoteDataToTableRows: function (data) {
    let rows = [];
    let sumOfCallMinutesRow = [
      CallMinuteInfoTopicName.SUM_OF_CALL_MINUTES.text,
      0,
      0,
      0,
      0,
    ];
    let sumOfNumberOfCallRow = [
      CallMinuteInfoTopicName.NUM_OF_NUMBER_OF_CALLS.text,
      0,
      0,
      0,
      0,
    ];
    let i = 0;
    for (i; i < data.length; i++) {
      if (
        data[i].callType === CallType.OUTGOING &&
        data[i].callNetworkType === CallNetworkType.ON_NET
      ) {
        sumOfCallMinutesRow[1] = parseFloat(data[i].noOfMinutes).toFixed(2);
        sumOfNumberOfCallRow[1] =data[i].noOfCalls;
      } else if (
        data[i].callType === CallType.OUTGOING &&
        data[i].callNetworkType === CallNetworkType.OFF_NET
      ) {
        sumOfCallMinutesRow[2] = parseFloat(data[i].noOfMinutes).toFixed(2);
        sumOfNumberOfCallRow[2] =data[i].noOfCalls;
      } else if (
        data[i].callType === CallType.INCOMING &&
        data[i].callNetworkType === CallNetworkType.ON_NET
      ) {
        sumOfCallMinutesRow[3] = parseFloat(data[i].noOfMinutes).toFixed(2);
        sumOfNumberOfCallRow[3] =data[i].noOfCalls;
      } else if (
        data[i].callType === CallType.INCOMING &&
        data[i].callNetworkType === CallNetworkType.OFF_NET
      ) {
        sumOfCallMinutesRow[4] = parseFloat(data[i].noOfMinutes).toFixed(2);
        sumOfNumberOfCallRow[4] =data[i].noOfCalls;
      }
    }
    rows.push(sumOfCallMinutesRow);
    rows.push(sumOfNumberOfCallRow);
    return rows;
  },
  getRemoteUrl: function () {
    return RemoteURL.BTRC_PANEL.MONTHLY_REPORT.IPTSP_IPTSP_CALL_INFO;
  },
};

export default IptspIptspCallDataInfo;
