import React from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import NotificationService from "../../helper/NotificationService";
import RestService, { RemoteURL } from "../../helper/RestService";
import { REGEX } from "../../helper/Utility";
import { CustomerActivationData } from "./ConnectionActivationData";

const INBOUND_DID_ADD_FORM_NUMBER_COLUMNS = ["did"];

const CUSTOMER_ADD_FORM_NUMBER_COLUMNS = ["userName", "userAlias"];

const VOIP_FORM_NUMBER_COLUMNS = [
  "name",
  "accountCode",
  "regExten",
  "callerId",
];

const CALLER_ID_FORM_NUMBER_COLUMNS = ["callerId"];

class SingleConnectionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activationData: props.activationData,
      number: props.activationData.number,
      serverSentNumber: props.activationData.number,
      prevNumber: props.activationData.number,
      numberReadOnly: true,
      inputNumberInvalid: false,
      showNotAvailable: false,
    };
  }

  componentDidUpdate() {
    if (this.activationData === null && this.props.activationData !== null) {
      this.setState({
        activationData: this.props.activationData,
        number: this.props.activationData.number,
        serverSentNumber: this.props.activationData.number,
      });
    }
  }

  extractNumberStatusResponse(response) {
    if (response.isTrue) {
      this.props.updateUsedNumbers(
        this.state.activationData.connectionId,
        this.state.number
      );
      this.setState({
        numberReadOnly: true,
        inputNumberInvalid: false,
        showNotAvailable: false,
      });
    } else {
      this.setState({
        inputNumberInvalid: true,
        showNotAvailable: true,
      });
    }
  }

  errorResponse(error) {NotificationService.showRestServiceError(error)}

  checkNumber() {
    let url =
      RemoteURL.CONNECTION.CHECK_NUMBER_TO_ASSIGN +
      "/" +
      this.props.activationData.connectionId +
      "?" +
      "number=" +
      this.state.number;
    RestService.get(
      url,
      this.extractNumberStatusResponse.bind(this),
      this.errorResponse.bind(this)
    );
  }

  checkInputNumberLength() {
    if (this.state.number.length !== 11) {
      this.setState({
        inputNumberInvalid: true,
        showNotAvailable: false,
        showNotWithInNumberPlan: false,
      });
      return false;
    } else {
      this.setState({
        inputNumberInvalid: false,
      });
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.number !== this.state.serverSentNumber) {
      if (this.checkInputNumberLength()) {
        this.checkNumber();
      }
    } else {
      this.resetNumberForm();
    }
  }

  editNumber() {
    this.setState({
      numberReadOnly: false,
      prevNumber: this.state.number,
    });
  }

  resetNumberForm() {
    this.setState({
      number: this.state.prevNumber,
      numberReadOnly: true,
      inputNumberInvalid: false,
      showNotAvailable: false,
      showNotWithInNumberPlan: false,
    });
    this.props.updateUsedNumbers(
      this.state.activationData.connectionNo,
      this.state.number
    );
  }

  resetToServerSentNumber() {
    this.setState({
      number: this.state.serverSentNumber,
      numberReadOnly: true,
      inputNumberInvalid: false,
      showNotAvailable: false,
      showNotWithInNumberPlan: false,
    });
    this.props.updateUsedNumbers(
      this.state.activationData.connectionNo,
      this.state.number
    );
  }

  render() {
    let errorMessage = "";
    if (this.state.showNotAvailable) {
      errorMessage = "Input number is not available";
    } else {
      errorMessage = "Please Input 11 digit number";
    }
    return (
      <div>
        <div class="d-flex justify-content-center">
          <Form
            noValidate
            //   validated={this.state.validated}
            onSubmit={this.handleSubmit.bind(this)}
          >
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="3">
                Number
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="tel"
                  isInvalid={this.state.inputNumberInvalid}
                  value={this.state.number}
                  className={this.getClassName}
                  onChange={(e) => {
                    this.setState({
                      inputNumberInvalid: false,
                    });
                    let value = e.target.value;
                    if (
                      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
                      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
                    ) {
                      if (value.length >= 11) {
                        value = value.slice(0, 11);
                      }
                      this.setState({
                        number: value,
                      });
                    }
                  }}
                  required
                  readOnly={this.state.numberReadOnly}
                  pattern={REGEX.REGEX_ONLY_11_DIGIT_MATCH}
                />

                <Form.Control.Feedback type="invalid">
                  {errorMessage}
                </Form.Control.Feedback>
              </Col>
              {this.state.numberReadOnly ? (
                <Col sm="3">
                  <Button
                    className="singleRowField"
                    block
                    size="sm"
                    variant="success"
                    onClick={this.editNumber.bind(this)}
                  >
                    Edit
                  </Button>
                  {this.state.number !== this.state.serverSentNumber ? (
                    <Button
                      className="singleRowField"
                      block
                      size="sm"
                      variant="danger"
                      onClick={this.resetToServerSentNumber.bind(this)}
                    >
                      Reset to Initial
                    </Button>
                  ) : null}
                </Col>
              ) : (
                <Col sm="3">
                  <div>
                    <Button
                      className="singleRowField"
                      block
                      size="sm"
                      variant="success"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      className="singleRowField"
                      block
                      size="sm"
                      variant="danger"
                      onClick={this.resetNumberForm.bind(this)}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              )}
            </Form.Group>
          </Form>
        </div>
        <Tabs defaultActiveKey="addFrom" className="mb-3">
          <Tab eventKey="addFrom" title="Customer Add form">
            {this.state.activationData !== null &&
            this.state.activationData.customerAdd !== null ? (
              <CustomerActivationData
                numberFields={CUSTOMER_ADD_FORM_NUMBER_COLUMNS}
                number={this.state.number}
                data={this.state.activationData.customerAdd}
              />
            ) : (
              "No data"
            )}
          </Tab>
          <Tab eventKey="callerId" title="Caller id form">
            {this.state.activationData !== null &&
            this.state.activationData.callerId !== null ? (
              <CustomerActivationData
                numberFields={CALLER_ID_FORM_NUMBER_COLUMNS}
                passwordFields={[]}
                number={this.state.number}
                data={this.state.activationData.callerId}
              />
            ) : (
              "No data"
            )}
          </Tab>
          <Tab eventKey="logRefill" title="Log refill form">
            {this.state.activationData !== null &&
            this.state.activationData.logRefillData !== null ? (
              <CustomerActivationData
                numberFields={[]}
                passwordFields={[]}
                number={this.state.number}
                data={this.state.activationData.logRefillData}
              />
            ) : (
              "No data"
            )}
          </Tab>
          <Tab eventKey="voipSetting" title="Voip Setting form">
            {this.state.activationData !== null &&
            this.state.activationData.voipSetting !== null ? (
              <CustomerActivationData
                numberFields={VOIP_FORM_NUMBER_COLUMNS}
                number={this.state.number}
                data={this.state.activationData.voipSetting}
              />
            ) : (
              "No data"
            )}
          </Tab>
          <Tab eventKey="inboundDid" title="Inbound DID Add form">
            {this.state.activationData !== null &&
            this.state.activationData.inboundDIDAdd !== null ? (
              <CustomerActivationData
                numberFields={INBOUND_DID_ADD_FORM_NUMBER_COLUMNS}
                passwordFields={[]}
                number={this.state.number}
                data={this.state.activationData.inboundDIDAdd}
              />
            ) : (
              "No data"
            )}
          </Tab>
          <Tab
            eventKey="inboundDidDestination"
            title="Inbound DID Destination Add form"
          >
            {this.state.activationData !== null &&
            this.state.activationData.inboundDidDestination !== null ? (
              <CustomerActivationData
                numberFields={[]}
                passwordFields={[]}
                number={this.state.number}
                data={this.state.activationData.inboundDidDestination}
              />
            ) : (
              "No data"
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default SingleConnectionPanel;
