import React from "react";
import { CommonFooter } from "../common/CommonFooter";
import { CommonHeader } from "../common/CommonHeader";
import SideBar from "../common/Sidebar";
import { SetPageTitle } from "../helper/Utility";
import { Routes } from "../route/Routes";

export const SidebarMenuItem = {
  MONTHLY_REPORT: "MONTHLY_REPORT",
  CDR_REPORT: "CDR_REPORT",
  CLIENT_REPORT: "CLIENT_REPORT",
  ACTIVE_REGISTRATION: "ACTIVE_REGISTRATION",
  ACTIVE_CALLS: "ACTIVE_CALLS",
  UTILIZATION: "UTILIZATION",
};

function checkCurrentActive(callingPanel, activePanel) {
  if (callingPanel === activePanel) {
    return "nav-link active";
  } else {
    return "nav-link ";
  }
}

const SideBarMenu = (props) => (
  <div>
    <li>
      Report
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.MONTHLY_REPORT,
              props.activePanel
            )}
            href={Routes.BTRC_PORTAL.MONTHLY_REPORT}
          >
            <span data-feather="home"></span>
            Monthly
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.CDR_REPORT,
              props.activePanel
            )}
            href={Routes.BTRC_PORTAL.CDR_REPORT}
          >
            <span data-feather="home"></span>
            CDR
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.CLIENT_REPORT,
              props.activePanel
            )}
            href={Routes.BTRC_PORTAL.CLIENT_REPORT}
          >
            <span data-feather="home"></span>
            Client list
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.ACTIVE_REGISTRATION,
              props.activePanel
            )}
            href={Routes.BTRC_PORTAL.ACTIVE_REGISTRATION}
          >
            <span data-feather="home"></span>
            Active registration
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.ACTIVE_CALLS,
              props.activePanel
            )}
            href={Routes.BTRC_PORTAL.ACTIVE_CALLS}
          >
            <span data-feather="home"></span>
            Active calls
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.UTILIZATION,
              props.activePanel
            )}
            href={Routes.BTRC_PORTAL.UTILIZATION}
          >
            <span data-feather="home"></span>
            Utilization
          </a>
        </li>
      </ul>
    </li>
  </div>
);

class BtrcPortal extends React.Component {
  constructor(props) {
    super(props);
    SetPageTitle({
      title: "BTRC portal",
      metaDescription: "BTRC portal",
    });
  }

  render() {
    return (
      <div>
        <CommonHeader />
        <div class="container-fluid">
          <div class="row">
            <SideBar
              children={<SideBarMenu activePanel={this.props.activePanel} />}
            />

            <main role="main" class="mainBlock col-sm-9 col-md-9 ml-sm-auto col-lg-9 pt-3 px-4col-10 pt-3 px-4 main-container">
              {this.props.currentPanel}
            </main>
          </div>
        </div>
        <CommonFooter />
      </div>
    );
  }
}

export default BtrcPortal;
