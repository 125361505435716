import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Dropdown, Form, Modal } from "react-bootstrap";
import RestService, { RemoteURL } from "../helper/RestService";
import NotificationService from "../helper/NotificationService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
    dateFilter,
    numberFilter,
    textFilter,
    selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import { Routes } from "../route/Routes";
import { InvoiceTypeSelectFilterOption } from "../customerPortal/CustomerInvoiceListDataInfo";
import { RequiredMarker } from "../common/form/RequiredMarker";
import { getMonthFromNumber, getMonthNumberFilter, getYearFormatter, getYearNumberFilter } from "../helper/Utility";


class CustomerAllInvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: null,
            data: []
        }
    }

    showList() {
        if (this.state.userName != null) {
            this.setState({
                spin: true,
            });
            let fullUrl = RemoteURL.CUSTOMER.GET.ALL_INVOICE
                + "?username=" + this.state.userName;
            RestService.get(
                fullUrl,
                this.extractShowList.bind(this),
                this.errorResponse.bind(this)
            );
        }
    }

    extractShowList(result) {
        this.setState({
            data: result,
            spin: false
        })
    }


    errorResponse(error) {
        NotificationService.showRestServiceError(error);
        this.setState({
            spin: false,
        });
    }

    buttonAdd(cell, row) {
        return (
            <Link
                to={{
                    pathname: Routes.EMPLOYEE_PORTAL.INVOICE_DETAIL + "/" + row.id,
                }}
            >
                Detail
            </Link>
        );
    }

    CUSTOMER_ALL_INVOICE_LIST_TABLE_COLUMNS = [
        {
            dataField: "createdDate",
            text: "Creation time",
            filter: dateFilter(),
            sort: true,
        },

        {
            dataField: "reference",
            text: "Reference",
            filter: textFilter(),
        },
        {
            dataField: "type",
            text: "Invoice type",
            filter: selectFilter({
                options: InvoiceTypeSelectFilterOption.InvoiceType.option,
            }),
        },
        {
            dataField: "billingYear",
            text: "Year",
            filter: getYearNumberFilter(),
            sort: true,
            formatter: getYearFormatter
        },
        {
            dataField: "billingMonth",
            text: "Month",
            filter: getMonthNumberFilter(),
            sort: true,
            formatter: getMonthFromNumber
        },
        {
            dataField: "paymentStatus",
            text: "Payment Status",
            filter: selectFilter({
                options: InvoiceTypeSelectFilterOption.PaymentStatus.option,
            }),
            sort: true,
        },
        {
            dataField: "amount",
            text: "Invoice amount",
            filter: numberFilter(),
            sort: true,
        },
        {
            dataField: "remainingAmount",
            text: "Unpaid amount",
            filter: numberFilter(),
            sort: true,
        },
        {
            dataField: "",
            text: "Detail",
            isDummyField: true,
            formatter: this.buttonAdd.bind(this),
        },
    ];

    CUSTOMER_ALL_INVOICE_LIST_TABLE_SORTED = [
        {
            dataField: "connectionInfo.iptspNumber",
            order: "desc",
        },
    ];

    noData() {
        return "No data found";
    }



    render() {
        return (
            <div>
                <Card>
                    <Card.Header>
                        <div class="row">
                            <div class=" row col-md-12 boxlayout">
                                <div class=" col-md-4"> <span >
                                    <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
                                </span>
                                </div>
                                <div class=" col-md-6">
                                    <Form.Label>
                                        User name:
                                    </Form.Label>
                                    {" "}<RequiredMarker />
                                    <Form.Control
                                        type="text"
                                        value={this.state.userName}
                                        onChange={(e) => {
                                            this.setState({
                                                userName: e.target.value
                                            })
                                        }}
                                        placeholder="User name"
                                        required
                                    />
                                    &nbsp;
                                    <div>
                                        <Button variant="primary" onClick={this.showList.bind(this)}> Show</Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Card.Header>
                    <Card.Body>
                        <BootstrapTable
                            bootstrap4
                            keyField={"id"}
                            data={this.state.data}
                            columns={this.CUSTOMER_ALL_INVOICE_LIST_TABLE_COLUMNS}
                            defaultSorted={this.CUSTOMER_ALL_INVOICE_LIST_TABLE_SORTED}
                            filter={filterFactory()}
                            filterPosition="top"
                            pagination={paginationFactory({
                                showTotal: true,
                                hideSizePerPage: true,
                            })}
                            bodyStyle={{ overflow: "overlay" }}
                            wrapperClasses="table-responsive"
                            noDataIndication={this.noData} />
                    </Card.Body>
                </Card>
            </div >
        );
    }
}

export default CustomerAllInvoiceList;
