class CustomerInfo {
  constructor(
    productId = null,
    connectionProperties = [],
    numberOfConnection = 1,
    personalInformation = new PersonalInformation(),
    permanentAddressSameAsPresentAddress = false,
    presentAddress = new Address(),
    permanentAddress = new Address(),
    companyInformation = new CompanyInformation(),
    billingAddressSameAsPresentAddress = false,
    billingAddress = new Address(),
    otherInformation = new OtherInformation()
  ) {
    this.productId = productId;
    this.connectionProperties = connectionProperties;
    this.numberOfConnection = numberOfConnection;
    this.personalInformation = personalInformation;
    this.permanentAddressSameAsPresentAddress =
      permanentAddressSameAsPresentAddress;
    this.presentAddress = presentAddress;
    this.permanentAddress = permanentAddress;
    this.companyInformation = companyInformation;
    this.billingAddressSameAsPresentAddress =
      billingAddressSameAsPresentAddress;
    this.billingAddress = billingAddress;
    this.otherInformation = otherInformation;
  }
}

export class PersonalInformation {
  constructor(
    name = "",
    contactNo = "",
    emailId = "",
    dateOfBirth = "",
    gender = "",
    nationality = "",
    governmentId = "",
    governmentIdType = "",
    occupation = "",
    nameOfOccupation = ""
  ) {
    this.name = name;
    this.contactNo = contactNo;
    this.emailId = emailId;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.nationality = nationality;
    this.governmentId = governmentId;
    this.governmentIdType = governmentIdType;
    this.occupation = occupation;
    this.nameOfOccupation = nameOfOccupation;
  }
}

export class Address {
  constructor(
    flatNo = "",
    houseNo = "",
    roadNo = "",
    area = "",
    upazilaId = "",
    postCode = ""
  ) {
    this.flatNo = flatNo;
    this.houseNo = houseNo;
    this.roadNo = roadNo;
    this.area = area;
    this.upazilaId = upazilaId;
    this.postCode = postCode;
  }
}

export class CompanyInformation {
  constructor(name = "") {
    this.name = name;
  }
}

export class OtherInformation {
  constructor(
    fathersName = "",
    mothersName = "",
    spousesName = "",
    identifiersName = "",
    identifiersContactNo = "",
    identifiersNid = ""
  ) {
    this.fathersName = fathersName;
    this.mothersName = mothersName;
    this.spousesName = spousesName;
    this.identifiersName = identifiersName;
    this.identifiersContactNo = identifiersContactNo;
    this.identifiersNid = identifiersNid;
  }
}

export class PersonalAttachment {
  constructor(photo = null, signature = null, governmentIdFile = null) {
    this.photo = photo;
    this.signature = signature;
    this.governmentIdFile = governmentIdFile;
  }
}

export class CompanyAttachment {
  constructor(
    authorizationToPersonnel = null,
    tradeLicense = null,
    tin = null,
    bin = null
  ) {
    this.authorizationToPersonnel = authorizationToPersonnel;
    this.tradeLicense = tradeLicense;
    this.tin = tin;
    this.bin = bin;
  }
}

export class ConnectionProperty {
  concurrentChannel = 0;
  postPayCreditLimit = 0;
}

export default CustomerInfo;
