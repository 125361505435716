import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Address } from "../domainObject/CustomerInfo";
import { OrderedCustomerRegistrationStatus } from "../domainObject/DomainDataType";
import NotificationService, { NotificationMessage, NotificationType } from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { AddressForm } from "./AddressForm";
import { OtherInformationForm } from "./OtherInformationForm";
import { PersonalInformationForm } from "./PersonalInformationForm";

export const PersonalInformationRegistration = (props) => {
  const [validated, setValidated] = useState(false);
  //Personal information
  const [nameOfSubscriber, setNameOfSubscriber] = useState("");
  const [contactNo, setContactNo] = useState("");

  const [emailId, setEmailId] = useState("");

  const [nationality, setNationality] = useState("");
  const [occupation, setOccupation] = useState("");
  const [nameOfOccupation, setNameOfOccupation] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [governmentId, setGovernmentId] = useState("");

  const [governmentIdType, setGovernmentIdType] = useState("");
  //Present address
  const [presentAddressFlatNo, setPresentAddressFlatNo] = useState("");
  const [presentAddressHouseNo, setPresentAddressHouseNo] = useState("");
  const [presentAddressRoadNo, setPresentAddressRoadNo] = useState("");
  const [presentAddressArea, setPresentAddressArea] = useState("");
  const [presentAddressUpazilaId, setPresentAddressUpazilaId] = useState("");
  const [presentAddressPostCode, setPresentAddressPostCode] = useState("");

  //Permanent address
  const [presentAddressIsPermanent, setPresentAddressIsPermanent] =
    useState(false);
  const [permanentAddressFlatNo, setPermanentAddressFlatNo] = useState("");
  const [permanentAddressHouseNo, setPermanentAddressHouseNo] = useState("");
  const [permanentAddressRoadNo, setPermanentAddressRoadNo] = useState("");
  const [permanentAddressArea, setPermanentAddressArea] = useState("");
  const [permanentAddressUpazilaId, setPermanentAddressUpazilaId] =
    useState("");
  const [permanentAddressPostCode, setPermanentAddressPostCode] = useState("");

  //Other information
  const [fathersName, setFathersName] = useState("");
  const [mothersName, setMothersName] = useState("");
  const [spousesName, setSpousesName] = useState("");
  const [identifiersName, setIdentifiersName] = useState("");
  const [identifiersContactNo, setIdentifiersContactNo] = useState("");

  const [identifiersNID, setIdentifiersNID] = useState("");

  function extractResponse(response) {
    if (response.isTrue) {
      NotificationService.show(NotificationMessage.PERSONAL_INFORMATION_REGISTRATION_SUCCESS, NotificationType.SUCCESS);
      props.updateStatus(
        OrderedCustomerRegistrationStatus.PERSONAL_INFO_REGISTERED
      );
    } else {
      props.handleServerError();
    }
  }

  

  function registerPersonalInformation() {
    let request = {
      id: props.customerId,
      name: nameOfSubscriber,
      emailId: emailId,
      dateOfBirth: dateOfBirth,
      gender: gender,
      nationality: nationality,
      governmentId: governmentId,
      governmentIdType: governmentIdType,
      occupation: occupation,
      nameOfOccupation: nameOfOccupation,
      permanentAddressSameAsPresentAddress: presentAddressIsPermanent,
      presentAddress: createPresentAddress(),
      permanentAddress: createPermanentAddress(),
      fathersName: fathersName,
      mothersName: mothersName,
      spousesName: spousesName,
      identifiersName: identifiersName,
      identifiersContactNo: identifiersContactNo,
      identifiersNid: identifiersNID,
    };
    RestService.post(
      RemoteURL.CUSTOMER.REGISTRATION.PERSONAL_INFORMATION,
      request,
      extractResponse,
      props.handleServerError,
      false,
      true
    );
  }

  const createPresentAddress = () => {
    let presentAddress = new Address();
    presentAddress.flatNo = presentAddressFlatNo;
    presentAddress.houseNo = presentAddressHouseNo;
    presentAddress.roadNo = presentAddressRoadNo;
    presentAddress.area = presentAddressArea;
    presentAddress.upazilaId = presentAddressUpazilaId;
    presentAddress.postCode = presentAddressPostCode;
    return presentAddress;
  };

  const createPermanentAddress = () => {
    if (presentAddressIsPermanent) {
      return createPresentAddress();
    } else {
      let permanentAddress = new Address();
      permanentAddress.flatNo = permanentAddressFlatNo;
      permanentAddress.houseNo = permanentAddressHouseNo;
      permanentAddress.roadNo = permanentAddressRoadNo;
      permanentAddress.area = permanentAddressArea;
      permanentAddress.upazilaId = permanentAddressUpazilaId;
      permanentAddress.postCode = permanentAddressPostCode;
      return permanentAddress;
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      registerPersonalInformation();
    }
  }

  function personalInformationForm() {
    return (
      <PersonalInformationForm
        customerType={props.customerType}
        nameOfSubscriber={nameOfSubscriber}
        setNameOfSubscriber={setNameOfSubscriber}
        contactNo={contactNo}
        setContactNo={setContactNo}
        emailId={emailId}
        setEmailId={setEmailId}
        gender={gender}
        setGender={setGender}
        dateOfBirth={dateOfBirth}
        setDateOfBirth={setDateOfBirth}
        nationality={nationality}
        setNationality={setNationality}
        governmentId={governmentId}
        setGovernmentId={setGovernmentId}
        governmentIdType={governmentIdType}
        setGovernmentIdType={setGovernmentIdType}
        occupation={occupation}
        setOccupation={setOccupation}
        nameOfOccupation={nameOfOccupation}
        setNameOfOccupation={setNameOfOccupation}
        formReadOnly={false}
      />
    );
  }
  function presentAddressForm() {
    return (
      <fieldset className="scheduler-border">
        <legend className="scheduler-border">Present Address:</legend>
        <AddressForm
          allDivision={props.allDivision}
          flatNo={presentAddressFlatNo}
          setFlatNo={setPresentAddressFlatNo}
          houseNo={presentAddressHouseNo}
          setHouseNo={setPresentAddressHouseNo}
          isHouseNoRequired={false}
          roadNo={presentAddressRoadNo}
          setRoadNo={setPresentAddressRoadNo}
          isRoadNoRequired={false}
          area={presentAddressArea}
          setArea={setPresentAddressArea}
          upazilaId={presentAddressUpazilaId}
          setUpazilaId={setPresentAddressUpazilaId}
          isZoneNeeded={true}
          postCode={presentAddressPostCode}
          setPostCode={setPresentAddressPostCode}
          formReadOnly={false}
        />
      </fieldset>
    );
  }

  function permanentAddressForm() {
    return (
      <fieldset className="scheduler-border">
        <legend className="scheduler-border">Permanent Address:</legend>
        <Form.Check
          type="checkbox"
          label="Same as Present Address"
          checked={presentAddressIsPermanent}
          onChange={() => {
            setPresentAddressIsPermanent(!presentAddressIsPermanent);
          }}
        />
        {presentAddressIsPermanent ? null : (
          <AddressForm
            allDivision={props.allDivision}
            flatNo={permanentAddressFlatNo}
            setFlatNo={setPermanentAddressFlatNo}
            houseNo={permanentAddressHouseNo}
            setHouseNo={setPermanentAddressHouseNo}
            isHouseNoRequired={false}
            roadNo={permanentAddressRoadNo}
            setRoadNo={setPermanentAddressRoadNo}
            isRoadNoRequired={false}
            area={permanentAddressArea}
            setArea={setPermanentAddressArea}
            upazilaId={permanentAddressUpazilaId}
            setUpazilaId={setPermanentAddressUpazilaId}
            isZoneNeeded={false}
            postCode={permanentAddressPostCode}
            setPostCode={setPermanentAddressPostCode}
            formReadOnly={false}
          />
        )}
      </fieldset>
    );
  }
  function otherInformationForm() {
    return (
      <fieldset className="scheduler-border">
        <legend className="scheduler-border">Other Information:</legend>
        <OtherInformationForm
          fathersName={fathersName}
          setFathersName={setFathersName}
          mothersName={mothersName}
          setMothersName={setMothersName}
          spousesName={spousesName}
          setSpousesName={setSpousesName}
          identifiersName={identifiersName}
          setIdentifiersName={setIdentifiersName}
          identifiersContactNo={identifiersContactNo}
          setIdentifiersContactNo={setIdentifiersContactNo}
          identifiersNid={identifiersNID}
          setIdentifiersNID={setIdentifiersNID}
          formReadOnly={false}
        />
      </fieldset>
    );
  }

  return (
    <div className="Registration h-100">
       <br></br>
      <br></br>
      <h4 className="singleRowField">
        <strong>Personal information</strong>
      </h4>
      <br></br>
      <br></br>
      <Form  noValidate validated={validated} onSubmit={handleSubmit}>
        {personalInformationForm()}
        {presentAddressForm()}
        {permanentAddressForm()}
        {otherInformationForm()}
        <Button className="singleRowField" block size="lg" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};
