import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileMenu from "./ProfileMenu";

const SideBar = (props) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        className="bg-body-tertiary col-sm-12 col-md-3 col-lg-3 col-xl-2"
      >
        <Container className="">
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                  PCL
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto sidebar col-md-3 col-lg-3 col-xl-2 custom-css">
                  <ul>{props.children}</ul>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <div className="d-block-xxs"><ProfileMenu /></div>
          {/* </div> */}
        </Container>
      </Navbar>
    </>
  );
};
export default SideBar;
