import React, { useState } from 'react'
import RestService, { RemoteURL } from '../../../helper/RestService';
import NotificationService from '../../../helper/NotificationService';
import Chart from "react-apexcharts";
import Datetime from "react-datetime";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';

export default function MonthlyMaxUtilization() {
    const [chartData, setChartData] = useState([]);
    const [month, setMonth] = useState(moment().format("M"));
    const [year, setYear] = useState(moment().format("Y"));
    const [spin, setSpin] = useState(false);


    React.useEffect(() => {
        setSpin(true);
        fetchData();
    }, [month, year]);

    const fetchData = function () {
        let url = RemoteURL.BTRC_PANEL.UTILIZATION_MONTHLY + '?month=' + month + '&year=' + year;

        RestService.get(
            url,
            (data) => {
                updateChartData(data);
            },
            (error) => {
                setSpin(false);
                NotificationService.showRestServiceError(error);
            }
        );


        function updateChartData(data) {
            const _data = data.map((item) => (
                {
                    x: item.localDate,
                    y: item.percentage
                }
            ))
            setChartData(_data);
            setSpin(false);
        }
    }

    const handleDateChange = (value) => {
        setChartData([]);
        setMonth(value.format("M"));
        setYear(value.format("Y"));
    }

    const validDate =(current) =>{
        var openingDate = moment("2021-10-01T00:00:00");
        return current.isAfter(openingDate);
      }
    

    return (
        <div>

            <Card bg="light" text="dark">
                <Card.Header>
                    <div class="row">
                        <div class=" row col-md-12 boxlayout">
                        <div class=" col-md-4"> <span >
                                <FontAwesomeIcon icon={faSync} spin={spin} size="2x" />
                            </span>
                            </div>
                            <div class=" col-md-6">  
                            <div style={{ maxWidth: 200, display: 'flex', alignItems: 'center' }}>
                            Month: <Datetime
                                inputProps={{ placeholder: "Select a month" }}
                                dateFormat="YYYY-MMM"
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={handleDateChange}
                                value={moment(month + "-" + year, "M-YYYY")}
                                isValidDate={validDate}
                            />
                        </div>
                            </div>
                            
                        </div>
                    </div>

                </Card.Header>
                <Card.Body>
                    <div>
                    <Chart
                        options={{
                            noData: {
                                text: "No data found",
                                align: 'center',
                                verticalAlign: 'middle',
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    color: "#000000",
                                    fontSize: '14px',
                                    fontFamily: "Helvetica"
                                }
                            },
                            colors: ['#2E93fA'],
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                title: {
                                    text: 'Date'
                                },
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Max Utilization (%)'
                                },
                                min: 0,
                                max: 100,
                                labels: {
                                    formatter: function (value, timestamp) {
                                        return value + '%'
                                    }
                                },
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 1
                            },
                            tooltip: {
                                x: {
                                    format: 'dd MMM yyyy',
                                }
                            }
                        }
                        }
                        series={[
                            {
                                color: '#008FFB',
                                name: 'Utilization',
                                data: chartData,
                            }
                        ]}
                        type='bar'
                        width="100%"
                        height={520}
                    />
                </div>
            </Card.Body>
        </Card>
        </div>
    )
}
