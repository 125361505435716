import React from "react";
import { withRouter } from "react-router";
import CustomerSupportDetail from "../common/CustomerSupportDetail";

class CustomerPortalSupportDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <CustomerSupportDetail supportId={this.props.match.params.supportId} />
      </div>
    );
  }
}

export default withRouter(CustomerPortalSupportDetail);
