import React from "react";
import { Card, CardGroup, Col, Row, Table } from "react-bootstrap";
import { withRouter } from "react-router";
import { AccountType, InvoiceType } from "../domainObject/DomainDataType";
import NotificationService from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { currencyFormatter, formatAddress, MonthName, roundToNDecimal } from "../helper/Utility";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  numberFilter,
} from "react-bootstrap-table2-filter";
import { textFilter } from "react-bootstrap-table2-filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

class InvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceInfo: null,
      spin: true,
    };
  }

  extractInvoiceInfoResponse(result) {
    this.setState({
      invoiceInfo: result,
    });
    this.props.setInvoiceInfo(result);
    this.setState({
      spin: false,
    });
  }

  errorResponse(error) {
    NotificationService.showRestServiceError(error);
    this.setState({
      spin: false,
    });
  }

  fetchInvoiceInfo() {
    let fullUrl = RemoteURL.INVOICE.DETAIL + "/" + this.props.invoiceId;
    RestService.get(
      fullUrl,
      this.extractInvoiceInfoResponse.bind(this),
      this.errorResponse.bind(this)
    );
  }

  componentDidMount() {
    this.fetchInvoiceInfo();
  }


  monthlyCallDetail(callDetailList) {
    let row = [];
    if (callDetailList != null) {
      for (let i = 0; i < callDetailList.length; i++) {
        <tr>
          <td>{callDetailList[i].source}</td>
          <td>{callDetailList[i].destination}</td>
          <td>{callDetailList[i].totalMinutes}</td>
          <td>{callDetailList[i].chargedAmount}</td>
        </tr>;
      }
    }
    return (
      <div>
        <tr>
          <td>Source</td>
          <td>Destination</td>
          <td>Total minutes</td>
          <td>Charged Amount</td>
        </tr>
        {row}
      </div>
    );
  }
  showMonthlyBillDetail() {
    var CALL_SUMMARY_TABLE_COLUMNS = [
      {
        dataField: "connectionInfo.iptspNumber",
        text: "IPTSP Number",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "connectionInfo.reference",
        text: "Connection Id",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "connectionInfo.totalMinute",
        text: "Total Minutes",
        sort: true,
        filter: numberFilter(),
        formatter: roundToNDecimal,
      },
      {
        dataField: "connectionInfo.chargedAmount",
        text: "Charged Amount",
        sort: true,
        filter: numberFilter(),
        formatter: currencyFormatter,
      }
    ];

    var CALL_SUMMARY_TABLE_SORTED = [
      {
        dataField: "connectionInfo.iptspNumber",
        order: "desc",
      },
    ];

    var CALL_SUMMARY_EMPTY_TABLE_MESSAGE = () => {
      return "No data found";
    };

    var tableData = [];
  
    if (this.state.invoiceInfo != null) {
      for (let i = 0; i < this.state.invoiceInfo.invoiceDetails.length; i++) {
        let callDetail = this.state.invoiceInfo.invoiceDetails[i].callDetail;
        if (callDetail != null) {
          let totalMinute = 0;
          let chargedAmount = 0;
          for (let j = 0; j < callDetail.length; j++) {
            totalMinute += callDetail[j].totalMinutes;
            chargedAmount += callDetail[j].chargedAmount;
          }
          this.state.invoiceInfo.invoiceDetails[i].connectionInfo.totalMinute = totalMinute;
          this.state.invoiceInfo.invoiceDetails[i].connectionInfo.chargedAmount = chargedAmount;
        }
      }
      tableData = this.state.invoiceInfo.invoiceDetails;
    }


    return (
      <Card>
        <Card.Header>Summary of Current Charges:</Card.Header>
        <Card.Body>
        <div>
            <BootstrapTable
              bootstrap4
              keyField={"id"}
              data={tableData}
              columns={CALL_SUMMARY_TABLE_COLUMNS}
              defaultSorted={CALL_SUMMARY_TABLE_SORTED}
              filter={filterFactory()}
              filterPosition="top"
              pagination={paginationFactory({
                showTotal: true,
                hideSizePerPage: true,
              })}
              bodyStyle={{ overflow: "overlay" }}
              wrapperClasses="table-responsive"
              noDataIndication={CALL_SUMMARY_EMPTY_TABLE_MESSAGE}
            />
          </div>
        </Card.Body>
      </Card>
    );
  }

  partialpaymentButton(cell, row) {
    this.props.partialPayment(row);
  }


  showInvoiceDetail() {

    var INVOICE_DETAIL_TABLE_COLUMNS = [
      {
        dataField: "connectionInfo.iptspNumber",
        text: "IPTSP Number",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "amountExcludingVat",
        text: "Amount(exl vat)",
        sort: true,
        filter: numberFilter(),
        formatter: currencyFormatter,
      },
      {
        dataField: "vatAmount",
        text: "Vat",
        filter: numberFilter(),
        formatter: currencyFormatter,
      },
      {
        dataField: "amount",
        text: "Total Amount",
        sort: true,
        filter: numberFilter(),
        formatter: currencyFormatter,
      },
      {
        dataField: "paidAmount",
        text: "Paid amount",
        sort: true,
        filter: numberFilter(),
        formatter: currencyFormatter,
      },
      {
        dataField: "",
        text: "",
        isDummyField: true,
        formatter: this.partialpaymentButton.bind(this),
      },
    ];

    var INVOICE_DETAIL_TABLE_SORTED = [
      {
        dataField: "connectionInfo.iptspNumber",
        order: "desc",
      },
    ];

    var INVOICE_DETAIL_TABLE_EMPTY_TABLE_MESSAGE = () => {
      return "No data found";
    };

    var tableData = [];
    if (this.state.invoiceInfo != null) {
      tableData = this.state.invoiceInfo.invoiceDetails;
    }
    return (
      <Card>
        <Card.Header>Attention:</Card.Header>
        <Card.Body>
          <div>
            <BootstrapTable
              bootstrap4
              keyField={"id"}
              data={tableData}
              columns={INVOICE_DETAIL_TABLE_COLUMNS}
              defaultSorted={INVOICE_DETAIL_TABLE_SORTED}
              filter={filterFactory()}
              filterPosition="top"
              pagination={paginationFactory({
                showTotal: true,
                hideSizePerPage: true,
              })}
              bodyStyle={{ overflow: "overlay" }}
              wrapperClasses="table-responsive"
              noDataIndication={INVOICE_DETAIL_TABLE_EMPTY_TABLE_MESSAGE}
            />
          </div>
        </Card.Body>
      </Card>
    );
  }

  showGeneralInfo() {
    if (this.state.invoiceInfo !== null) {
      return (
        <Card>
          <Card.Header>
            Info:
          </Card.Header>
          <Card.Body>
            <CardGroup>
              <Card>
                <Card.Body>
                  <Card.Text>
                    <p>
                      Name of purchaser:{" "}
                      <strong>
                        {this.state.invoiceInfo.customerInfo.personalInfo.name}
                      </strong>
                    </p>
                    <p>
                      Address:{" "}
                      <strong>
                        {this.isPrepaidConnections()
                          ? formatAddress(this.getInstallationAddress())
                          : formatAddress(this.getBillingAddress())}
                      </strong>
                    </p>
                    <p>
                      email:{" "}
                      <strong>
                        {this.state.invoiceInfo.customerInfo.personalInfo.emailId}
                      </strong>
                    </p>
                    <p>
                      Phone:{" "}
                      <strong>
                        {this.state.invoiceInfo.customerInfo.personalInfo.contactNo}
                      </strong>
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Text>
                    <p>
                      Invoice No:{" "}
                      <strong>{this.state.invoiceInfo.reference}</strong>
                    </p>
                    <p>
                      Invoice Type: <strong>{this.state.invoiceInfo.type}</strong>
                    </p>
                    <p>
                      Date of Issue:{" "}
                      <strong>{this.state.invoiceInfo.createdDate}</strong>
                    </p>
                    {this.isMonthlyInvoice() ? (
                      <p>
                        Invoice Period:{" "}
                        <strong>
                          {MonthName[this.state.invoiceInfo.billingMonth - 1] +
                            "," +
                            this.state.invoiceInfo.billingYear}
                        </strong>
                      </p>
                    ) : null}
                    <p>
                      Due Date: <strong>{this.state.invoiceInfo.dueDate}</strong>
                    </p>
                    <p>
                      Payment Status:{" "}
                      <strong>{this.state.invoiceInfo.paymentStatus}</strong>
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>{this.props.fullPayment()}</Card.Footer>
              </Card>
            </CardGroup>
          </Card.Body>
        </Card>
      );
    }
  }

  isPrepaidConnections() {
    if (
      this.state.invoiceInfo != null &&
      this.state.invoiceInfo.invoiceDetails[0].connectionInfo.productInfo
        .accountType === AccountType.PRE_PAID
    ) {
      return true;
    } else {
      return false;
    }
  }

  getInstallationAddress() {
    if (this.state.invoiceInfo != null) {
      return this.state.invoiceInfo.invoiceDetails[0].connectionInfo
        .installationAddress;
    }
  }

  getBillingAddress() {
    if (this.state.invoiceInfo != null) {
      return this.state.invoiceInfo.invoiceDetails[0].connectionInfo
        .postPayBillingAddress;
    }
  }

  isMonthlyInvoice() {
    if (
      this.state.invoiceInfo != null &&
      this.state.invoiceInfo.type === InvoiceType.MONTHLY_BILL
    ) {
      return true;
    } else {
      return false;
    }
  }

  isRefillInvoice() {
    if (
      this.state.invoiceInfo != null &&
      this.state.invoiceInfo.type === InvoiceType.REFILL
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
        </Card.Header>
        <Card.Body>
          <div>
            <Row xs={1} md={1} className="g-4">
              <Col>{this.showGeneralInfo()}</Col>

              <Col>
                {" "}
                <br></br>
                <br></br>
                {this.state.invoiceInfo != null ? this.showInvoiceDetail() : null}
              </Col>

              {this.isMonthlyInvoice() ? (
                <Col>
                  {" "}
                  <br></br>
                  <br></br>
                  {this.showMonthlyBillDetail()}
                </Col>
              ) : null}
            </Row>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default withRouter(InvoiceDetail);
