import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../css/Login.css";
import { useHistory, useLocation } from "react-router-dom";
import authService from "./LoginService";
import UserInfo from "../domainObject/UserInfo";
import { RoleService } from "../domainObject/Role";
import RestService, { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";
import { CommonHeader } from "../common/CommonHeader";
import { CommonFooter } from "../common/CommonFooter";
import NotificationService from "../helper/NotificationService";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import CustomModal, { ModalType } from "../common/modal/CustomModal";

const LoginPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const Log_IN_URL = RemoteURL.USER.LOG_IN;
  const USER_INFO_URL = RemoteURL.USER.INFO;

  function validateForm() {
    return userName.length > 0 && password.length > 0;
  }
  function errorHandler(error) {
    NotificationService.showRestServiceError(error);
    setLoadingModalShow(false);
  }

  function getUserInfo(token) {
    function extractResponse(result) {
      let userInfo = extractUserInfo(result);
      authService.setUserInfo(userInfo);
      setLoadingModalShow(false);
      if (location.state && location.state.from) {
        history.replace(location.state.from);
      } else {
        if (userInfo.roles.length > 0) {
          history.replace(userInfo.roles[0].defaultPath);
        } else {
          history.replace(Routes.HOME);
        }
      }
    }

    function extractUserInfo(response) {
      let userInfo = new UserInfo();
      userInfo.id = response.id;
      userInfo.name = response.name;
      userInfo.userName = response.userName;
      userInfo.roles = RoleService.getRoles(response.roles);
      return userInfo;
    }
    RestService.get(USER_INFO_URL, extractResponse, errorHandler);
  }

  function login() {
    function extractResponse(result) {
      authService.setAuthToken(result.authToken);
      authService.setRefreshToken(result.refreshToken);
      getUserInfo(result.authToken);
    }
    let requestBody = {
      username: userName,
      password: password,
    };
    RestService.post(
      Log_IN_URL,
      requestBody,
      extractResponse,
      errorHandler,
      false
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoadingModalShow(true);
    login();
  }

  return (
    <div>
      <CommonHeader />

      <div className="Login h-100">
      <Container style={{marginTop:"20px"}}>
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>User Name</Form.Label>{" "}
            <Form.Control
              autoFocus
              type="username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
          <Link
            to={{
              pathname: Routes.FORGET_PASSWORD,
            }}
          >
            Forgot Password
          </Link>
          </Form.Group>
          <Form.Group size="lg" controlId="password" style={{marginTop:"10px"}}>
          <Button style={{width:"100%"}} block size="lg" type="submit" disabled={!validateForm()}>
            Login
          </Button>
          </Form.Group>
          <br></br>
          <p>
            If you want to take new IPTSP connection, register with us.{" "}
            <Link
              to={{
                pathname: Routes.REGISTRATION.CUSTOMER,
              }}
            >
              Register
            </Link>
          </p>
        </Form>
        </Container>
      </div>
      <CommonFooter />
      <CustomModal
        message={"Logging in ......"}
        openControl={loadingModalShow}
        modalType={ModalType.LOADING_MODAL}
      />
    </div>
  );
};
export default LoginPage;
