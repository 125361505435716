import React from "react";
import RestService from "../../helper/RestService";
import { IsValidValue } from "../../helper/Utility";
import NotificationService from "../../helper/NotificationService";
class MonthlyReportTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorAlert: null,
    };
  }

  transformData(data) {
    let zoneToDataMap = this.props.transformRemoteDataToTableRows(data);
    this.props.setSpinChild(false);
    return zoneToDataMap;
  }

  extractResponse(result) {
    const transformedRows = this.transformData(result);
    this.setState({
      rows: transformedRows,
      errorAlert: null,
    });
  }

  errorHandler(error) {
    this.props.setSpinChild(false);
    NotificationService.showRestServiceError(error);
  }

  fetchData(remoteUrl) {
    this.setState({
      rows : []
    })
    this.props.setSpinChild(true);
    let url = remoteUrl;
    if (IsValidValue(this.props.year) && IsValidValue(this.props.month)) {
      url += "?year=" + this.props.year + "&month=" + this.props.month;
    }
    RestService.get(
      url,
      this.extractResponse.bind(this),
      this.errorHandler.bind(this)
    );
  }

  componentDidMount() {
    this.fetchData(this.props.remoteApiUrl);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.year !== prevProps.year ||
      this.props.month !== prevProps.month ||
      this.props.remoteApiUrl !== prevProps.remoteApiUrl
    ) {
      this.fetchData(this.props.remoteApiUrl);
    }
  }

  render() {
    var rows = this.state.rows.map(function (item, i) {
      var entry = item.map(function (element, j) {
        return (
          <td colspan="1" key={j}>
            {" "}
            {element}{" "}
          </td>
        );
      });
      return <tr key={i}> {entry} </tr>;
    });
    return (
      <div class="table-responsive">
        {this.state.errorAlert}
        <table class="table table-bordered .table-responsive table-sm">
          {this.props.thead}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default MonthlyReportTable;
