import React from "react";

import {
  textFilter,
  dateFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import { InvoiceType, PaymentStatus } from "../domainObject/DomainDataType";
import { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";
import { InvoiceTypeSelectFilterOption } from "../customerPortal/CustomerInvoiceListDataInfo";
import { getMonthFromNumber, getMonthNumberFilter, getYearFormatter, getYearNumberFilter } from "../helper/Utility";

function buttonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.EMPLOYEE_PORTAL.INVOICE_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>
  );
}



const EmployeeInvoiceListDataInfo = {
  remoteApiUrl: RemoteURL.INVOICE.SEARCH,
  SelectFilterOption: InvoiceTypeSelectFilterOption,

  defaultSorted: [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "createdDate",
      text: "Creation time",
      filter: dateFilter(),
      sort: true,
    },

    {
      dataField: "reference",
      text: "Id",
      filter: textFilter()
    },
    {
      dataField: "customerInfo.user.userName",
      text: "Username",
      filter: textFilter()
    },
    {
      dataField: "customerInfo.personalInfo.name",
      text: "Name",
      filter: textFilter()
    },
    {
      dataField: "customerInfo.companyInfo.name",
      text: "Company name",
      filter: textFilter()
    },

    {
      dataField: "type",
      text: "Invoice type",
      filter: selectFilter({
        options: InvoiceTypeSelectFilterOption.InvoiceType.option,
      }),
    },
    {
      dataField: "billingYear",
      text: "Year",
      filter: getYearNumberFilter(),
      sort: true,
      formatter: getYearFormatter
  },
  {
      dataField: "billingMonth",
      text: "Month",
      filter: getMonthNumberFilter(),
      sort: true,
      formatter:getMonthFromNumber
  },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      filter: selectFilter({
        options: InvoiceTypeSelectFilterOption.PaymentStatus.option,
      }),
      sort: true,
    },
    {
      dataField: "amount",
      text: "Total amount",
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: "remainingAmount",
      text: "Unpaid amount",
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: buttonAdd,
    },
  ],
};

export default EmployeeInvoiceListDataInfo;
