import moment from 'moment';
import React, { useState } from 'react'
import RestService, { RemoteURL } from '../../../helper/RestService';
import NotificationService from '../../../helper/NotificationService';
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft, faSync } from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';



export default function Weekly() {
    const [chartData, setChartData] = useState([]);
    const [spin, setSpin] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));

    const fetchData = function () {

        let url = RemoteURL.BTRC_PANEL.UTILIZATION_DATE_RANGE + '?startDate=' + startDate + '&endDate=' + endDate;

        RestService.get(
            url,
            (data) => {
                updateChartData(data);
            },
            (error) => {
                NotificationService.showRestServiceError(error);
                setSpin(false);
            }
        );


        function updateChartData(data) {
            const _data = data.map((item) => (
                {
                    x: new Date(item.timestamp).getTime(),
                    y: item.count
                }
            ))
            setChartData(_data);
            setSpin(false);
        }
    }

    const fetchPreviousWeek = (() => {
        setEndDate(startDate);
        setStartDate(moment(startDate).subtract(6, 'days').format('YYYY-MM-DD 00:00:00'))
        fetchData();
    })

    const fetchNextWeek = (() => {
        setStartDate(endDate);
        setEndDate(moment(endDate).add(6, 'days').format('YYYY-MM-DD 00:00:00'))
        fetchData();
    })

    React.useEffect(() => {
        setSpin(true);
        setChartData([]);
        fetchData();

    }, [startDate]);

    return (
        <div>
            <Card bg="light" text="dark">
                <Card.Header>
                    <div class="row">
                        <div class=" row col-md-12 boxlayout">
                        <div class=" col-md-4"> <span >
                                <FontAwesomeIcon icon={faSync} spin={spin} size="2x" />
                            </span>
                            </div>
                            <div class=" col-md-3">  <span class="clickableSpan" onClick={fetchPreviousWeek}>
                                <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                            </span>
                            </div>
                            <div class=" col-md-3">  {moment().isSameOrBefore(endDate) ?
                                null :
                                <span class="clickableSpan" onClick={fetchNextWeek}>
                                    <FontAwesomeIcon icon={faChevronRight} size="2x" />
                                </span>
                            }
                            </div>
                           
                        </div>
                    </div>

                </Card.Header>
                <Card.Body>
                    <div>
                        <Chart
                            options={{
                                noData: {
                                    text: "No data found",
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    offsetX: 0,
                                    offsetY: 0,
                                    style: {
                                        color: "#000000",
                                        fontSize: '14px',
                                        fontFamily: "Helvetica"
                                    }
                                },
                                colors: ['#2E93fA'],
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    title: {
                                        text: 'Date'
                                    },
                                    type: 'datetime',
                                    labels: {
                                        datetimeUTC: false
                                    }
                                },
                                yaxis: {
                                    title: {
                                        text: 'Active Calls'
                                    },
                                    min: 0,
                                    // max: 100,
                                    // labels: {
                                    //     formatter: function (value, timestamp) {
                                    //         return value + '%'
                                    //     }
                                    // }
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 1
                                },
                                tooltip: {
                                    x: {
                                        format: 'dd MMM yyyy HH:mm',
                                    }
                                }
                            }
                            }
                            series={[
                                {
                                    color: '#008FFB',
                                    name: 'Utilization',
                                    data: chartData,
                                }
                            ]}

                            type='area'
                            width="100%"
                            height={520}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
