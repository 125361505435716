import React from "react";
import { Button, Form, Image, Modal, Tab, Tabs } from "react-bootstrap";
import {
  CorporateVerificationType,
  CustomerType,
  CustomerStatus,
  FileType,
  PersonalVerificationType,
} from "../domainObject/DomainDataType";
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import RestService, { RemoteURL } from "../helper/RestService";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const GET_FILE_URL = RemoteURL.FILE.GET;

//TODO: Add security
class CustomerAttachmentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: FileType.PHOTO,
      pdfNumOfPages: null,
      pdfPageNumber: 1,

      photoUrl: "",
      photoVerificationUI: null,
      signatureUrl: "",
      signatureVerificationUI: null,
      governmentIdFileUrl: "",
      governmentIdVerificationUI: null,
      authorizationUrl: "",
      authorizationVerificationUI: null,
      tradeLicenseUrl: "",
      tradeLicenseVerificationUI: null,
      tinUrl: "",
      tinVerificationUI: null,
      binUrl: "",
      binVerificationUI: null,

      showVerificationForm: false,
      verificationFormValidated: false,
      verificationFormType: null,
      verificationFileId: null,
      verificationFormsStatus: true,
      verificationFormErrorReason: null,

      errorAlert: null,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onPdfDocumentLoadSuccess = this.onPdfDocumentLoadSuccess.bind(this);
    this.nextPdfPage = this.nextPdfPage.bind(this);
    this.previousPdfPage = this.previousPdfPage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.verificationData !== null &&
      this.props.customerInfo !== null
    ) {
      if (
        prevProps.verificationData === null ||
        prevProps.verificationData.version !==
          this.props.verificationData.version
      ) {
        this.handleTabChange(this.state.activeTabKey);
      }
    }
  }

  getPersonalAttachmentFileId(fileType) {
    let fileId = null;

    if (fileType in this.props.customerInfo.personalAttachment) {
      fileId = this.props.customerInfo.personalAttachment[fileType].id;
    }

    return fileId;
  }

  getCompanyAttachmentFileId(fileType) {
    let fileId = null;
    if (fileType in this.props.customerInfo.companyInfo.companyAttachment) {
      fileId =
        this.props.customerInfo.companyInfo.companyAttachment[fileType].id;
    }
    return fileId;
  }

  ////************** PDF VIEWER START */
  onPdfDocumentLoadSuccess({ numPages }) {
    this.setState({
      pdfNumOfPages: numPages,
    });
  }

  changePage(offset) {
    this.setState({
      pdfPageNumber: this.state.pdfPageNumber + offset,
    });
  }
  previousPdfPage() {
    this.changePage(-1);
  }

  nextPdfPage() {
    this.changePage(1);
  }

  showPdf(url) {
    return (
      <div>
        <Document
          file={url}
          onLoadError={console.error}
          onLoadSuccess={this.onPdfDocumentLoadSuccess}
        >
          <Page width={480} pageNumber={this.state.pdfPageNumber} />
        </Document>
        <div>
          <p>
            Page{" "}
            {this.state.pdfPageNumber || (this.state.pdfNumOfPages ? 1 : "--")}{" "}
            of {this.state.pdfNumOfPages || "--"}
          </p>
          <button
            type="button"
            disabled={this.state.pdfPageNumber <= 1}
            onClick={this.previousPdfPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={this.state.pdfPageNumber >= this.state.pdfNumOfPages}
            onClick={this.nextPdfPage}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  ////************** PDF VIEWER END */

  isCorporateCustomer() {
    return (
      this.props.customerInfo !== null &&
      this.props.customerInfo.customerType === CustomerType.CORPORATE
    );
  }
  handleTabChange(tabKey) {
    switch (tabKey) {
      case FileType.PHOTO: {
        this.setState({
          photoUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getPersonalAttachmentFileId(FileType.PHOTO),
          photoVerificationUI: this.showVerificationData(
            PersonalVerificationType.PHOTO,
            this.getPersonalAttachmentFileId(FileType.PHOTO)
          ),
        });

        break;
      }
      case FileType.SIGNATURE: {
        this.setState({
          signatureUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getPersonalAttachmentFileId(FileType.SIGNATURE),
          signatureVerificationUI: this.showVerificationData(
            PersonalVerificationType.SIGNATURE,
            this.getPersonalAttachmentFileId(FileType.SIGNATURE)
          ),
        });

        break;
      }
      case FileType.GOVERNMENT_ID: {
        this.setState({
          governmentIdFileUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getPersonalAttachmentFileId(FileType.GOVERNMENT_ID),
          governmentIdVerificationUI: this.showVerificationData(
            PersonalVerificationType.GOVERNMENT_ID,
            this.getPersonalAttachmentFileId(FileType.GOVERNMENT_ID)
          ),
        });

        break;
      }
      case FileType.AUTHORIZATION_TO_PERSONNEL: {
        this.setState({
          authorizationUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getCompanyAttachmentFileId(
              FileType.AUTHORIZATION_TO_PERSONNEL
            ),
          authorizationVerificationUI: this.showVerificationData(
            CorporateVerificationType.AUTHORIZATION_TO_PERSONNEL,
            this.getCompanyAttachmentFileId(FileType.AUTHORIZATION_TO_PERSONNEL)
          ),
        });

        break;
      }
      case FileType.TRADE_LICENCE: {
        this.setState({
          tradeLicenseUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getCompanyAttachmentFileId(FileType.TRADE_LICENCE),
          tradeLicenseVerificationUI: this.showVerificationData(
            CorporateVerificationType.TRADE_LICENCE,
            this.getCompanyAttachmentFileId(FileType.TRADE_LICENCE)
          ),
        });

        break;
      }
      case FileType.TIN: {
        this.setState({
          tinUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getCompanyAttachmentFileId(FileType.TIN),
          tinVerificationUI: this.showVerificationData(
            CorporateVerificationType.TIN,
            this.getCompanyAttachmentFileId(FileType.TIN)
          ),
        });

        break;
      }
      case FileType.BIN: {
        this.setState({
          binUrl:
            RemoteURL.IPTSP_SERVER_BASE_URL +
            GET_FILE_URL +
            "/" +
            this.getCompanyAttachmentFileId(FileType.BIN),
          binVerificationUI: this.showVerificationData(
            CorporateVerificationType.BIN,
            this.getCompanyAttachmentFileId(FileType.BIN)
          ),
        });

        break;
      }
      default: {
      }
    }

    this.setState({
      activeTabKey: tabKey,
    });
  }

  ///********** VERIFICATION FORM START */
  extractVerificationResponse(response) {
    if (response != null) {
      NotificationService.show(
        NotificationMessage.CUSTOMER_ATTACHMENT_VERIFICATION_SUCCESS,
        NotificationType.SUCCESS
      );
      this.props.updateVerificationData();
    }
  }

  errorFunction(error) {
    NotificationService.showRestServiceError(error);
  }

  verifyCustomer() {
    let url = RemoteURL.CUSTOMER.VERIFICATION.ATTACHMENT;
    let requestBody = {
      customerId: this.props.customerInfo.id,
      fileId: this.state.verificationFileId,
      type: this.state.verificationFormType,
      status: this.state.verificationFormsStatus,
      errorReason: this.state.verificationFormErrorReason,
    };

    RestService.post(
      url,
      requestBody,
      this.extractVerificationResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  submitVerificationForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      verificationFormValidated: true,
    });
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.verifyCustomer();
      this.setState({
        showVerificationForm: false,
      });
    }
  }
  getVerificationForm() {
    return (
      <Form
        noValidate
        validated={this.state.verificationFormValidated}
        onSubmit={this.submitVerificationForm.bind(this)}
      >
        <Form.Group controlId="formGridAddress2 form-floating">
          <Form.Label>VerificationType</Form.Label>
          <Form.Control
            value={this.state.verificationFormType}
            readOnly={true}
          />
        </Form.Group>
        <Form.Group controlId="formBasicSelect">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            value={this.state.verificationFormsStatus}
            onChange={(e) => {
              this.setState({
                verificationFormsStatus: e.target.value,
              });
            }}
          >
            <option value={true}>Accept</option>
            <option value={false}>Reject</option>
          </Form.Control>
        </Form.Group>
        {this.state.verificationFormsStatus == "false" ? (
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Error reason</Form.Label>
            <Form.Control
              placeholder="Reason"
              value={this.state.verificationFormErrorReason}
              onChange={(e) => {
                this.setState({
                  verificationFormErrorReason: e.target.value,
                });
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input error reason
            </Form.Control.Feedback>
          </Form.Group>
        ) : null}
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={this.handleVerificationFormClose.bind(this)}
          >
            Close
          </Button>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
  handleVerificationFormClose() {
    this.setState({
      showVerificationForm: false,
    });
  }
  verificationForm() {
    return (
      <Modal
        show={this.state.showVerificationForm}
        onHide={this.handleVerificationFormClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.getVerificationForm()}</Modal.Body>
      </Modal>
    );
  }

  showVerificationForm(verificationType, fileId) {
    this.setState({
      showVerificationForm: true,
      verificationFormType: verificationType,
      verificationFileId: fileId,
    });
  }

  ///********** VERIFICATION FORM END */

  showVerificationButton(verificationType, fileId) {
    if (
      this.props.eligibleToVerify &&
      this.props.customerInfo !== null &&
      this.props.customerInfo.status === CustomerStatus.REGISTERED
    ) {
      return (
        <Button
          variant="primary"
          onClick={this.showVerificationForm.bind(
            this,
            verificationType,
            fileId
          )}
        >
          Verify
        </Button>
      );
    } else {
      return null;
    }
  }

  showVerificationData(verificationType, fileId) {
    if (verificationType in this.props.verificationData.data) {
      let data = this.props.verificationData.data[verificationType];
      if (data.status) {
        return (
          <h2>
            <FontAwesomeIcon icon={faCheckCircle} color="green" size="2x" />
          </h2>
        );
      } else {
        return (
          <div>
              <FontAwesomeIcon icon={faTimesCircle} color="red" size="3x" />
            <h3>
              Reason: <strong> {data.errorReason}</strong>
            </h3>
            {this.showVerificationButton(verificationType, fileId)}
          </div>
        );
      }
    } else {
      return <div>{this.showVerificationButton(verificationType, fileId)}</div>;
    }
  }

  render() {
    return (
      <div>
        <Tabs
          defaultActiveKey={FileType.PHOTO}
          activeKey={this.state.activeTabKey}
          className="mb-3"
          onSelect={(k) => this.handleTabChange(k)}
        >
          <Tab eventKey={FileType.PHOTO} title="Photo">
            <Image src={this.state.photoUrl} />
            {this.state.photoVerificationUI}
          </Tab>
          <Tab eventKey={FileType.SIGNATURE} title="Signature">
            <Image src={this.state.signatureUrl} />
            {this.state.signatureVerificationUI}
          </Tab>
          <Tab eventKey={FileType.GOVERNMENT_ID} title="Government Id">
            {this.showPdf(this.state.governmentIdFileUrl)}
            {this.state.governmentIdVerificationUI}
          </Tab>
          {this.isCorporateCustomer() ? (
            <Tab
              eventKey={FileType.AUTHORIZATION_TO_PERSONNEL}
              title="Authorization to personnel"
            >
              {this.showPdf(this.state.authorizationUrl)}
              {this.state.authorizationVerificationUI}
            </Tab>
          ) : null}
          {this.isCorporateCustomer() ? (
            <Tab eventKey={FileType.TRADE_LICENCE} title="Trade License">
              {this.showPdf(this.state.tradeLicenseUrl)}
              {this.state.tradeLicenseVerificationUI}
            </Tab>
          ) : null}
          {this.isCorporateCustomer() ? (
            <Tab eventKey={FileType.TIN} title="tin">
              {this.showPdf(this.state.tinUrl)}
              {this.state.tinVerificationUI}
            </Tab>
          ) : null}
          {this.isCorporateCustomer() ? (
            <Tab eventKey={FileType.BIN} title="bin">
              {this.showPdf(this.state.binUrl)}
              {this.state.binVerificationUI}
            </Tab>
          ) : null}
        </Tabs>
        {this.verificationForm()}
      </div>
    );
  }
}

export default CustomerAttachmentView;
