import React from "react";
import { withRouter } from "react-router";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import ClientCdrReportDataInfo from "./ClientCdrReportDataInfo";


class CustomerCdrReportPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() { }



    render() {
        return (
            <div>
                <ReportWithSearchAndFilterTable
                    dataInfo={ClientCdrReportDataInfo} />
            </div>
        );
    }
}

export default withRouter(CustomerCdrReportPanel);
