import React from "react";
import { Form } from "react-bootstrap";
import { REGEX } from "../../helper/Utility";

export const OTPInputForm = (props) => {
  function checkAndSetOTP(value) {
    if (
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
    ) {
      props.setValue(value);
    }
  }

  return (
    <div>
      <Form.Group size="lg" controlId="password">
        <Form.Label>OTP</Form.Label>
        <Form.Control
          type="text"
          value={props.value}
          required
          onChange={(e) => checkAndSetOTP(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please input one time password
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
