import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { RequiredMarker } from "../common/form/RequiredMarker";
import {
  CustomerSupportIssueSummery,
  CustomerSupportIssueType,
} from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import authService from "../login/LoginService";

import { useHistory } from "react-router";
import { Routes } from "../route/Routes";
import NotificationService from "../helper/NotificationService";

export const CustomerSupportReqPanel = (props) => {
  const [validated, setValidated] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [issueType, setIssueType] = useState(null);
  const [connectionId, setConnectionId] = useState(null);
  const [issueSummery, setIssueSummery] = useState(null);
  const [issueDetail, setIssueDetail] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [issueId, setIssueId] = useState(null);

  const history = useHistory();

  function redirect() {
    setSuccessModal(false);
    if (props.successCaseHandle != null) {
      props.successCaseHandle();
    }
    history.replace(Routes.CUSTOMER.PORTAL.SUPPORT_LIST);
  }
  function formSubmitSuccessModal() {
    return (
      <Modal show={successModal} onHide={redirect}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Issues submitted successfully. Issue id : {issueId}.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={redirect}>
            Go
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function createCustomerSupportRequest() {
    let request = {};
    request.issueType = issueType;
    request.connectionId = connectionId;
    request.issueSummery = issueSummery;
    request.issueDetail = issueDetail;
    return request;
  }

  function submitIssue() {
    function extractResponse(result) {
      setIssueId(result.reference);
    }

    let request = createCustomerSupportRequest();
    RestService.post(
      RemoteURL.CUSTOMER.SUPPORT.CREATE,
      request,
      extractResponse,
      NotificationService.showRestServiceError
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      submitIssue();
    }
  }

  function getCustomerInfo() {
    function extractData(response) {
      setCustomerInfo(response);
    }

    let userInfo = authService.getUserInfo();
    RestService.get(
      RemoteURL.CUSTOMER.GET.Detail + "/" + userInfo.id,
      extractData,
      NotificationService.showRestServiceError
    );
  }
  useEffect(() => {
    if (customerInfo == null) {
      getCustomerInfo();
    }
  });
  useEffect(() => {
    if (issueId != null) {
      setSuccessModal(true);
    }
  }, [issueId]);
  return (
    <div className="Registration h-100">
      <h5 className="singleRowField">
        <strong>Support request:</strong>
      </h5>
      <br></br>
      {customerInfo != null ? (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formGridAddress2 form-floating">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              value={customerInfo.user.userName}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group controlId="formGridAddress2 form-floating">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={customerInfo.personalInfo.emailId}
              readOnly={true}
            />
          </Form.Group>
          {customerInfo.companyInfo != null ? (
            <Form.Group controlId="formGridAddress2 form-floating">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="email"
                value={customerInfo.companyInfo.name}
                readOnly={true}
              />
            </Form.Group>
          ) : null}
          <Form.Group controlId="formBasicSelect">
            <Form.Label>Issue Type</Form.Label> <RequiredMarker />
            <Form.Control
              as="select"
              value={issueType}
              onChange={(e) => {
                setIssueType(e.target.value);
              }}
              required
            >
              <option value={""}>Click to Select issue type</option>
              <option value={CustomerSupportIssueType.GENERAL.key}>
                {CustomerSupportIssueType.GENERAL.label}
              </option>
              <option value={CustomerSupportIssueType.CONNECTION.key}>
                {CustomerSupportIssueType.CONNECTION.label}
              </option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a issue type
            </Form.Control.Feedback>
          </Form.Group>
          {issueType === CustomerSupportIssueType.CONNECTION.key ? (
            <Form.Group controlId="formBasicSelect">
              <Form.Label>Connection number</Form.Label> <RequiredMarker />
              <Form.Control
                as="select"
                value={connectionId}
                onChange={(e) => {
                  setConnectionId(e.target.value);
                }}
                required
              >
                <option value={""}>Click to Select connection</option>
                {customerInfo.activeConnections.map((connection, i) => (
                  <option value={connection.id}>
                    {connection.iptspNumber}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a connection
              </Form.Control.Feedback>
            </Form.Group>
          ) : null}
          <Form.Group controlId="formBasicSelect">
            <Form.Label>Issue summery</Form.Label> <RequiredMarker />
            <Form.Control
              as="select"
              value={issueSummery}
              onChange={(e) => {
                setIssueSummery(e.target.value);
              }}
              required
            >
              <option value={""}>Click to Select issue summery</option>
              <option
                value={CustomerSupportIssueSummery.CUSTOMER_REGISTRATION.key}
              >
                {CustomerSupportIssueSummery.CUSTOMER_REGISTRATION.label}
              </option>
              <option
                value={CustomerSupportIssueSummery.ACCOUNT_CONVERSION.key}
              >
                {CustomerSupportIssueSummery.ACCOUNT_CONVERSION.label}
              </option>
              <option
                value={CustomerSupportIssueSummery.CREDIT_LIMIT_INCREASE.key}
              >
                {CustomerSupportIssueSummery.CREDIT_LIMIT_INCREASE.label}
              </option>
              <option value={CustomerSupportIssueSummery.REFILL_ISSUE.key}>
                {CustomerSupportIssueSummery.REFILL_ISSUE.label}
              </option>
              <option
                value={CustomerSupportIssueSummery.BILL_PAYMENT_ISSUE.key}
              >
                {CustomerSupportIssueSummery.BILL_PAYMENT_ISSUE.label}
              </option>
              <option value={CustomerSupportIssueSummery.OTHERS.key}>
                {CustomerSupportIssueSummery.OTHERS.label}
              </option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a issue summery
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Issue detail</Form.Label> <RequiredMarker />
            <Form.Control
              as="textarea"
              rows={10}
              value={issueDetail}
              onChange={(e) => {
                setIssueDetail(e.target.value);
              }}
              required
              maxLength="1000"
            />
            <Form.Control.Feedback type="invalid">
              Please input detail
            </Form.Control.Feedback>
          </Form.Group>
          <Button className="singleRowField" block size="lg" type="submit">
            Submit
          </Button>
          <br></br>
        </Form>
      ) : null}
      {formSubmitSuccessModal()}
    </div>
  );
};
