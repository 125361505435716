import authService from "../login/LoginService";
import { Routes } from "../route/Routes";

const Role = {
  ADMIN: { value: "Admin", defaultPath: null },
  User: { value: "User", defaultPath: null },
  BTRC: { value: "BTRC", defaultPath: Routes.BTRC_PORTAL.HOME },
  EMPL_SALES_AND_MARKETING: {
    value: "EMPL_SALES_AND_MARKETING",
    defaultPath: Routes.EMPLOYEE_PORTAL.HOME,
  },
  EMPL_TECHNICAL: {
    value: "EMPL_TECHNICAL",
    defaultPath: Routes.EMPLOYEE_PORTAL.HOME,
  },
  EMPL_ACCOUNT_AND_FINANCE: {
    value: "EMPL_ACCOUNT_AND_FINANCE",
    defaultPath: Routes.EMPLOYEE_PORTAL.HOME,
  },
  CUST_INDIVIDUAL: {
    value: "CUST_INDIVIDUAL",
    defaultPath: Routes.CUSTOMER.PORTAL.HOME,
  },
  CUST_COMPANY: {
    value: "CUST_COMPANY",
    defaultPath: Routes.COMPANY.PORTAL.HOME,
  },
  CUST_AUTHORIZED: {
    value: "CUST_AUTHORIZED",
    defaultPath: Routes.CUSTOMER.PORTAL.HOME,
  },
};

const getRoles = function (remoteRoles) {
  let i = 0;
  let roles = [];
  for (i; i < remoteRoles.length; i++) {
    let role = getRole(remoteRoles[i]);
    roles.push(role);
  }
  return roles;
};

function getRole(roleValue) {
  for (let key in Role) {
    if (Role.hasOwnProperty(key)) {
      if (Role[key].value.toUpperCase() === roleValue.toUpperCase()) {
        return Role[key];
      }
    }
  }
}

const isSalesAndMarketingEmployee = () => {
  let employeeRole = authService.getUserInfo().roles;
  let i = 0;
  for (i; i < employeeRole.length; i++) {
    if (employeeRole[i].value === Role.EMPL_SALES_AND_MARKETING.value) {
      return true;
    }
  }
  return false;
};

const isTechnicalEmployee = () => {
  let employeeRole = authService.getUserInfo().roles;
  let i = 0;
  for (i; i < employeeRole.length; i++) {
    if (employeeRole[i].value === Role.EMPL_TECHNICAL.value) {
      return true;
    }
  }
  return false;
};

const isFinanceAndAccountingEmployee = () => {
  let employeeRole = authService.getUserInfo().roles;
  let i = 0;
  for (i; i < employeeRole.length; i++) {
    if (employeeRole[i].value === Role.EMPL_ACCOUNT_AND_FINANCE.value) {
      return true;
    }
  }
  return false;
};

const isCorporateAuthorizedUser = () => {
  let userRole = authService.getUserInfo().roles;
  let i = 0;
  for (i; i < userRole.length; i++) {
    if (userRole[i].value === Role.CUST_AUTHORIZED.value) {
      return true;
    }
  }
  return false;
};

const RoleService = {
  getRoles,
  isSalesAndMarketingEmployee,
  isTechnicalEmployee,
  isCorporateAuthorizedUser,
  isFinanceAndAccountingEmployee,
};

export { Role, RoleService };
