import React from "react";
import { RemoteURL } from "../../helper/RestService";

class ViewClass {
  nameOfIcx;
  numberOfEone;
}

const PopAndMedaiaGatewayDataInfo = {
  thead: (
    <thead>
      <tr>
        <th>Pop Address</th>
        <th>No. Of Media Geateway</th>
      </tr>
    </thead>
  ),

  transformRemoteDataToTableRows: function (data) {
    let rows = convertToArray(data);
    return rows;

    function convertToArray(data) {
      let rows = [];
      let i = 0;
      for (i; i < data.length; i++) {
        let row = [];
        row.push(data[i].address);
        row.push(data[i].noOfGateway);
        rows.push(row);
      }

      return rows;
    }
  },
  getRemoteUrl: function () {
    return RemoteURL.BTRC_PANEL.MONTHLY_REPORT.POPS_AND_GATEWAY_INFO;
  },
};

export default PopAndMedaiaGatewayDataInfo;
