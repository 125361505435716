import React from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import RestService, { RemoteURL } from "../../helper/RestService";
import { Routes } from "../../route/Routes";
import SingleConnectionPanel from "./SingleConnectionPanel";
class CustomerActivationPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookedNumbers: [],
      activationData: null,
      numbers: [],
      showActivationModal: false,
      showActivationResponseModal: false,
      activationResponse: false,
    };
  }

  extractActivationDataResponse(result) {
    this.setState({
      activationData: result,
    });
    this.populateNumbers();
  }

  errorFunction(error) {}

  fetchActivationData() {
    let url =
      RemoteURL.CONNECTION.ACTIVATION.GET_DATA +
      "/" +
      this.props.match.params.customerId;
    RestService.get(
      url,
      this.extractActivationDataResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  populateNumbers() {
    let bookedNumbers = [];
    let usedNumbers = [];

    for (let i = 0; i < this.state.activationData.length; i++) {
      bookedNumbers.push(this.state.activationData[i].number);
      let numberReqObj = {};
      numberReqObj.connectionId = this.state.activationData[i].connectionId;
      numberReqObj.number = this.state.activationData[i].number;
      usedNumbers.push(numberReqObj);
    }
    this.setState({
      bookedNumbers: bookedNumbers,
      numbers: usedNumbers,
    });
  }

  //Add to booked numbers
  updateUsedNumbers(connectionId, number) {
    let usedNumbers = this.state.numbers;
    for (let i = 0; i < usedNumbers.length; i++) {
      if (usedNumbers[i].connectionId === connectionId) {
        usedNumbers[i].number = number;
        break;
      }
    }
    this.setState({
      numbers: usedNumbers,
    });
  }

  extractRemoveBookedNumberResponse(result) {
    console.log(result);
  }

  removeBookedNumbers() {
    let url = RemoteURL.NUMBER_PLAN.REMOVE_BOOKED_NUMBER;
    RestService.post(
      url,
      this.state.bookedNumbers,
      this.extractRemoveBookedNumberResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  extractCustomerActivationResponse(response) {
    if (response !== null) {
      this.setState({
        activationResponse: response.isTrue,
        showActivationResponseModal: true,
      });
    }
  }

  activateCustomer() {
    this.handleActivationModalClose();
    let payload = {
      numbers: this.state.numbers,
    };
    let url =
      RemoteURL.CONNECTION.ACTIVATION.ACTIVATE +
      "/" +
      this.props.match.params.customerId;
    RestService.put(
      url,
      payload,
      this.extractCustomerActivationResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  showActivationModal() {
    this.setState({
      showActivationModal: true,
    });
  }

  handleActivationModalClose() {
    this.setState({
      showActivationModal: false,
    });
  }

  handleActivationResponseModalClose() {
    this.setState({
      showActivationResponseModal: false,
    });
  }

  showActivationResponseModal() {
    this.setState({
      showActivationResponseModal: true,
    });
  }

  activationConfirmationModal() {
    return (
      <Modal
        show={this.state.showActivationModal}
        onHide={this.handleActivationModalClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Activate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to submit?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={this.handleActivationModalClose.bind(this)}
          >
            Close
          </Button>
          <Button variant="success" onClick={this.activateCustomer.bind(this)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  activationResponseModal() {
    return (
      <Modal
        show={this.state.showActivationResponseModal}
        onHide={this.handleActivationResponseModalClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Activate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.activationResponse
            ? "Success"
            : "Error. Contact administration"}
        </Modal.Body>
        <Modal.Footer>
          <Link
            to={{
              pathname: Routes.EMPLOYEE_PORTAL.CUSTOMER_LIST,
            }}
          >
            Close
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }

  showActivationButton() {
    return (
      <div class="d-flex justify-content-center">
        <Button
          variant="primary"
          size="lg"
          onClick={this.showActivationModal.bind(this)}
        >
          Activate
        </Button>
      </div>
    );
  }

  componentDidMount() {
    this.fetchActivationData();
    window.addEventListener(
      "beforeunload",
      this.removeBookedNumbers.bind(this)
    );
  }

  componentWillUnmount() {
    this.removeBookedNumbers();
  }

  render() {
    let tabList = [];

    if (this.state.activationData !== null) {
      for (let i = 0; i < this.state.activationData.length; i++) {
        tabList.push(
          <Tab eventKey={i} title={"Connection No " + i}>
            <SingleConnectionPanel
              activationData={this.state.activationData[i]}
              updateUsedNumbers={this.updateUsedNumbers.bind(this)}
              customerId={this.props.match.params.customerId}
            />
          </Tab>
        );
      }
    }
    return (
      <div>
        <p>The uiPassword field will not reflect change in number</p>
        <br />
        {this.showActivationButton()}
        <br />
        <br />

        <Tabs defaultActiveKey={0} className="mb-3">
          {tabList}
        </Tabs>

        {this.activationConfirmationModal()}
        {this.activationResponseModal()}
      </div>
    );
  }
}

export default withRouter(CustomerActivationPanel);
