import {
  numberFilter,
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { AccountType, CustomerType } from "../domainObject/DomainDataType";

import { RemoteURL } from "../helper/RestService";

const SelectFilterOption = {
  AccountType: {
    key: "accountType",
    option: {
      0: AccountType.PRE_PAID,
      1: AccountType.POST_PAID,
    },
  },
  CustomerCategory: {
    key: "customerType",
    option: {
      0: CustomerType.INDIVIDUAL,
      1: CustomerType.CORPORATE,
    },
  }
};

const ClientReportDataInfo = {
  remoteApiUrl: RemoteURL.BTRC_PANEL.CLIENT_REPORT,
  SelectFilterOption: SelectFilterOption,
  defaultSorted: [
    {
      dataField: "customerNumber",
      order: "asc",
    },
  ],
  columns: [
    {
      dataField: "customerNumber",
      text: "User name",
      style: { "white-space": "wrap" },
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "customerOnboardDate",
      text: "Customer Onboarding Date",
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: "zone",
      text: "Zone",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "concurrentChannel",
      text: "Concurrent Channel",
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: "accountType",
      text: "Type",
      filter: selectFilter({
        options: SelectFilterOption.AccountType.option,
      }),
      sort: true,
    },
    {
      dataField: "customerType",
      text: "Customer Category",
      filter: selectFilter({
        options: SelectFilterOption.CustomerCategory.option,
      }),
      sort: true,
    },
  ],
};

export default ClientReportDataInfo;
