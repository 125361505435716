import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../css/Login.css";
import RestService, { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";
import { CommonHeader } from "../common/CommonHeader";
import { CommonFooter } from "../common/CommonFooter";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import { useHistory } from "react-router-dom";
import { OtpType } from "../domainObject/DomainDataType";
import { PasswordForm } from "../common/form/PasswordForm";

const ForgetPasswordPage = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [reTypePassword, setReTypePassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const history = useHistory();

  function extractOtpGenerationResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.OTP_REQUESTED,
        NotificationType.INFO
      );
      setOtpRequested(true);
    } else {
      NotificationService.show(
        NotificationMessage.OTP_REQUEST_FAIL,
        NotificationType.ERROR
      );
    }
  }

  function requestOtp() {
    let request = {
      type: OtpType.FORGET_PASSWORD,
      key: userName,
    };
    RestService.post(
      RemoteURL.OTP.GENERATION,
      request,
      extractOtpGenerationResponse,
      NotificationService.showRestServiceError,
      false,
      true
    );
  }

  function handleUserNameSubmit(event) {
    event.preventDefault();
    requestOtp();
  }

  function validateUserNameForm() {
    return userName.length > 0;
  }

  function getUserNameForm() {
    return (
      <Form onSubmit={handleUserNameSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            autoFocus
            type="username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </Form.Group>
        <Button
          block
          size="lg"
          type="submit"
          disabled={!validateUserNameForm()}
        >
          Request OTP
        </Button>
      </Form>
    );
  }

  function showUserNameForm() {
    if (otpRequested === false) {
      return true;
    } else {
      return false;
    }
  }

  function extractOtpVerificationResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.OTP_VERIFICATION_SUCCESS,
        NotificationType.SUCCESS
      );
      setOtpVerified(true);
    } else {
      NotificationService.show(
        NotificationMessage.OTP_VERIFICATION_FAIL,
        NotificationType.ERROR
      );
      setOtpRequested(false);
    }
  }

  function verifyOtp() {
    let request = {
      key: userName,
      type: OtpType.FORGET_PASSWORD,
      otp: otp,
    };
    RestService.post(
      RemoteURL.OTP.VERIFICATION,
      request,
      extractOtpVerificationResponse,
      NotificationService.showRestServiceError,
      false,
      true
    );
  }

  function handleOtpVerificationSubmit(event) {
    event.preventDefault();
    verifyOtp();
  }

  function validateOtpVerificationForm() {
    return otp.length > 0;
  }

  function getOtpVerificationForm() {
    return (
      <Form onSubmit={handleOtpVerificationSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>OTP</Form.Label>
          <Form.Control
            autoFocus
            type="number"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </Form.Group>
        <Button
          block
          size="lg"
          type="submit"
          disabled={!validateOtpVerificationForm()}
        >
          Verify OTP
        </Button>
        <br></br>
        <p>
          If otp is not sent within 5 minutes, request again{" "}
          <Button onClick={() => requestOtp()}>Request otp</Button>
        </p>
      </Form>
    );
  }

  function showOtpVerificationForm() {
    if (otpRequested === true && otpVerified === false) {
      return true;
    } else {
      return false;
    }
  }

  function extractPasswordChangeResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.PASSWORD_CHANGE_SUCCESSFUL,
        NotificationType.SUCCESS
      );
      history.replace(Routes.LOG_IN);
    } else {
      NotificationService.show(
        NotificationMessage.PASSWORD_CHANGE_FAILED,
        NotificationType.ERROR
      );
      setOtpRequested(false);
      setOtpVerified(false);
    }
  }

  function changePassword() {
    let request = {
      userName: userName,
      password: password,
    };
    RestService.post(
      RemoteURL.USER.FORGET_PASSWORD,
      request,
      extractPasswordChangeResponse,
      NotificationService.showRestServiceError,
      false,
      true
    );
  }

  function handlePasswordChangeSubmit(event) {
    event.preventDefault();
    changePassword();
  }

  function validatePasswordChangeForm() {
    return password.length > 0 && password === reTypePassword;
  }

  function getPasswordChangeForm() {
    return (
      <Form onSubmit={handlePasswordChangeSubmit}>
        <PasswordForm
          value={password}
          setValue={setPassword}
          label={"New password"}
        />
        <PasswordForm
          value={reTypePassword}
          setValue={setReTypePassword}
          label={"Retype password"}
        />
        <Button
          block
          size="lg"
          type="submit"
          disabled={!validatePasswordChangeForm()}
        >
          Request
        </Button>
      </Form>
    );
  }

  function showPasswordChangeForm() {
    if (otpRequested === true && otpVerified === true) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      <CommonHeader />
      <div className="Login h-100">
        {showUserNameForm() ? getUserNameForm() : null}
        {showOtpVerificationForm() ? getOtpVerificationForm() : null}
        {showPasswordChangeForm() ? getPasswordChangeForm() : null}
      </div>
      <CommonFooter />
    </div>
  );
};
export default ForgetPasswordPage;
