import {
  numberFilter,
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { RemoteURL } from "../helper/RestService";


const SelectFilterOption = {
  CallStatus: {
    key: "callStatus",
    option: {
      0: "ANSWER",
      1: "BUSY",
      2: "NOANSWER",
      3: "CANCEL",
      4: "CONGESTION",
      5: "CHANUNAVAIL",
      6: "DONTCALL",
      7: "TORTURE",
      8: "INVALIDRGS",
    },
  },
};

const ClientCdrReportDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.GET.CDR_REPORT,
  SelectFilterOption: SelectFilterOption,
  activePageReportUrl: RemoteURL.CUSTOMER.GET.CDR_REPORT_DOWNLOAD,
  activePageReprtFileName: "CDR Report.xlsx",


  defaultSorted: [
    {
      dataField: "startTime",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "startTime",
      text: "Start Time",
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: "stopTime",
      text: "End Time",
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: "callerId",
      text: "Caller Id",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number (Dest. Number)",
      style: { "white-space": "wrap" },
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "destination",
      text: "Destination",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "duration",
      text: "Duration(Second)",
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: "callStatus",
      text: "Call Status",
      filter: selectFilter({
        options: SelectFilterOption.CallStatus.option,
      }),
      sort: true,
    },
  ],

};

export default ClientCdrReportDataInfo;
