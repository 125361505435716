import React from "react";

import {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";

import { CustomerStatus, CustomerType } from "../domainObject/DomainDataType";
import { Routes } from "../route/Routes";
import { Link } from "react-router-dom";
import { RemoteURL } from "../helper/RestService";

function buttonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.EMPLOYEE_PORTAL.CUSTOMER_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>
  );
}

const SelectFilterOption = {
  CustomerType: {
    key: "customerType",
    option: {
      0: CustomerType.INDIVIDUAL,
      1: CustomerType.CORPORATE,
    },
  },
  CustomerStatus: {
    key: "status",
    option: {
      0: CustomerStatus.REGISTERED,
    },
  },
};

const CustomerVerificationReqDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.GET.SEARCH,
  SelectFilterOption: SelectFilterOption,
  withDefaultFilter: true,

  defaultSorted: [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "createdDate",
      text: "Creation time",
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: "user.userName",
      text: "User name",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "personalInfo.name",
      text: "Name",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "customerType",
      text: "Customer type",
      filter: selectFilter({
        options: SelectFilterOption.CustomerType.option,
      }),
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      filter: selectFilter({
        options: SelectFilterOption.CustomerStatus.option,
        defaultValue: CustomerStatus.REGISTERED,
        withoutEmptyOption: true
      }),
    },
    {
      dataField: "personalInfo.governmentId",
      text: "Nid/Passport id",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "companyInfo.name",
      text: "Company name",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: buttonAdd,
    },
  ],
};

export default CustomerVerificationReqDataInfo;
