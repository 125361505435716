import React from "react";
import TableWithRemoteSearchAndFilter, {
  GetFilters,
} from "./TableWithRemoteSearchAndFilter";
import { CheckEmptyObject, downloadFile, IsValidValue } from "../helper/Utility";
import RestService from "../helper/RestService";
import NotificationService from "../helper/NotificationService";
import { Button, Card } from "react-bootstrap";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RequestBody {
  pageNo;
  pageSize;
  sortField;
  sortOrder;
  filters;
}
class ReportWithSearchAndFilterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      totalSize: 0,
      sizePerPage: 10,
      filters: [],
      sortField: null,
      sortOrder: null,
      spin: false
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.defaultPage = 1;
    this.defaultPageSize = 10;
  }

  createRequestBody(pageNo, pageSize, filters, sortField, sortOrder) {
    let filterArray = GetFilters(
      filters,
      this.props.dataInfo.SelectFilterOption
    );
    let requestBody = new RequestBody();
    requestBody.pageNo = pageNo;
    requestBody.pageSize = pageSize;
    requestBody.sortField = sortField;
    requestBody.sortOrder = sortOrder;
    requestBody.filters = filterArray;
    return requestBody;
  }

  errorHandler(error) {
    NotificationService.showRestServiceError(error);
    this.setState({
      spin: false
    })
  }

  extractResponse(result) {
    let row = Object.assign(result.data);
    this.setState({
      data: row,
      totalSize: result.totalCount,
    });
    this.setState({
      spin: false
    })
  }
  fetchData(page, sizePerPage, filters, sortField, sortOrder) {
    this.setState({
      spin: true
    })
    let requestBody = this.createRequestBody(
      page,
      sizePerPage,
      filters,
      sortField,
      sortOrder
    );

    let url = this.props.dataInfo.remoteApiUrl;
    RestService.post(
      url,
      requestBody,
      this.extractResponse.bind(this),
      this.errorHandler.bind(this)
    );
    this.setState({
      page: page,
      sizePerPage: sizePerPage,
    });
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {

    if (!IsValidValue(sortField)) {
      sortField = this.props.dataInfo.defaultSorted[0].dataField;
      sortOrder = this.props.dataInfo.defaultSorted[0].order;
    }
    let skipApiCall = false;
    if (
      this.props.dataInfo.withDefaultFilter != null &&
      this.props.dataInfo.withDefaultFilter
    ) {
      if (CheckEmptyObject(filters)) {
        skipApiCall = true;
      }
    }
    if (!skipApiCall) {
      console.log(page, sizePerPage, filters, sortField, sortOrder);
      this.fetchData(page, sizePerPage, filters, sortField, sortOrder);
    }

    this.setState({
      filters: filters,
      sortField: sortField,
      sortOrder: sortOrder
    })
  };

  extractDownloadResponse(file) {
    downloadFile(this.props.dataInfo.activePageReprtFileName, file);
    this.setState({
      spin: false
    })
  }

  downloadReport() {
    this.setState({
      spin: true
    })
    let url = this.props.dataInfo.activePageReportUrl;
    var sortField = this.props.dataInfo.defaultSorted[0].dataField;
    var sortOrder = this.props.dataInfo.defaultSorted[0].order;
    var filters = [];
    if (IsValidValue(this.state.sortField)) {
      sortField = this.state.sortField;
      sortOrder = this.state.sortOrder;
    }

    if (!CheckEmptyObject(filters)) {
      filters = this.state.filters;
    }

    let requestBody = this.createRequestBody(
      0,
      0,
      filters,
      sortField,
      sortOrder
    );


    RestService.postFileDownload(
      url,
      requestBody,
      this.extractDownloadResponse.bind(this),
      this.errorHandler.bind(this)
    );
  }

  render() {
    return (
      <div>
        <Card bg="light" text="dark">

          <Card.Header>
            <div>

              <span>
                <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
              </span>
            </div>
          </Card.Header>

          <Card.Body>
            <div>
              {this.state.errorAlert}
              <TableWithRemoteSearchAndFilter
                data={this.state.data}
                page={this.state.page}
                sizePerPage={this.state.sizePerPage}
                totalSize={this.state.totalSize}
                onTableChange={this.handleTableChange}
                keyField="id"
                columns={this.props.dataInfo.columns}
                defaultSorted={this.props.dataInfo.defaultSorted}
                selectRow={this.props.dataInfo.selectRow}
                singleSelectFuntion={this.props.dataInfo.singleSelectFuntion}
                allSelectFuntion={this.props.dataInfo.allSelectFuntion}
              />
            </div>
          </Card.Body>
          <Card.Footer>
            <div><span>
              <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
            </span>
              &nbsp;
              {this.props.dataInfo.activePageReportUrl != null && this.props.dataInfo.activePageReportUrl != "" ?
                <Button variant="primary" onClick={this.downloadReport}> Download</Button>
                : ""}
            </div>
          </Card.Footer>

        </Card>
      </div>
    );
  }
}
export default ReportWithSearchAndFilterTable;
