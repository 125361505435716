import React from "react";

import {
  textFilter,
  dateFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import { InvoiceType, PaymentStatus } from "../domainObject/DomainDataType";
import { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";
import { getMonthFromNumber, getMonthNumberFilter, getYearFormatter, getYearNumberFilter } from "../helper/Utility";

function buttonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.CUSTOMER.PORTAL.INVOICE_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>
  );
}

export const InvoiceTypeSelectFilterOption = {
  InvoiceType: {
    key: "type",
    option: {
      0: InvoiceType.SET_UP_CHARGE,
      1: InvoiceType.SECURITY_DEPOSIT,
      2: InvoiceType.MONTHLY_BILL,
      3: InvoiceType.REFILL,
      4: InvoiceType.CANCEL,
    },
  },
  PaymentStatus: {
    key: "paymentStatus",
    option: {
      0: PaymentStatus.PAID,
      1: PaymentStatus.PARTIAL_PAID,
      2: PaymentStatus.UNPAID,
    },
  },
  Month: {
    key: "billingMonth",
    option: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      11: 11,
      12: 12
    }
  }
};

export const CustomerInvoiceListDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.GET.INVOICE_SEARCH,
  SelectFilterOption: InvoiceTypeSelectFilterOption,

  defaultSorted: [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "createdDate",
      text: "Creation time",
      filter: dateFilter(),
      sort: true,
    },

    {
      dataField: "reference",
      text: "Reference",
      filter: textFilter(),
    },
    {
      dataField: "type",
      text: "Invoice type",
      filter: selectFilter({
        options: InvoiceTypeSelectFilterOption.InvoiceType.option,
      }),
    },
    {
      dataField: "billingYear",
      text: "Year",
      filter: getYearNumberFilter(),
      sort: true,
      formatter: getYearFormatter
    },
    {
      dataField: "billingMonth",
      text: "Month",
      filter: getMonthNumberFilter(),
      sort: true,
      formatter: getMonthFromNumber
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      filter: selectFilter({
        options: InvoiceTypeSelectFilterOption.PaymentStatus.option,
      }),
      sort: true,
    },
    {
      dataField: "amount",
      text: "Invoice amount",
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: "remainingAmount",
      text: "Unpaid amount",
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: buttonAdd,
    },
  ],
};

export default CustomerInvoiceListDataInfo;
