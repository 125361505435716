
import React from "react";
import { withRouter } from "react-router";
import RestService, { RemoteURL } from "../helper/RestService";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import { Routes } from "../route/Routes";
import { CONNECTION_PANEL_DEFAULT_SORTED, CONNECTION_PANEL_SELECT_FILTER, numberNotAssignedFormatter } from "../customerPortal/CustomerConnectionPanel";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import { Button, Card, Form, Modal } from "react-bootstrap";
import NotificationService, { NotificationType } from "../helper/NotificationService";
import CustomModal, { ModalType } from "../common/modal/CustomModal";
import { CONNECTION_TABLE_DEFAULT_SORTED, CONNECTION_TABLE_FILTER_OPTION, employeeConnectionDetailButtonAdd } from "./EmployeeConnectionListPanel";

class EmployeePrepaidConnectionListPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkRefillList: []
    }
  }

  ////////////////////////////////////////////////////////

  handleRefillFormClose() {
    this.setState({
      showRefillForm: false,
      refillAmount: null,
      selectedConnection: null,
    });
  }


  handleBulkRefillFormClose() {
    this.setState({
      showBulkRefillForm: false,
    });
  }


  extractRefillInvoiceResponse(response) {
    this.props.history.push(
      Routes.EMPLOYEE_PORTAL.INVOICE_DETAIL + "/" + response.id
    );
  }

  generateRefillInvoice() {
    let req = {};
    req.connectionId = this.state.selectedConnection.id;
    req.amount = this.state.refillAmount;
    let reqArray = [];
    reqArray.push(req);
    RestService.post(
      RemoteURL.INVOICE.CREATE.REFILL,
      reqArray,
      this.extractRefillInvoiceResponse.bind(this),
      NotificationService.showRestServiceError
    );
  }

  refillFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true,
    });

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.generateRefillInvoice();
    }
  }


  generateBulkRefillInvoice() {
    let reqArray = [];

    for (let i = 0; i < this.state.bulkRefillList.length; i++) {
      let req = {};
      req.connectionId = this.state.bulkRefillList[i].id;
      if (this.state.bulkRefillIndividual) {
        req.amount = this.state.bulkRefillList[i].refillAmount;
      } else {
        req.amount = this.state.bulkRefillAmount;
      }
      reqArray.push(req);
    }

    RestService.post(
      RemoteURL.INVOICE.CREATE.REFILL,
      reqArray,
      this.extractRefillInvoiceResponse.bind(this),
      NotificationService.showRestServiceError
    );
  }

  bulkRefillFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true,
    });

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.generateBulkRefillInvoice();
    }
  }


  refillModal() {
    return (
      <Modal
        show={this.state.showRefillForm}
        onHide={this.handleRefillFormClose.bind(this)}
        onSubmit={this.refillFormSubmit.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Refill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.refillFormSubmit.bind(this)}
          >
            <Form.Group controlId="formGridAddress2">
              <Form.Label>Number</Form.Label>
              <Form.Control
                type="text"
                placeholder={this.state.selectedConnection != null ?
                  this.state.selectedConnection.iptspNumber :
                  ''}
                disabled
              />
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={this.state.refillAmount}
                onChange={(e) => {
                  this.setState({
                    refillAmount: e.target.value,
                  });
                }}
                placeholder="amount"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please input amount
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button type="submit" className="btn btn-info mr-auto">
                Refill
              </Button>
              <Button
                className="btn btn-info"
                variant="danger"
                onClick={this.handleRefillFormClose.bind(this)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  showRefillModal(connection) {
    this.setState({
      showRefillForm: true,
      selectedConnection: connection,
    });
  }

  bulkRefillModal() {
    const formElement = [];
    if (this.state.bulkRefillIndividual) {
      for (let i = 0; i < this.state.bulkRefillList.length; i++) {
        formElement.push(
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Number</Form.Label>
            <Form.Control
              type="text"
              placeholder={this.state.bulkRefillList[i].iptspNumber}
              disabled
            />
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={this.state.bulkRefillList[i].refillAmount}
              onChange={(e) => {
                let index = i;
                let connectionList = this.state.bulkRefillList;
                let connection = connectionList[index];
                connection["refillAmount"] = e.target.value;
                this.setState({
                  bulkRefillList: connectionList,
                });
              }}
              placeholder="amount"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input amount
            </Form.Control.Feedback>
          </Form.Group>
        )
      }
    } else {
      for (var i = 0; i < this.state.bulkRefillList.length; i++) {
        formElement.push(
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Number</Form.Label>
            <Form.Control
              type="text"
              placeholder={this.state.bulkRefillList[i].iptspNumber}
              disabled
            />
          </Form.Group>
        )
      }
      formElement.push(
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Bulk Amount</Form.Label>
          <Form.Control
            type="number"
            min="1"
            value={this.state.bulkRefillAmount}
            onChange={(e) => {
              this.setState({
                bulkRefillAmount: e.target.value,
              });
            }}
            placeholder="amount"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please input amount
          </Form.Control.Feedback>
        </Form.Group>
      )
    }

    return (
      <Modal
        show={this.state.showBulkRefillForm}
        onHide={this.handleBulkRefillFormClose.bind(this)}
        onSubmit={this.bulkRefillFormSubmit.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Refill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.bulkRefillFormSubmit.bind(this)}
          >
            <Form.Check
              type="checkbox"
              label="Different Amount"
              checked={this.state.bulkRefillIndividual}
              onChange={() => {
                this.setState({
                  bulkRefillIndividual: !this.state.bulkRefillIndividual
                })
              }}
            />
            {formElement}
            <Modal.Footer>
              <Button type="submit" className="btn btn-info mr-auto">
                Refill
              </Button>
              <Button
                className="btn btn-info"
                variant="danger"
                onClick={this.handleBulkRefillFormClose.bind(this)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal >
    );
  }

  showBulkRefillModal(connection) {
    this.setState({
      showBulkRefillForm: true
    });
  }

  refillButton(cell, row) {
    return (
      <Button onClick={this.showRefillModal.bind(this, row)}>Refill</Button>
    );
  }

  bulkSelectSignleRowSelection(connection, isSelect) {
    if (this.state.bulkRefillList.length > 0) {
      if (this.state.bulkRefillList[0].customer.id != connection.customer.id) {
        NotificationService.show("Connection doesn't belong to same customer", NotificationType.ERROR);
        return false;
      }
    }
    let existingConnectionList = this.state.bulkRefillList;
    existingConnectionList = existingConnectionList.filter(function (existingConnection) {
      return existingConnection.id !== connection.id;
    })
    if (isSelect) {
      existingConnectionList.push(connection);
    }

    this.setState({
      bulkRefillList: existingConnectionList
    })
  }

  bulkSelectAllRowSelection(connectionList, isSelect) {
    if (connectionList.length > 1) {
      for (let i = 1; i < connectionList.length; i++) {
        if (connectionList[0].customer.id != connectionList[i].customer.id) {
          NotificationService.show("Connection doesn't belong to same customer", NotificationType.ERROR);
          return [];
        }
      }
    }
    if (isSelect) {
      this.setState({
        bulkRefillList: connectionList
      })

    } else {
      this.setState({
        bulkRefillList: []
      })
    }
  }
  //////////////////////////////////////////////////////////
  
  cancelRefillInvoice() {
    RestService.post(
      RemoteURL.INVOICE.CANCEL.REFILL,
      {},
      this.extractCancelRefillInvoiceResult.bind(this),
      NotificationService.showRestServiceError
    );
  }

 

  extractCancelRefillInvoiceResponse(result) {
    NotificationService.show("Refill invoices cancel successful", NotificationType.SUCCESS);
  }


  EMPLOYEE_CONNECTION_LIST_DATA_INFO = {
    remoteApiUrl: RemoteURL.CONNECTION.PREPAID,
    SelectFilterOption: CONNECTION_TABLE_FILTER_OPTION,
    defaultSorted: CONNECTION_TABLE_DEFAULT_SORTED,
    selectRow: true,
    singleSelectFuntion: this.bulkSelectSignleRowSelection.bind(this),
    allSelectFuntion: this.bulkSelectAllRowSelection.bind(this),
    columns: [
      {
        dataField: "createdDate",
        text: "Creation time",
        filter: dateFilter(),
        sort: true,
      },
      {
        dataField: "customer.user.userName",
        text: "Customer id",
        sort: true,
        formatter: numberNotAssignedFormatter,
        filter: textFilter(),
      },
      {
        dataField: "iptspNumber",
        text: "IPTSP number",
        sort: true,
        formatter: numberNotAssignedFormatter,
        filter: textFilter(),
      },
      {
        dataField: "status",
        text: "Status",
      },
      {
        dataField: "",
        text: "Refill",
        isDummyField: true,
        formatter: this.refillButton.bind(this),
      },
      {
        dataField: "",
        text: "Detail",
        isDummyField: true,
        formatter: employeeConnectionDetailButtonAdd
      },
    ],
  }


  render() {
    return (
        <div>
          <Card>
            <Card.Header>
              {this.state.bulkRefillList.length != 0 ?
                <Button onClick={this.showBulkRefillModal.bind(this)}>Bulk Refill</Button>
                : null}
            </Card.Header>
            <Card.Body>
              <ReportWithSearchAndFilterTable
                dataInfo={this.EMPLOYEE_CONNECTION_LIST_DATA_INFO}
              />
            </Card.Body>
            <Card.Footer>
            <CustomModal
            buttonText={'Cancell All Refill invoices'}
            message={<h1 style={{ color: 'red' }}>This button will cancel all unpaid refill invoices before yesterday</h1>}
            successButtonText={"Cancel All"}
            modalType={ModalType.CONFIRMATION_MODAL}
            confiramtionFunction={this.cancelRefillInvoice.bind(this)}
            
          />
            </Card.Footer>
          </Card>
          {this.refillModal()}
          {this.bulkRefillModal()}
        
        </div>
    );
  }
}

export default withRouter(EmployeePrepaidConnectionListPanel);
