import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import { AccountType, ConnectionStatus } from "../domainObject/DomainDataType";
import NotificationService, { NotificationType } from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { formatAddress, formatIptspNumber } from "../helper/Utility";
import authService from "../login/LoginService";
import { RoleService } from "../domainObject/Role";
import CustomModal, { ModalType } from "./modal/CustomModal";
class ConnectionDetailPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionInfo: null,
      invoiceList: [],
      credit: 0,
      spin: false,
      loadingModalShow:false
    };
  }

  isPrepaid() {
    if (
      this.state.connectionInfo.productInfo.accountType === AccountType.PRE_PAID
    ) {
      return true;
    } else {
      return false;
    }
  }

  isNumberExist() {
    if (this.state.connectionInfo.iptspNumber !== null) {
      return true;
    } else {
      return false;
    }
  }

  handleCreditFetchError(error) {
    this.setState({
      spin: false,
    });
    NotificationService.showRestServiceError(error);
  }

  extractGetCreditResponse(result) {
    this.setState({
      spin: false,
    });
    if (result != null && result.data != null && result.data.length > 0) {
      let resultNumber = result.data[0].number;
      if (resultNumber === this.state.connectionInfo.iptspNumber) {
        this.setState({
          credit: result.data[0].credit,
        });
      }
    }
  }

  fetchCredit(iptspNumber) {
    if (iptspNumber != null) {
      this.setState({
        spin: true,
      });
      let request = [];
      request.push(iptspNumber);
      RestService.get(
        RemoteURL.CONNECTION.GET_CREDIT + "?iptspNumbers=" + request.join(","),
        this.extractGetCreditResponse.bind(this),
        this.handleCreditFetchError
      );
    }
  }

  discard() {
    this.setState({
      loadingModalShow:true
    });
    let url = RemoteURL.CONNECTION.DISCARD + "/" + this.state.connectionInfo.id;
    RestService.put(
      url, {},
      this.extractDiscardResponse.bind(this),
      this.errorResponse.bind(this)
    )
  }
  
  delete() {
    this.setState({
      loadingModalShow:true
    })
    let url = RemoteURL.CONNECTION.DELETE + "/" + this.state.connectionInfo.id;
    RestService.put(
      url, {},
      this.extractDiscardResponse.bind(this),
      this.errorResponse.bind(this)
    )
  }

  extractDiscardResponse(result) {
    this.setState({
      loadingModalShow:false
    })
    NotificationService.show("Operation completed successfully", NotificationType.SUCCESS);
    window.location.reload();
  }

  extractConnectionInfoResponse(result) {
    this.setState({
      connectionInfo: result,
    });
    this.fetchCredit(result.iptspNumber);
  }

  errorResponse(error) {
    this.setState({
      loadingModalShow:false
    })
    NotificationService.showRestServiceError(error);
  }

  fetchConnectionInfo() {
    let fullUrl =
      RemoteURL.CONNECTION.DETAIL + "/" + this.props.match.params.connectionId;
    RestService.get(
      fullUrl,
      this.extractConnectionInfoResponse.bind(this),
      this.errorResponse.bind(this)
    );
  }

  componentDidMount() {
    this.fetchConnectionInfo();
  }

  getDeleteMessage() {
    return (
      <div>
        <h3>Do you want to delete this connection?</h3>
        <p>User name: <b>{this.state.connectionInfo.customer.user.userName}</b></p>
        <p>Iptsp Number: <b>{this.state.connectionInfo.iptspNumber}</b></p>
        <br></br>
        <h3> Data from the following tables will be deleted:</h3>
        <ul>
          <li> <b>cc_card</b></li>
          <li> <b>cc_callerid</b></li>
          <li> <b>cc_sip_buddies</b></li>
          <li> <b>cc_did</b></li>
          <li> <b>cc_logrefill</b></li>
          <li> <b>cc_did_use</b></li>
          <li> <b>cc_did_destination</b></li>
        </ul>
        <h3>Customer <b>will see </b> this connection as deleted in their panel !!!</h3>
      </div>
    )
  }

  getDiscardMessage() {
    return (
      <div>
        <h3>Do you want to delete this connection?</h3>
        <p>User name: <b>{this.state.connectionInfo.customer.user.userName}</b></p>
        <p>Iptsp Number: <b>{this.state.connectionInfo.iptspNumber}</b></p>
        <br></br>
        <h3> Data from the following tables will be deleted:</h3>
        <ul>
          <li> <b>cc_card</b></li>
          <li> <b>cc_callerid</b></li>
          <li> <b>cc_sip_buddies</b></li>
          <li> <b>cc_did</b></li>
          <li> <b>cc_logrefill</b></li>
          <li> <b>cc_did_use</b></li>
          <li> <b>cc_did_destination</b></li>
        </ul>
        <h3>Customer <b> will not see </b> this connection as deleted in their panel !!!</h3>
      </div>
    )
  }

  validForDiscard() {
    return RoleService.isTechnicalEmployee()
      && this.state.connectionInfo.status != ConnectionStatus.DISCARDED
      && this.state.connectionInfo.status != ConnectionStatus.DELETED
      && this.state.connectionInfo.status != ConnectionStatus.SUSPENDED;
  }

  validForDelete() {
    return RoleService.isTechnicalEmployee()
      && this.state.connectionInfo.status != ConnectionStatus.DISCARDED
      && this.state.connectionInfo.status != ConnectionStatus.DELETED;
  }

  showGeneralInfo() {
    return (
      <div>
        {this.state.connectionInfo != null ? (
          <Card>
            <Card.Header>
              Id: <strong>{this.state.connectionInfo.reference}</strong>
            </Card.Header>
            {this.state.connectionInfo.iptspNumber != null ? (
              <Card.Header>
                {this.isPrepaid() ? "Balance: " : "Usages:"}
                <strong>{this.state.credit} </strong>{" "}
                <span
                  class="clickableSpan"
                  onClick={this.fetchCredit.bind(
                    this,
                    this.state.connectionInfo.iptspNumber
                  )}
                >
                  <span class="tooltiptext">Click to refresh</span>
                  <FontAwesomeIcon
                    icon={faSync}
                    spin={this.state.spin}
                    size="2x"
                  />{" "}
                  {!this.isPrepaid()
                    ? "Credit limit: " +
                    this.state.connectionInfo.postPayCreditLimit
                    : null}
                </span>
              </Card.Header>
            ) : null}
            <Card.Body>
              {this.isNumberExist() ? (
                <Card.Text>
                  IPTSP number:{" "}
                  <strong>
                    {formatIptspNumber(this.state.connectionInfo.iptspNumber)}
                  </strong>
                </Card.Text>
              ) : null}
              <Card.Text>
                Status: <strong>{this.state.connectionInfo.status}</strong>
              </Card.Text>
              <Card.Text>
                Inst. Address:{" "}
                <strong>
                  {formatAddress(this.state.connectionInfo.installationAddress)}
                </strong>
              </Card.Text>
              {!this.isPrepaid() ? (
                <Card.Text>
                  Billing Address:{" "}
                  <strong>
                    {formatAddress(
                      this.state.connectionInfo.postPayBillingAddress
                    )}
                  </strong>
                </Card.Text>
              ) : null}
            </Card.Body>
            {this.validForDiscard() ?
              <Card.Footer>
                <CustomModal
                  buttonText={'Discard'}
                  message={this.getDiscardMessage()}
                  successButtonText={"Discard"}
                  modalType={ModalType.CONFIRMATION_MODAL}
                  confiramtionFunction={this.discard.bind(this)}

                />
              </Card.Footer>
              : null}
            {this.validForDelete() ?
              <Card.Footer>
                <CustomModal
                  buttonText={'Delete'}
                  message={this.getDeleteMessage()}
                  successButtonText={"Delete"}
                  modalType={ModalType.CONFIRMATION_MODAL}
                  confiramtionFunction={this.delete.bind(this)}

                />
              </Card.Footer>
              : null}
          </Card>
        ) : null}
         <CustomModal
        openControl={this.state.loadingModalShow}
        modalType={ModalType.LOADING_MODAL}
      />
      </div>
    );
  }

  render() {
    return (
      <div>
        <Row xs={1} md={1} className="g-4">
          <Col>{this.showGeneralInfo()}</Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(ConnectionDetailPanel);
