import authService from "../login/LoginService";
import NotificationService from "./NotificationService";

const checkTokenExpiration = (functionToCall) => {
  if (authService.hasAuthTokenExpired()) {
    authService.refreshAuthToken(functionToCall);
    return true;
  } else {
    return false;
  }
};

const post = (
  url,
  requestBody,
  extractFunction,
  errorFunction,
  isSecure = true,
  addBaseUrl = true
) => {
  if (isSecure) {
    if (
      checkTokenExpiration(function () {
        post(
          url,
          requestBody,
          extractFunction,
          errorFunction,
          isSecure,
          addBaseUrl
        );
      })
    ) {
      return;
    }
  }
  const requestOptions = createJsonRequestOptionWithBody(
    "POST",
    requestBody,
    isSecure
  );
  let fullUrl = appendBaseUrl(url, addBaseUrl);
  restCall(fullUrl, requestOptions, extractFunction, errorFunction);
};

const postFileDownload = (
  url,
  requestBody,
  extractFunction,
  errorFunction,
  isSecure = true,
  addBaseUrl = true
) => {
  if (isSecure) {
    if (
      checkTokenExpiration(function () {
        postFileDownload(
          url,
          requestBody,
          extractFunction,
          errorFunction,
          isSecure,
          addBaseUrl
        );
      })
    ) {
      return;
    }
  }
  const requestOptions = createJsonRequestOptionWithBody(
    "POST",
    requestBody,
    isSecure
  );
  let fullUrl = appendBaseUrl(url, addBaseUrl);
  restDownload(fullUrl, requestOptions, extractFunction, errorFunction);
};

const put = (
  url,
  requestBody,
  extractFunction,
  errorFunction,
  isSecure = true,
  addBaseUrl = true
) => {
  if (isSecure) {
    if (
      checkTokenExpiration(function () {
        put(
          url,
          requestBody,
          extractFunction,
          errorFunction,
          isSecure,
          addBaseUrl
        );
      })
    ) {
      return;
    }
  }
  const requestOptions = createJsonRequestOptionWithBody(
    "PUT",
    requestBody,
    isSecure
  );
  let fullUrl = appendBaseUrl(url, addBaseUrl);
  restCall(fullUrl, requestOptions, extractFunction, errorFunction);
};

const get = (
  url,
  extractFunction,
  errorFunction,
  isSecure = true,
  addBaseUrl = true
) => {
  if (isSecure) {
    if (
      checkTokenExpiration(function () {
        get(url, extractFunction, errorFunction, isSecure, addBaseUrl);
      })
    ) {
      return;
    }
  }
  const requestOptions = createJsonRequestOptionWithOutBody("GET", isSecure);
  let fullUrl = appendBaseUrl(url, addBaseUrl);
  restCall(fullUrl, requestOptions, extractFunction, errorFunction);
};

const getDownload = (
  url,
  extractFunction,
  errorFunction,
  isSecure = true,
  addBaseUrl = true
) => {
  if (isSecure) {
    if (
      checkTokenExpiration(function () {
        getDownload(url, extractFunction, errorFunction, isSecure, addBaseUrl);
      })
    ) {
      return;
    }
  }
  const requestOptions = createJsonRequestOptionWithOutBody("GET", isSecure);
  let fullUrl = appendBaseUrl(url, addBaseUrl);
  restDownload(fullUrl, requestOptions, extractFunction, errorFunction);
};

const postFileUpload = (
  url,
  requestBody,
  extractFunction,
  errorFunction,
  isSecure = true,
  addBaseUrl = true
) => {
  if (isSecure) {
    if (
      checkTokenExpiration(function () {
        postFileUpload(
          url,
          requestBody,
          extractFunction,
          errorFunction,
          isSecure,
          addBaseUrl
        );
      })
    ) {
      return;
    }
  }
  let fullUrl = appendBaseUrl(url, addBaseUrl);
  let requestOptions = createFileUploadRequestOptionWithBody(
    "POST",
    requestBody,
    isSecure
  );
  restCall(fullUrl, requestOptions, extractFunction, errorFunction);
};

const createFileUploadRequestOptionWithBody = (
  method,
  requestBody,
  isSecure
) => {
  if (isSecure) {
    let token = authService.getToken();
    return {
      method: method,
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
      body: requestBody,
    };
  } else {
    return {
      method: method,
      body: requestBody,
    };
  }
};

const createJsonRequestOptionWithBody = (method, requestBody, isSecure) => {
  if (isSecure) {
    let token = authService.getToken();
    return {
      method: method,
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify(requestBody),
    };
  } else {
    return {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify(requestBody),
    };
  }
};

const createJsonRequestOptionWithOutBody = (method, isSecure) => {
  if (isSecure) {
    let token = authService.getToken();
    return {
      method: method,
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
  } else {
    return {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
  }
};

const appendBaseUrl = (url, addBaseUrl) => {
  if (addBaseUrl) {
    return getApiBaseUrl() + url;
  } else {
    return url;
  }
};

const restCall = (fullUrl, requestOptions, extractFunction, errorFunction) => {
  fetch(fullUrl, requestOptions)
    .then((res) => res.json())
    .then(
      (result) => {
        if (result.code == null) {
          extractFunction(result);
        } else {
          if (errorFunction != null) {
            errorFunction(result);
          } else {
            NotificationService.showRestServiceError(result);
          }
        }
      },
      (error) => {
        if (errorFunction != null) {
          errorFunction(error);
        } else {
          NotificationService.showRestServiceError(error);
        }
      }
    );
};

const restDownload = (fullUrl, requestOptions, extractFunction, errorFunction) => {
  fetch(fullUrl, requestOptions)
    .then((res) => res.blob())
    .then(
      (result) => {
        if (result.code == null) {

          extractFunction(result);
        } else {
          if (errorFunction != null) {
            errorFunction(result);
          } else {
            NotificationService.showRestServiceError(result);
          }
        }
      },
      (error) => {
        if (errorFunction != null) {
          errorFunction(error);
        } else {
          NotificationService.showRestServiceError(error);
        }
      }
    );
};

const getApiBaseUrl = function () {
  return process.env.REACT_APP_API_BASE_URL;
};

export const RemoteURL = {
  IPTSP_SERVER_BASE_URL: getApiBaseUrl(),
  OTP: {
    GENERATION: "/ns/otp",
    VERIFICATION: "/ns/otp/verify",
  },
  USER: {
    LOG_IN: "/ns/login",
    REFRESH_TOKEN: "/ns/refreshToken",
    LOG_OUT: "/logout",
    INFO: "/users/info",
    CHANGE_PASSWORD: "/users/password",
    FORGET_PASSWORD: "/ns/forgetPassword",
  },
  BTRC_PANEL: {
    MONTHLY_REPORT: {
      ACTIVE_SUBSCRIBER_INFO: "/btrc/monthly_report/active_subscriber",
      PROVISIONAL_SUBSCRIBER_INFO:
        "/btrc/monthly_report/provisioned_subscriber",
      ICX_CONNECTIVITY_CAPACITY: "/btrc/monthly_report/icx_capacity_list",
      ICX_IPTSP_CALL_INFO: "/btrc/monthly_report/icx_iptsp_call_log",
      IPTSP_IPTSP_CALL_INFO: "/btrc/monthly_report/iptsp_to_iptsp_call_log",
      POPS_AND_GATEWAY_INFO: "/btrc/monthly_report/pop_gateway_list",
    },
    CLIENT_REPORT: "/btrc/client_report",
    ACTIVE_CALLS: "/btrc/active_calls",
    ACTIVE_REGISTRATIONS: "/btrc/registered_account",
    CDR_REPORT: "/btrc/cdr_report",
    UTILIZATION_DATE_RANGE: '/btrc/utilization',
    UTILIZATION_MONTHLY: '/btrc/utilization/monthly',
  },
  TERMS_AND_CONDITION: {
    GET: {
      CUSTOMER_REGISTRATION: "/ns/termAndConditions/customerRegistration",
    },
  },
  COMPANY: {
    QUERY: {
      TIN_EXIST: "/ns/customer/companyExist",
    },
  },
  CUSTOMER: {
    VERIFICATION: {
      ATTACHMENT: "/customer/verification/attachment",
      COMPLETE: "/customer/verification/complete",
    },
    GET: {
      Detail: "/customer",
      SEARCH: "/customer/search",
      INVOICE_SEARCH: "/customer/invoices/search",
      ACTIVE_CUSTOMER_BY_CONNECTION_STATUS:
        "/customer/active/byConnectionStatus",
      CDR_REPORT: "/customer/cdrReport",
      CALL_SUMMARY_REPORT: "/customer/callSummary",
      CDR_REPORT_DOWNLOAD: "/customer/cdrReport/download",
      SUPPORT_SEARCH: "/customer/supports/search",
      ALL_INVOICE: "/customer/invoices",
    },
    REGISTRATION: {
      REGISTER: "/ns/customer/register",
      COMPANY: "/ns/customer/register/company",
      PERSONAL_INFORMATION: "/ns/customer/register/personalInfo",
      STATUS: "/ns/customer/registration/status",
      UPLOAD_PERSONAL_ATTACHMENT: "/ns/customer/register/personal/attachment",
      UPLOAD_COMPANY_ATTACHMENT: "/ns/customer/register/company/attachment",
      CHECK_USER: "/ns/customer/exist"
    },
    ADDRESS: {
      CREATE: "/address",
    },
    SUPPORT: {
      CREATE: "/support/customer",
      DETAIL: "/support/customer",
      SEARCH: "/support/customer/search",
      CLOSE: "/support/customer/close",
    },
    QUERY: {
      CONTACT_NO_EXIST: "/ns/customer/contactNoExist",
      EMAIL_ID_EXIST: "/ns/customer/emailIdExist",
      GOVERNMENT_ID_EXIST: "/ns/customer/governmentIdExist",
    },
  },
  CONNECTION: {
    ALL: "/connection/search",
    CREATE: "/connection",
    ACTIVATION: {
      GET_DATA: "/connection/activation/data",
      ACTIVATE: "/connection/activation/activate",
    },
    DISCARDED_LIST: "/connection/discarded/list",
    PREPAID: "/connection/prepaid/list",
    DISCARD:"/connection/discard",
    DELETE:"/connection/delete",
    CHECK_NUMBER_TO_ASSIGN: "/connection/number/availability",
    CHECK_NUMBER_EXISTENCE_STATUS: "/connection/number/existence/status",
    DETAIL: "/connection",
    GET_CREDIT: "/connection/number/credit",
    INVOICES: "/connection/invoices",
  },
  LOCATION: {
    GET: {
      ALL_DIVISION: "/ns/location/divisions",
      ALL_DISTRICT_BY_DIVISION: "/ns/location/districtsByDivision",
      ALL_UPAZILA_BY_DIVISION: "/ns/location/upazilasByDistrict",
    },
  },
  PRODUCT: {
    GET: {
      BY_ID: "/ns/product",
      ALL: "/ns/product/all",
      BY_CUSTOMER: "/ns/product/byCustomer",
    },
  },
  NUMBER_PLAN: {
    REMOVE_BOOKED_NUMBER: "/numberPlan/remove/bookedNumbers",
    CHECK_NUMBER_STATUS: "",
  },
  INVOICE: {
    SEARCH: "/invoice/search",
    DETAIL: "/invoice",
    MONTHLY_BILL_LIST: "/invoice/monthly-bill-list",
    CREATE: {
      REFILL: "/invoice/generate/refill",
      ALL_MONTHLY_BILL: "/invoice/generate/monthlyBill/all",
      SINGLE_MONTHLY_BILL:"/invoice/generate/monthlyBill"
    },
    CANCEL:{
      REFILL: "/cancel/refill/all",
    }
  },
  TRANSACTION: {
    CASH: "/transaction/cash",
    CHEQUE: "/transaction/cheque",
    MFS: "/transaction/mfs",
  },
  PAYMENT: {
    INVOICE: "/payment/invoice",
    BKASH: {
      CREATE_PAYMENT: "/payment/bkash/createPayment",
      EXECUTE_PAYMENT: "/payment/bkash/executePayment",
      CANCEL_PAYMENT: "/payment/bkash/cancelPayment",
    },
  },
  FILE: {
    GET: "/ns/files",
  },
  BANK_INFO: {
    GET: {
      ACTIVE: "/ns/bankInfo/active",
    },
  },
};

const RestService = {
  post,
  put,
  get,
  getDownload,
  postFileUpload,
  postFileDownload,
  getApiBaseUrl,
  appendBaseUrl
};

export default RestService;
