import React from "react";
import {
  CustomerType,
  AccountType,
  ZoneKeyToView,
} from "../../domainObject/DomainDataType";
import { RemoteURL } from "../../helper/RestService";
import {
  CheckValidityWithZeroDefault,
  IsValidValue,
} from "../../helper/Utility";

class ViewData {
  prepaidIndividualCount;
  prepaidCorporateCount;
  prepaidCorporateSipCount;
  postpaidIndividualCount;
  postpaidCorporeateCount;
  postpaidCorporateSipCount;
}

const SubscriberDataInfo = {
  thead: (
    <thead>
      <tr>
        <th colspan="7" scope="colgroup">
          No. of Subscriber (Reporting Month RGB-Revenue Generating Base)
        </th>
      </tr>
      <tr>
        <th rowspan="1"></th>
        <th colspan="3" scope="colgroup">
          Prepaid
        </th>
        <th colspan="3" scope="colgroup">
          Postpaid
        </th>
      </tr>
      <tr>
        <th>Zone</th>
        <th>
          Individual <br /> (No. of 09648-XXXXXX)
        </th>
        <th>
          Corporate <br /> (No. of 09648-XXXXXX)
        </th>
        <th>
          Corporate <br /> (No of Concurrent <br /> Channel/SIP port)
        </th>
        <th>
          Individual <br /> (No. of 09648-XXXXXX)
        </th>
        <th>
          Corporate <br /> (No. of 09648-XXXXXX)
        </th>
        <th>
          Corporate <br /> (No of Concurrent <br /> Channel/SIP port)
        </th>
      </tr>
    </thead>
  ),
  transformRemoteDataToTableRows: function (data) {
    let zoneToDataMap = new Map();
    populateZoneToDataMapWithDefault(zoneToDataMap);
    for (let i = 0; i < data.length; i++) {
      if (IsValidValue(data[i].zone) && data[i].zone != "") {
        let zone = getZoneKey(data[i].zone);
        if (zoneToDataMap.has(zone)) {
          let viewData = zoneToDataMap.get(zone);
          assignData(viewData, data[i]);
        }
      }
    }

    let rows = convertToArray(zoneToDataMap);

    function getZoneKey(zone) {
      if (zone.toLowerCase().includes("central")) {
        return ZoneKeyToView.CENTRAL;
      }
      if (
        zone.toLowerCase().includes("north") &&
        zone.toLowerCase().includes("west")
      ) {
        return ZoneKeyToView.NORTH_WEST;
      }
      if (
        zone.toLowerCase().includes("north") &&
        zone.toLowerCase().includes("east")
      ) {
        return ZoneKeyToView.NORTH_EAST;
      }

      if (
        zone.toLowerCase().includes("south") &&
        zone.toLowerCase().includes("west")
      ) {
        return ZoneKeyToView.SOUTH_WEST;
      }
      if (
        zone.toLowerCase().includes("south") &&
        zone.toLowerCase().includes("east")
      ) {
        return ZoneKeyToView.SOUTH_EAST;
      }
    }

    function populateZoneToDataMapWithDefault(zoneToDataMap) {
      Object.keys(ZoneKeyToView).forEach(function (key, idx) {
        let dummyData = new ViewData();
        dummyData.prepaidIndividualCount = 0;
        dummyData.prepaidCorporateCount = 0;
        dummyData.prepaidCorporateSipCount = 0;
        dummyData.postpaidIndividualCount = 0;
        dummyData.postpaidCorporeateCount = 0;
        dummyData.postpaidCorporateSipCount = 0;
        zoneToDataMap.set(ZoneKeyToView[key], dummyData);
      });
    }

    function assignData(viewData, data) {
      if (
        data.payType === AccountType.PRE_PAID &&
        data.clientType === CustomerType.INDIVIDUAL
      ) {
        viewData.prepaidIndividualCount = data.count;
      } else if (
        data.payType === AccountType.PRE_PAID &&
        data.clientType === CustomerType.CORPORATE
      ) {
        viewData.prepaidCorporateCount = data.count;
        viewData.prepaidCorporateSipCount = data.concurrentCount;
      } else if (
        data.payType === AccountType.POST_PAID &&
        data.clientType === CustomerType.INDIVIDUAL
      ) {
        viewData.postpaidIndividualCount = data.count;
      } else if (
        data.payType === AccountType.POST_PAID &&
        data.clientType === CustomerType.CORPORATE
      ) {
        viewData.postpaidCorporeateCount = data.count;
        viewData.postpaidCorporateSipCount = data.concurrentCount;
      }
    }

    function convertToArray(zoneToDataMap) {
      let rows = [];
      let totalPrepaidIndividualCount = 0;
      let totalPrepaidCorporateCount = 0;
      let totalPrepaidCorporateSipCount = 0;
      let totalPostpaidIndividualCount = 0;
      let totalPostpaidCorporateCount = 0;
      let totalPostpaidCorporateSipCount = 0;

      zoneToDataMap.forEach((value, key) => {
        let tempRow = [];
        let prepaidIndividualCount = CheckValidityWithZeroDefault(
          value.prepaidIndividualCount
        );
        let prepaidCorporateCount = CheckValidityWithZeroDefault(
          value.prepaidCorporateCount
        );
        let prepaidCorporateSipCount = CheckValidityWithZeroDefault(
          value.prepaidCorporateSipCount
        );
        let postpaidIndividualCount = CheckValidityWithZeroDefault(
          value.postpaidIndividualCount
        );
        let postpaidCorporeateCount = CheckValidityWithZeroDefault(
          value.postpaidCorporeateCount
        );
        let postpaidCorporateSipCount = CheckValidityWithZeroDefault(
          value.postpaidCorporateSipCount
        );

        tempRow.push(key);
        tempRow.push(prepaidIndividualCount);
        tempRow.push(prepaidCorporateCount);
        tempRow.push(prepaidCorporateSipCount);
        tempRow.push(postpaidIndividualCount);
        tempRow.push(postpaidCorporeateCount);
        tempRow.push(postpaidCorporateSipCount);

        rows.push(tempRow);

        totalPrepaidIndividualCount += prepaidIndividualCount;
        totalPrepaidCorporateCount += prepaidCorporateCount;
        totalPrepaidCorporateSipCount += prepaidCorporateSipCount;
        totalPostpaidIndividualCount += postpaidIndividualCount;
        totalPostpaidCorporateCount += postpaidCorporeateCount;
        totalPostpaidCorporateSipCount += postpaidCorporateSipCount;
      });

      let tempRow = [];
      tempRow.push("Total");
      tempRow.push(totalPrepaidIndividualCount);
      tempRow.push(totalPrepaidCorporateCount);
      tempRow.push(totalPrepaidCorporateSipCount);
      tempRow.push(totalPostpaidIndividualCount);
      tempRow.push(totalPostpaidCorporateCount);
      tempRow.push(totalPostpaidCorporateSipCount);
      rows.push(tempRow);

      return rows;
    }

    return rows;
  },
  getActiveSubscriberDataRemoteUrl: function () {
    return RemoteURL.BTRC_PANEL.MONTHLY_REPORT.ACTIVE_SUBSCRIBER_INFO;
  },
  getProvisionalSubscriberDataRemoteUrl: function () {
    return RemoteURL.BTRC_PANEL.MONTHLY_REPORT.PROVISIONAL_SUBSCRIBER_INFO;
  },
};

export default SubscriberDataInfo;
