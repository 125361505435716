import React from "react";
import { useParams } from "react-router";
import { ConnectionRegistration } from "../connectionRegistration/ConnectionRegistration";
import { RegistrationType } from "../domainObject/DomainDataType";

export const EmployeeAddConnection = (props) => {
  let { customerId } = useParams();

  return (
    <div>
      <ConnectionRegistration
        customerId={customerId}
        registrationType={RegistrationType.OFFLINE}
      />
    </div>
  );
};
