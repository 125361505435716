import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { PasswordForm } from "../common/form/PasswordForm";
import { RequiredMarker } from "../common/form/RequiredMarker";
import { Address } from "../domainObject/CustomerInfo";
import { OrderedCustomerRegistrationStatus } from "../domainObject/DomainDataType";
import NotificationService, { NotificationMessage, NotificationType } from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { REGEX } from "../helper/Utility";
import { AddressForm } from "./AddressForm";

export const CompanyRegistration = (props) => {
  const [validated, setValidated] = useState(false);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [tin, setTin] = useState("");
  const [tinInvalid, setTinInvalid] = useState(false);
  const [companyAddressFlatNo, setCompanyAddressFlatNo] = useState("");
  const [companyAddressHouseNo, setCompanyAddressHouseNo] = useState("");
  const [companyAddressRoadNo, setCompanyAddressRoadNo] = useState("");
  const [companyAddressArea, setCompanyAddressArea] = useState("");
  const [companyAddressUpazilaId, setCompanyAddressUpazilaId] = useState("");
  const [companyAddressPostCode, setCompanyAddressPostCode] = useState("");

  function extractResponse(response) {
    NotificationService.show(NotificationMessage.COMPANY_CREATION_SUCCESS, NotificationType.SUCCESS);
    props.updateCompanyId(response.companyId);
    props.updateStatus(OrderedCustomerRegistrationStatus.COMPANY_REGISTERED);
  }

  const createAddressRequest = () => {
    let companyAddress = new Address();
    companyAddress.flatNo = companyAddressFlatNo;
    companyAddress.houseNo = companyAddressHouseNo;
    companyAddress.roadNo = companyAddressRoadNo;
    companyAddress.area = companyAddressArea;
    companyAddress.upazilaId = companyAddressUpazilaId;
    companyAddress.postCode = companyAddressPostCode;
    return companyAddress;
  };

  function registerCompany() {
    let request = {
      customerId: props.customerId,
      name: name,
      password: password,
      tin: tin,
      address: createAddressRequest(),
    };
    RestService.post(
      RemoteURL.CUSTOMER.REGISTRATION.COMPANY,
      request,
      extractResponse,
      props.handleServerError,
      false,
      true
    );
  }

  function handleCheckCompanyResponse(response) {
    if (response.isTrue) {
      setValidated(false);
      setTinInvalid(true);
      let error = {};
      error.message = "Company exist";
      props.handleServerError(error);
    } else {
      registerCompany();
    }
  }

  function checkCompany() {
    RestService.get(
      RemoteURL.COMPANY.QUERY.TIN_EXIST + "?tin=" + tin,
      handleCheckCompanyResponse,
      props.handleServerError,
      false
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      checkCompany();
    }
  }

  function checkAndSetTin(value) {
    if (
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
    ) {
      if (value.length > 12) {
        value = value.slice(0, 12);
      }
      setTin(value);
    }
  }

  return (
    <div>
      <br></br>
      <br></br>
      <h4 className="singleRowField">
        <strong>Create your company account</strong>
      </h4>
      <br></br>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="singleRowField" size="lg" controlId="email">
          <Form.Label>Company Name</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            autoFocus
            type="text"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="singleRowField" size="lg" controlId="email">
          <Form.Label>Tin(Username)</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            type="number"
            isInvalid={tinInvalid}
            value={tin}
            required
            onChange={(e) => {
              setTinInvalid(false);
              checkAndSetTin(e.target.value);
            }}
            pattern={REGEX.TIN_MATCH}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          {tinInvalid
            ? "Tin already exist"
            : "Please input TIN. It must be 13 digit."}
        </Form.Control.Feedback>

        <div className="singleRowField">
          <PasswordForm value={password} setValue={setPassword} />
        </div>
        <fieldset className="scheduler-border">
          <legend className="scheduler-border">Address:</legend>
          <Form.Group size="lg">
            <AddressForm
              allDivision={props.allDivision}
              flatNo={companyAddressFlatNo}
              setFlatNo={setCompanyAddressFlatNo}
              houseNo={companyAddressHouseNo}
              setHouseNo={setCompanyAddressHouseNo}
              roadNo={companyAddressRoadNo}
              setRoadNo={setCompanyAddressRoadNo}
              area={companyAddressArea}
              setArea={setCompanyAddressArea}
              upazilaId={companyAddressUpazilaId}
              setUpazilaId={setCompanyAddressUpazilaId}
              isZoneNeeded={true}
              postCode={companyAddressPostCode}
              setPostCode={setCompanyAddressPostCode}
              formReadOnly={false}
            />
          </Form.Group>
        </fieldset>
        <Button className="singleRowField" block size="lg" type="submit">
          Create
        </Button>
      </Form>
    </div>
  );
};
