import React from "react";
import { Button, Card, CardGroup, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { BkashPayment } from "../bkashPayment/BkashPayment";
import InvoiceDetail from "../common/InvoiceDetail";
import { MFSAgent, PaymentStatus } from "../domainObject/DomainDataType";
import NotificationService from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import authService from "../login/LoginService";

class CustomerPortalInvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceInfo: null,
      showPaymentForm: false,
      showMFSTrxClaimForm: false,
      fullInvoicePayment: true,
      paymentInvoiceDetailId: null,
      paymentAmount: null,
      mfsTrxClaimFormValidate: false,
      mfsTrxClaimFormAgent: MFSAgent.BKASH,
      mfsTrxClaimFormTrxId: null,
    };
  }

  handlePaymentFormClose() {
    this.setState({
      showPaymentForm: false,
      fullInvoicePayment: true,
      paymentInvoiceDetailId: null,
      paymentAmount: null,
    });
  }

  showMFSTrxClaimForm() {
    this.setState({
      showPaymentForm: false,
      showMFSTrxClaimForm: true,
    });
  }

  getPaymentForm() {
    return (
      <CardGroup>
        {this.state.invoiceInfo != null ? (
          <div>
            <Card>
              <Card.Body>
                <BkashPayment
                  amount={this.state.paymentAmount}
                  invoiceNumber={this.state.invoiceInfo.reference}
                  invoiceDetailId={this.state.paymentInvoiceDetailId}
                />
              </Card.Body>
            </Card>
            {/* <Card>
              <Card.Body>
                <Button onClick={this.showMFSTrxClaimForm.bind(this)}>
                  Claim
                </Button>
              </Card.Body>
            </Card> */}
          </div>
        ) : null}
      </CardGroup>
    );
  }

  paymentForm() {
    return (
      <Modal
        show={this.state.showPaymentForm}
        onHide={this.handlePaymentFormClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.getPaymentForm()}</Modal.Body>
      </Modal>
    );
  }

  extractPaymentResponse(response) {
    if (response.isTrue) {
      window.location.reload();
    }
  }

  completePayment(transaction) {
    let url = RemoteURL.PAYMENT.INVOICE;
    let requestBody = {
      transactionId: transaction.id,
      invoiceId: null,
      invoiceDetailId: null,
    };
    if (this.state.fullInvoicePayment) {
      requestBody.invoiceId = this.state.invoiceInfo.id;
    } else {
      requestBody.invoiceDetailId = this.state.paymentInvoiceDetailId;
    }

    RestService.post(
      url,
      requestBody,
      this.extractPaymentResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  errorFunction(error) {
    NotificationService.showRestServiceError(error);
  }

  extractMFSTransactionResponse(response) {
    this.completePayment(response);
  }

  claimMfsTrx() {
    let url = RemoteURL.TRANSACTION.MFS;
    let requestBody = {
      agent: this.state.mfsTrxClaimFormAgent,
      trxId: this.state.mfsTrxClaimFormTrxId,
    };
    RestService.post(
      url,
      requestBody,
      this.extractMFSTransactionResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  handleMFSTrxFormClose() {
    this.setState({
      showMFSTrxClaimForm: false,
    });
  }

  submitMFSTrxClaim(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      mfsTrxClaimFormValidate: true,
    });
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.claimMfsTrx();
    }
  }

  mfsTrxClaimForm() {
    return (
      <Modal
        show={this.state.showMFSTrxClaimForm}
        onHide={this.handleMFSTrxFormClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim MFS transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={this.state.mfsTrxClaimFormValidate}
            onSubmit={this.submitMFSTrxClaim.bind(this)}
          >
            <Form.Group controlId="formBasicSelect">
              <Form.Label> Payment type</Form.Label>
              <Form.Control
                as="select"
                value={this.state.mfsTrxClaimFormAgent}
                onChange={(e) => {
                  this.setState({
                    mfsTrxClaimFormAgent: e.target.value,
                  });
                }}
                required
                readOnly
              >
                <option value={MFSAgent.BKASH}>bKash</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select agent
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formGridAddress2">
              <Form.Label>Transaction Id</Form.Label>
              <Form.Control
                type="text"
                value={this.state.mfsTrxClaimFormTrxId}
                onChange={(e) => {
                  this.setState({
                    mfsTrxClaimFormTrxId: e.target.value,
                  });
                }}
                placeholder="Transaction id"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please input Transaction id
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={this.handleMFSTrxFormClose.bind(this)}
              >
                Close
              </Button>
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  showPaymentModalForFullInvoice() {
    this.setState({
      showPaymentForm: true,
      fullInvoicePayment: true,
      paymentAmount: this.state.invoiceInfo.remainingAmount,
    });
  }

  showFullPaymentButton() {
    let paymentPending =
      this.state.invoiceInfo != null &&
      this.state.invoiceInfo.paymentStatus !== PaymentStatus.PAID;
    if (paymentPending) {
      return (
        <Button
          variant="primary"
          onClick={this.showPaymentModalForFullInvoice.bind(this)}
        >
          Pay all
        </Button>
      );
    }
  }

  showPaymentModalForInvoiceDetail(invoiceDetailId, amount) {
    this.setState({
      showPaymentForm: true,
      fullInvoicePayment: false,
      paymentInvoiceDetailId: invoiceDetailId,
      paymentAmount: amount,
    });
  }

  showInvoiceDetailPaymentButton(invoiceDetail) {
    let paymentPending = invoiceDetail.paymentStatus !== PaymentStatus.PAID;
    if (paymentPending) {
      return (
        <Button
          variant="primary"
          onClick={this.showPaymentModalForInvoiceDetail.bind(
            this,
            invoiceDetail.id,
            invoiceDetail.remainingAmount
          )}
        >
          Pay
        </Button>
      );
    }
  }

  setInvoiceInfo(invoiceInfo) {
    this.setState({
      invoiceInfo: invoiceInfo,
    });
  }

  render() {
    return (
      <div>
        <InvoiceDetail
          invoiceId={this.props.match.params.invoiceId}
          setInvoiceInfo={this.setInvoiceInfo.bind(this)}
          fullPayment={this.showFullPaymentButton.bind(this)}
          partialPayment={this.showInvoiceDetailPaymentButton.bind(this)}
        />
        {this.paymentForm()}
        {this.mfsTrxClaimForm()}
      </div>
    );
  }
}

export default withRouter(CustomerPortalInvoiceDetail);
