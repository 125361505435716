import React from "react";

import { Alert } from "react-bootstrap";

import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  dateFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import { textFilter } from "react-bootstrap-table2-filter";
import RestService, { RemoteURL } from "../helper/RestService";
import NotificationService from "../helper/NotificationService";

const columns = [
  {
    dataField: "channel",
    text: "Channel",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "context",
    text: "Context",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "extension",
    text: "Extension",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "priority",
    text: "Priority",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "state",
    text: "State",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "application",
    text: "Application",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "data",
    text: "Data",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "callerId",
    text: "Caller Id",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "accountCode",
    text: "Account Code",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "peerAccount",
    text: "Peer Account",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "amaFlags",
    text: "Ama Flags",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "durationInSecond",
    text: "Duration(Second)",
    sort: true,
    filter: numberFilter(),
  },
  {
    dataField: "bridgeId",
    text: "Bridge Id",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "timeStamp",
    text: "Time Stamp",
    sort: true,
    filter: dateFilter(),
  },
];
const defaultSorted = [
  {
    dataField: "timeStamp",
    order: "desc",
  },
];

const emptyTableMessage = () => {
  return "No data found";
};
class ActiveCallReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: "",
      countData: "",
      spin: true,
      errorAlert: "",
    };
    this.fetchData = this.fetchData.bind(this);
  }

  getCountData(result) {
    let rows = [];
    rows.push(
      <p> {"Number of Active Channels: " + result.numOfActiveChannels} </p>
    );
    rows.push(<p> {"Number of Active Calls: " + result.numOfActiveCalls} </p>);
    rows.push(
      <p> {"Number of Processed Calls: " + result.numOfCallsProcessed} </p>
    );
    return rows;
  }

  errorHandler(error) {
    this.setState({
      spin: false,
    });
    NotificationService.showRestServiceError(error);
  }

  extractResponse(result) {
    if (result != null && result.data != null) {
      let row = Object.assign(result.data);
      this.setState({
        tableData: row,
        spin: false,
        countData: this.getCountData(result),
      });
    }
  }

  fetchData() {
    this.setState({
      spin: true,
    });

    let url = RemoteURL.BTRC_PANEL.ACTIVE_CALLS;

    RestService.get(
      url,
      this.extractResponse.bind(this),
      this.errorHandler.bind(this)
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        <Card bg="light" text="dark">
          <Card.Header>
            <span class="clickableSpan" onClick={this.fetchData}>
              <span class="tooltiptext">Click to refresh</span>
              <FontAwesomeIcon icon={faSync} spin={this.state.spin} size="2x" />
            </span>
          </Card.Header>
          <Card.Body>
            <div>
              <BootstrapTable
                bootstrap4
                keyField={"timeStamp"}
                data={this.state.tableData}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bodyStyle={{ overflow: "overlay" }}
                wrapperClasses="table-responsive"
                noDataIndication={emptyTableMessage}
              />
            </div>
            <Card.Text>{this.state.countData}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default ActiveCallReport;
