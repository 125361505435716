import React from "react";
import { Redirect } from "react-router-dom";
import { Routes } from "../../route/Routes";

function RedirectRoute(props) {
  return (
    <Redirect
      to={{
        pathname: Routes.BTRC_PORTAL.HOME,
        state: { from: props.location },
      }}
    />
  );
}

export default RedirectRoute;
