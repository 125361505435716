import React from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { REGEX } from "../helper/Utility";

export const OtherInformationForm = (props) => {
  return (
    <Row>
      <Col>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Father's Name</Form.Label>
          <Form.Control
            placeholder="Father's Name"
            value={props.fathersName}
            onChange={(e) => {
              props.setFathersName(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="50"
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Mother's Name</Form.Label>
          <Form.Control
            placeholder="Mother's Name"
            value={props.mothersName}
            onChange={(e) => {
              props.setMothersName(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="50"
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Spouse's Name</Form.Label>
          <Form.Control
            placeholder="Spouse's Name"
            value={props.spousesName}
            onChange={(e) => {
              props.setSpousesName(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="50"
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Identifier's Name</Form.Label>
          <Form.Control
            placeholder="Identifier's Name"
            value={props.identifiersName}
            onChange={(e) => {
              props.setIdentifiersName(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="50"
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Identifier's Contact No.</Form.Label>
          <Form.Control
            placeholder="Identifier's Contact No."
            type="tel"
            value={props.identifiersContactNo}
            onChange={(e) => {
              let value = e.target.value;
              if (
                value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
                value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
              ) {
                if (value.length >= 11) {
                  value = value.slice(0, 11);
                }
                props.setIdentifiersContactNo(value);
              }
              
            }}
            readOnly={props.formReadOnly}
            maxLength="11"
            pattern={REGEX.REGEX_ONLY_11_DIGIT_MATCH}
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Identifier's NID</Form.Label>
          <Form.Control
            placeholder="Identifier's NID"
            value={props.identifiersNID}
            onChange={(e) => {
              let value = e.target.value;
              if (
                value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
                value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
              ) {
                if (value.length >= 17) {
                  value = value.slice(0, 17);
                }
                props.setIdentifiersNID(e.target.value);
              }
            }}
            readOnly={props.formReadOnly}
            maxLength="17"
            minLength="10"
            pattern={REGEX.REGEX_ONLY_10_OR_11_DIGIT_MATCH}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};
