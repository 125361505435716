import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CommonFooter } from "../common/CommonFooter";
import { CommonHeader } from "../common/CommonHeader";
import SideBar from "../common/Sidebar";
import { CustomerRegistration } from "../customerRegistration/CustomerRegistration";
import {
  CustomerStatus,
  CustomerType,
  OrderedCustomerRegistrationStatus,
} from "../domainObject/DomainDataType";
import { RoleService } from "../domainObject/Role";
import RestService, { RemoteURL } from "../helper/RestService";
import { noop, SetPageTitle } from "../helper/Utility";
import authService from "../login/LoginService";
import { Routes } from "../route/Routes";
import { CustomerSupportReqPanel } from "./CustomerSupportReqPanel";

export const SidebarMenuItem = {
  ADDRESS_LIST: "ADDRESS_LIST",
  CONNECTION_LIST: "CONNECTION_LIST",
  REQUEST_CONNECTION: "REQUEST_CONNECTION",
  EXISTING_CONNECTION_REQUEST: "EXISTING_CONNECTION_REQUEST",
  CONNECTION_DETAIL: "CONNECTION_DETAIL",
  REGISTER_ADDRESS: "REGISTER_ADDRESS",
  INVOICE_LIST: "INVOICE_LIST",
  INVOICE_DETAIL: "INVOICE_DETAIL",
  REFILL: "REFILL",
  CDR: "CDR",
  CALL_SUMMARY: "CALL_SUMMARY",
  SUPPORT_REQUEST: "SUPPORT_REQUEST",
  SUPPORT_LIST: "SUPPORT_LIST",
  SUPPORT_DETAIL: "SUPPORT_DETAIL",
};

function checkCurrentActive(callingPanel, activePanel) {
  if (callingPanel === activePanel) {
    return "nav-link active";
  } else {
    return "nav-link ";
  }
}

const SideBarMenu = (props) => (
  <div>
    <li>
      Connection
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.CONNECTION_LIST,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.CONNECTION_LIST}
          >
            <span data-feather="home"></span>
            All
          </a>
        </li>

        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.REQUEST_CONNECTION,
              props.activePanel
            )}
            href={
              Routes.CUSTOMER.PORTAL.REQUEST_CONNECTION + "/" + props.customerId
            }
          >
            <span data-feather="home"></span>
            Request new
          </a>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.EXISTING_CONNECTION_REQUEST,
                props.activePanel
              )}
              href={Routes.CUSTOMER.PORTAL.EXISTING_CONNECTION_REQUEST}
            >
              <span data-feather="home"></span>
              Payment pending requests
            </a>
          </li>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.CONNECTION_DETAIL,
              props.activePanel
            )}
          >
            <span data-feather="home"></span>
            Detail
          </a>
        </li>
      </ul>
    </li>
    <li>
      Address
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.ADDRESS_LIST,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.ADDRESS_LIST}
          >
            <span data-feather="home"></span>
            All
          </a>
        </li>

        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.REGISTER_ADDRESS,
              props.activePanel
            )}
            href={
              Routes.CUSTOMER.PORTAL.REGISTER_ADDRESS + "/" + props.customerId
            }
          >
            <span data-feather="home"></span>
            Add
          </a>
        </li>
      </ul>
    </li>
    <li>
      Invoice and Pay Bill
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.INVOICE_LIST,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.INVOICE_LIST + "/" + props.customerId}
          >
            <span data-feather="home"></span>
            All
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.INVOICE_DETAIL,
              props.activePanel
            )}
          >
            <span data-feather="home"></span>
            Detail
          </a>
        </li>
      </ul>
    </li>
    <li>
      Balance and Refill
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.REFILL,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.REFILL}
          >
            <span data-feather="home"></span>
            Refill
          </a>
        </li>
      </ul>
    </li>
    <li>
      Report
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.CDR,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.CDR}
          >
            <span data-feather="home"></span>
            CDR
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.CALL_SUMMARY,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.CALL_SUMMARY}
          >
            <span data-feather="home"></span>
            Call Summary
          </a>
        </li>
      </ul>
    </li>
    <li>
      Support
      <ul>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.SUPPORT_LIST,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.SUPPORT_LIST}
          >
            <span data-feather="home"></span>
            All
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.SUPPORT_REQUEST,
              props.activePanel
            )}
            href={Routes.CUSTOMER.PORTAL.SUPPORT_REQUEST}
          >
            <span data-feather="home"></span>
            Request
          </a>
        </li>
        <li>
          <a
            className={checkCurrentActive(
              SidebarMenuItem.SUPPORT_DETAIL,
              props.activePanel
            )}
          >
            <span data-feather="home"></span>
            Detail
          </a>
        </li>
      </ul>
    </li>
  </div>
);

class CustomerPortal extends React.Component {
  constructor(props) {
    super(props);
    SetPageTitle({
      title: "Customer portal",
      metaDescription: "Customer portal",
    });
    this.state = {
      customerInfo: null,
      registrationStatus: OrderedCustomerRegistrationStatus.COMPLETE,
      showSupportForm: false,
    };
  }

  extractCustomerDetailResponse(response) {
    this.setState({
      customerInfo: response,
    });
    this.checkCustomerStatus();
  }

  getCustomerInfo() {
    let userInfo = authService.getUserInfo();
    RestService.get(
      RemoteURL.CUSTOMER.GET.Detail + "/" + userInfo.id,
      this.extractCustomerDetailResponse.bind(this),
      noop
    );
  }

  extractRegistrationStatus(response) {
    this.setState({
      registrationStatus: response.status,
    });
  }

  getRegistrationStatus() {
    RestService.get(
      RemoteURL.CUSTOMER.REGISTRATION.STATUS + "/" + this.state.customerInfo.id,
      this.extractRegistrationStatus.bind(this),
      noop
    );
  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  checkCustomerStatus() {
    if (this.state.customerInfo != null) {
      if (this.state.customerInfo.status === CustomerStatus.ON_BOARDING) {
        this.getRegistrationStatus();
      }
    }
  }

  isRegistrationPending() {
    if (
      this.state.registrationStatus !==
        OrderedCustomerRegistrationStatus.COMPLETE &&
      this.state.registrationStatus !==
        OrderedCustomerRegistrationStatus.FIRST_CONNECTION_CREATED
    ) {
      if (RoleService.isCorporateAuthorizedUser()) {
        if (
          this.state.registrationStatus !==
          OrderedCustomerRegistrationStatus.COMPANY_ATTACHMENT_REGISTERED
        ) {
          return true;
        }
      } else {
        if (
          this.state.registrationStatus !==
          OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED
        ) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  getCustomerType() {
    if (RoleService.isCorporateAuthorizedUser()) {
      return CustomerType.CORPORATE;
    } else {
      return CustomerType.INDIVIDUAL;
    }
  }

  getCompanyId() {
    if (this.state.customerInfo.companyInfo != null) {
      return this.state.customerInfo.companyInfo.id;
    } else {
      return null;
    }
  }

  enableSupport() {
    this.setState({
      showSupportForm: true,
    });
  }

  closeSupportModal() {
    this.setState({
      showSupportForm: false,
    });
  }
  getSupportFormModal() {
    return (
      <Modal show={this.state.showSupportForm}  onHide={this.closeSupportModal.bind(this)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CustomerSupportReqPanel
            successCaseHandle={this.closeSupportModal.bind(this)}
          />
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <CommonHeader />
        <div>
          {this.isRegistrationPending() ? (
            <div>
              {this.getSupportFormModal()}
              <CustomerRegistration
                customerId={this.state.customerInfo.id}
                status={this.state.registrationStatus}
                registrationType={this.state.customerInfo.registrationType}
                customerType={this.getCustomerType()}
                companyId={this.getCompanyId()}
              />
              <h4 className="text-xl-center font-weight-bold">
                {" "}
                Facing problems to register?{" "}
                <Button
                  size="lg"
                  onClick={this.enableSupport.bind(this)}
                  variant="link"
                  block={false}
                >
                  Support
                </Button>
              </h4>
            </div>
          ) : (
            <div class="container-fluid">
              <div class="row">
                <SideBar
                  children={
                    <SideBarMenu
                      activePanel={this.props.activePanel}
                      customerId={
                        this.state.customerInfo === null
                          ? null
                          : this.state.customerInfo.id
                      }
                    />
                  }
                />

                <main
                  role="main"
                  class="mainBlock col-md-9 ml-sm-auto col-lg-10 pt-3 px-4"
                >
                  {this.props.currentPanel}
                </main>
              </div>
            </div>
          )}
        </div>
        <CommonFooter />
      </div>
    );
  }
}

export default CustomerPortal;
