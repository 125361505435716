import React from "react";
import { withRouter } from "react-router";
import { AccountType, ConnectionStatus } from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import authService from "../login/LoginService";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { Routes } from "../route/Routes";

export function connectionDetailButtonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.CUSTOMER.PORTAL.CONNECTION_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>);
}

export const CONNECTION_PANEL_SELECT_FILTER = {
  ACCOUNT_TYPE: {
    option: [
      {
        value: AccountType.PRE_PAID,
        label: "Prepaid",
      },
      {
        value: AccountType.POST_PAID,
        label: "Postpaid",
      },
    ],
  },
  STATUS_TYPE: {
    option: [
      {
        value: ConnectionStatus.WAITING_PRE_PAYMENT,
        label: "Activation charge pending",
      },
      {
        value: ConnectionStatus.WAITING_ACTIVATION,
        label: "Waiting for activation",
      },
      {
        value: ConnectionStatus.ACTIVE,
        label: "Active",
      },
      {
        value: ConnectionStatus.SUSPENDED,
        label: "Suspended",
      },
      {
        value: ConnectionStatus.DELETED,
        label: "Deleted",
      },
    ],
  },
};

export const CONNECTION_PANEL_DEFAULT_SORTED = [
  {
    dataField: "createdDate",
    order: "desc",
  },
];

export function numberNotAssignedFormatter(cell, row) {
  if (cell != null) {
    return cell;
  } else {
    return "Not assigned";
  }
}

export const CUSTOMER_CONNECTION_PANEL_COLUMNS = [
  {
    dataField: "iptspNumber",
    text: "IPTSP number",
    sort: true,
    formatter: numberNotAssignedFormatter,
    filter: textFilter(),
  },
  {
    dataField: "productInfo.accountType",
    text: "Product Type",
    sort: true,
    formatter: (cell) =>
      CONNECTION_PANEL_SELECT_FILTER.ACCOUNT_TYPE.option.filter(
        (opt) => opt.value === cell
      )[0].label || "",
    filter: selectFilter({
      options: CONNECTION_PANEL_SELECT_FILTER.ACCOUNT_TYPE.option,
    }),
  },

  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cell) =>
      CONNECTION_PANEL_SELECT_FILTER.STATUS_TYPE.option.filter(
        (opt) => opt.value === cell
      )[0].label || "",
    filter: selectFilter({
      options: CONNECTION_PANEL_SELECT_FILTER.STATUS_TYPE.option,
    }),
  },

  {
    dataField: "",
    text: "Detail",
    isDummyField: true,
    formatter: connectionDetailButtonAdd,
  },
];

export const CONNECTION_PANEL_EMPTY_TABLE_MESSAGE = () => {
  return "No connection found";
};
class CustomerConnectionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
    };
  }

  extractCustomerDetailResponse(response) {
    let connectionInfoList = [];
    for (let i = 0; i < response.connectionInfo.length; i++) {
      if (response.connectionInfo[i].status != ConnectionStatus.DISCARDED) {
        connectionInfoList.push(response.connectionInfo[i]);
      }
    }
    this.setState({
      tableData: connectionInfoList,
    });
  }

  getCustomerInfo() {
    let userInfo = authService.getUserInfo();
    RestService.get(
      RemoteURL.CUSTOMER.GET.Detail + "/" + userInfo.id,
      this.extractCustomerDetailResponse.bind(this)
    );
  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  render() {
    return (
      <div>
        <div>
          <BootstrapTable
            bootstrap4
            keyField={"id"}
            data={this.state.tableData}
            columns={CUSTOMER_CONNECTION_PANEL_COLUMNS}
            defaultSorted={CONNECTION_PANEL_DEFAULT_SORTED}
            filter={filterFactory()}
            filterPosition="top"
            pagination={paginationFactory({
              showTotal: true,
              hideSizePerPage: true,
            })}
            bodyStyle={{ overflow: "overlay" }}
            wrapperClasses="table-responsive"
            noDataIndication={CONNECTION_PANEL_EMPTY_TABLE_MESSAGE}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerConnectionPanel);
