import React from "react";
import { Redirect, Route } from "react-router-dom";
import authService from "../../login/LoginService";
import { Routes } from "../../route/Routes";

function PublicRoute(props) {
  let currentUser = authService.getUserInfo();
  if (authService.isLoggedIn()) {
    if (currentUser.roles.length > 0) {
      return (
        <Redirect
          to={{
            pathname: currentUser.roles[0].defaultPath,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: Routes.LOG_IN,
            state: { from: props.location },
          }}
        />
      );
    }
  } else {
    return <Route {...props} />;
  }
}

export default PublicRoute;
