import React from "react";

import { Alert } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import RestService, { RemoteURL } from "../helper/RestService";
import { RoleService } from "../domainObject/Role";
import { Link } from "react-router-dom";
import { Routes } from "../route/Routes";
import { ConnectionStatus } from "../domainObject/DomainDataType";

function buttonAdd(cell, row) {
  if (RoleService.isTechnicalEmployee()) {
    return (
      <Link
        to={{
          pathname: Routes.EMPLOYEE_PORTAL.CUSTOMER_ACTIVATION + "/" + row.id,
        }}
      >
        Detail
      </Link>
    );
  } else {
    return null;
  }
}

const columns = [
  {
    dataField: "personalInfo.name",
    text: "Customer Name",
  },
  {
    dataField: "customerType",
    text: "Customer Type",
  },
  {
    dataField: "personalInfo.governmentId",
    text: "Government Id",
  },
  {
    dataField: "user.userName",
    text: "User name",
  },
  {
    dataField: "companyInfo.name",
    text: "Company name",
  },
  {
    dataField: "companyInfo.tin",
    text: "Tin",
  },
  {
    dataField: "connectionsWaitingForActivation.length",
    text: "No. of pending connection",
  },
  {
    dataField: "",
    text: "",
    formatter: buttonAdd,
  },
];
const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];

const emptyTableMessage = () => {
  return "No data found";
};
class ConnectionActivationRequestPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: "",
      errorAlert: "",
    };
    this.fetchData = this.fetchData.bind(this);
  }

  errorHandler(error) {
    this.setState({
      errorAlert: (
        <Alert variant="danger">
          Server error !! Please contact administrator !
        </Alert>
      ),
      spin: false,
    });
  }

  extractResponse(result) {
    let row = Object.assign(result.data);
    this.setState({
      tableData: row,
    });
  }

  fetchData() {
    let url =
      RemoteURL.CUSTOMER.GET.ACTIVE_CUSTOMER_BY_CONNECTION_STATUS +
      "?connectionStatus=" +
      ConnectionStatus.WAITING_ACTIVATION;

    RestService.get(
      url,
      this.extractResponse.bind(this),
      this.errorHandler.bind(this)
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <BootstrapTable
        bootstrap4
        keyField={"id"}
        data={this.state.tableData}
        columns={columns}
        defaultSorted={defaultSorted}
        bodyStyle={{ overflow: "overlay" }}
        wrapperClasses="table-responsive"
        noDataIndication={emptyTableMessage}
      />
    );
  }
}

export default ConnectionActivationRequestPanel;
