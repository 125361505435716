import React, { useState } from "react";

import { Col, Row } from "react-bootstrap";
import LocationDomain from "../domainObject/Location";

import Form from "react-bootstrap/Form";
import RestService, { RemoteURL } from "../helper/RestService";
import { sortLocationDomainObj } from "../helper/Utility";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import { RequiredMarker } from "../common/form/RequiredMarker";

export const AddressForm = (props) => {
  const [allDistrictByDivision, setAllDistrictByDivision] = useState([]);
  const [allUpazilaByDistrict, setAllUpazilaByDistrict] = useState([]);
  const [zone, setZone] = useState("");
  const [upazilaId, setUpazilaId] = useState(props.upazilaId);
  const [districtId, setDistrictId] = useState("");
  const DISTRICT_BY_DIVISION_API_URL =
    RemoteURL.LOCATION.GET.ALL_DISTRICT_BY_DIVISION;
  const UPAZILA_BY_DISTRICT_API_URL =
    RemoteURL.LOCATION.GET.ALL_UPAZILA_BY_DIVISION;

  function errorHandler(error) {
    NotificationService.show(
      NotificationMessage.SOMETHING_WENT_WRONG,
      NotificationType.ERROR
    );
  }

  function getAllDistrictByDivision(divisionId, setterFunction) {
    function extractData(result) {
      let i = 0;
      let districtByDivision = [];
      for (i; i < result.length; i++) {
        districtByDivision.push(
          Object.assign(new LocationDomain.District(), result[i])
        );
      }
      districtByDivision.sort(sortLocationDomainObj);
      setterFunction(districtByDivision);
    }
    let fullUrl = DISTRICT_BY_DIVISION_API_URL + "/" + divisionId;

    RestService.get(fullUrl, extractData, errorHandler, false);
  }

  function getAllUpazilasByDistrict(districtId, setterFunction) {
    function extractData(result) {
      let i = 0;
      let upazilaByDistrict = [];
      for (i; i < result.length; i++) {
        upazilaByDistrict.push(
          Object.assign(new LocationDomain.Upazila(), result[i])
        );
      }
      upazilaByDistrict.sort(sortLocationDomainObj);
      setterFunction(upazilaByDistrict);
    }
    let fullUrl = UPAZILA_BY_DISTRICT_API_URL + "/" + districtId;
    RestService.get(fullUrl, extractData, errorHandler, false);
  }

  function setZoneByUpazila(upazilaId) {
    let i = 0;
    for (i; i < allUpazilaByDistrict.length; i++) {
      let upazila = allUpazilaByDistrict[i];
      if (upazila.id === parseInt(upazilaId)) {
        setZone(upazila.zoneInfo.zone);
        break;
      }
    }
  }

  return (
    <Row>
      <Col>
        {props.showName != null && props.showName === true ? (
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={props.name}
              onChange={(e) => {
                props.setName(e.target.value);
              }}
              readOnly={props.formReadOnly}
              maxLength="50"
            />
          </Form.Group>
        ) : null}
        <Form.Group>
          <Form.Label>Flat No.</Form.Label>
          <Form.Control
            value={props.flatNo}
            onChange={(e) => {
              props.setFlatNo(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="18"
          />
          <Form.Control.Feedback type="invalid">
            Please input Flat no.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>House No./Name</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            value={props.houseNo}
            onChange={(e) => {
              props.setHouseNo(e.target.value);
            }}
            required={true}
            readOnly={props.formReadOnly}
            maxLength="18"
          />
          <Form.Control.Feedback type="invalid">
            Please input House no/Name
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Road No.</Form.Label>
          <Form.Control
            value={props.roadNo}
            onChange={(e) => {
              props.setRoadNo(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="18"
          />
          <Form.Control.Feedback type="invalid">
            Please input Road no.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Area/Village</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            as="textarea"
            rows={3}
            value={props.area}
            onChange={(e) => {
              props.setArea(e.target.value);
            }}
            required
            readOnly={props.formReadOnly}
            maxLength="50"
          />
          <Form.Control.Feedback type="invalid">
            Please input area
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="formBasicSelect">
          <Form.Label>Division</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            as="select"
            onChange={(e) => {
              getAllDistrictByDivision(
                e.target.value,
                setAllDistrictByDivision
              );
              setUpazilaId("");
              setDistrictId("");
            }}
            required
            readOnly={props.formReadOnly}
            disabled={props.formReadOnly}
          >
            <option value={""}>Click to select a Division</option>
            {props.allDivision.map((division, i) => (
              <option value={division.id}>{division.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a division
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicSelect">
          <Form.Label>District</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            as="select"
            value={districtId}
            onChange={(e) => {
              setDistrictId(e.target.value);
              getAllUpazilasByDistrict(e.target.value, setAllUpazilaByDistrict);
              setUpazilaId("");
            }}
            required
            readOnly={props.formReadOnly}
            disabled={props.formReadOnly}
          >
            <option value={""}>Click to select a District</option>
            {allDistrictByDivision.map((district, i) => (
              <option value={district.id}>{district.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a district
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicSelect">
          <Form.Label>Select Upazila</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            as="select"
            value={upazilaId}
            onChange={(e) => {
              setUpazilaId(e.target.value);
              props.setUpazilaId(e.target.value);
              if (props.isZoneNeeded) {
                setZoneByUpazila(e.target.value);
              }
            }}
            required
            readOnly={props.formReadOnly}
            disabled={props.formReadOnly}
          >
            <option value={""}>Click to select an Upazila/Thana</option>
            {allUpazilaByDistrict.map((upazila, i) => (
              <option value={upazila.id}>{upazila.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a upazila
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Post Code</Form.Label>
          <Form.Control
            type="number"
            placeholder="Post code"
            value={props.postCode}
            onChange={(e) => {
              props.setPostCode(e.target.value);
            }}
            readOnly={props.formReadOnly}
            maxLength="18"
          />
        </Form.Group>
        {props.isZoneNeeded ? (
          <Form.Group>
            <Form.Label>Zone</Form.Label>
            <Form.Control value={zone} readOnly />
          </Form.Group>
        ) : null}
      </Col>
    </Row>
  );
};
