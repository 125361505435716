import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { PasswordForm } from "../common/form/PasswordForm";
import { PhoneNumberForm } from "../common/form/PhoneNumberForm";
import { TermsAndConditionsForm } from "../common/form/TermsAndConditionForm";
import {
  OrderedCustomerRegistrationStatus,
  RegistrationType,
} from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import { REGEX } from "../helper/Utility";
import { Routes } from "../route/Routes";

export const PhoneNumberRegistration = (props) => {
  const [contactNo, setContactNo] = useState(props.phoneNumber);
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [contactNoInvalid, setContactNoInvalid] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const history = useHistory();

  const TERMS_AND_CONDITIONS_DOWNLOAD_URL =
    RemoteURL.TERMS_AND_CONDITION.GET.CUSTOMER_REGISTRATION;

  function getTermsAndConditionUrl() {
    return RemoteURL.IPTSP_SERVER_BASE_URL + TERMS_AND_CONDITIONS_DOWNLOAD_URL;
  }

  const continueRegistration = () => {
    props.updateStatus(
      OrderedCustomerRegistrationStatus.PHONE_NUMBER_REGISTERED
    );
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    history.replace(Routes.LOG_IN);
  };

  const formSubmitSuccessModal = () => {
    return (
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            Thank you for choosing our IPTSP service. We need some information
            before providing your first connection. Shall we proceed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-info mr-auto"
            onClick={continueRegistration}
          >
            Continue
          </Button>
          <Button className="btn btn-info" onClick={handleCloseSuccessModal}>
            Later
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function extractResponse(response) {
    if (response.code == null) {
      props.updateCustomerId(response.id);
      if (props.registrationType === RegistrationType.ONLINE) {
        setShowSuccessModal(true);
      } else {
        continueRegistration();
      }
    } else {
      props.handleServerError(response);
    }
  }

  function registerPhoneNumber() {
    let request = {
      userName: contactNo,
      password: password,
      registrationType: props.registrationType,
      customerType: props.customerType,
    };
    if (props.registrationType === RegistrationType.ONLINE) {
      RestService.post(
        RemoteURL.CUSTOMER.REGISTRATION.REGISTER,
        request,
        extractResponse,
        props.handleServerError,
        false,
        true
      );
    } else {
      RestService.post(
        RemoteURL.CUSTOMER.REGISTRATION.REGISTER,
        request,
        extractResponse,
        props.handleServerError
      );
    }
  }
  function extractContactNoExistResponse(response) {
    if (response.isTrue) {
      setContactNoInvalid(true);
      setValidated(false);
      let error = {};
      error.message = "Already registered. ";
      props.handleServerError(error);
    } else {
      registerPhoneNumber();
    }
  }

  function checkContactNo() {
    let url = RemoteURL.CUSTOMER.REGISTRATION.CHECK_USER + "/" + contactNo;
    RestService.get(
      url,
      extractContactNoExistResponse,
      props.handleServerError,
      false
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      if (props.registrationType === RegistrationType.OFFLINE) {
        checkContactNo();
      } else {
        registerPhoneNumber();
      }
    }
  }

  function termsAndConditionForm() {
    if (props.registrationType === RegistrationType.ONLINE) {
      return <TermsAndConditionsForm url={getTermsAndConditionUrl()} />;
    } else {
      return null;
    }
  }

  return (
    <div className="Login h-100">
      <h5 className="singleRowField">
        <strong>Create your account</strong>
      </h5>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <PhoneNumberForm
          label={"Phone number(Username)"}
          value={contactNo}
          setValue={setContactNo}
          isInvalid={contactNoInvalid}
          readOnly={
            props.registrationType === RegistrationType.OFFLINE ? false : true
          }
        />
        <PasswordForm value={password} setValue={setPassword} />

        {termsAndConditionForm()}
        <Button block size="lg" type="submit">
          Create
        </Button>
      </Form>
      {formSubmitSuccessModal()}
    </div>
  );
};
