import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { RequiredMarker } from "../common/form/RequiredMarker";
import {
  MAX_FILE_UPLOAD_SIZE_IN_BYTES,
  PASSPORT_SIZE_PHOT_DIMENSION_PIXEL,
  SIGNATURE_SIZE_PHOT_DIMENSION_PIXEL,
} from "../config/Config";

export const PersonalAttachmentForm = (props) => {
  const [isPhotoInvalid, setIsPhotoInvalid] = useState(false);
  const [isGovernmentIdFileInvalid, setIsGovernmentIdFileInvalid] =
    useState(false);
  const [isSignatureInvalid, setSignatureInvalid] = useState(false);

  function checkPhotoDimension(inputPhoto, dimension, setterFunction, propSetterFunction) {
    let fr = new FileReader();

    fr.onload = function () {
      var img = new Image();

      img.onload = function () {
        let width = img.width;
        let height = img.height;
        if (width <= dimension && height <= dimension) {
          setterFunction(false);
          propSetterFunction(false);
        } else {
          setterFunction(true);
          propSetterFunction(true);
        }
      };

      img.src = fr.result;
    };

    fr.readAsDataURL(inputPhoto);
  }

  function checkPhoto(inputPhoto) {
    if (inputPhoto != null) {
      let fileExtension = inputPhoto.type;
      let fileSize = inputPhoto.size;
      if (
        !fileExtension.includes("image") ||
        fileSize > MAX_FILE_UPLOAD_SIZE_IN_BYTES
      ) {
        setIsPhotoInvalid(true);
        props.setIsPhotoInvalid(true);
        return;
      } else {
        setIsPhotoInvalid(false);
        props.setIsPhotoInvalid(false);
      }
      checkPhotoDimension(inputPhoto, PASSPORT_SIZE_PHOT_DIMENSION_PIXEL,setIsPhotoInvalid, props.setIsPhotoInvalid);
    }
  }

  function checkSignature(inputPhoto) {
    if (inputPhoto != null) {
      let fileExtension = inputPhoto.type;
      let fileSize = inputPhoto.size;
      if (
        !fileExtension.includes("image") ||
        fileSize > MAX_FILE_UPLOAD_SIZE_IN_BYTES
      ) {
        setSignatureInvalid(true);
        props.setIsSignatureInvalid(true);
      } else {
        setSignatureInvalid(false);
        props.setIsSignatureInvalid(false);
      }
    }
    checkPhotoDimension(inputPhoto, SIGNATURE_SIZE_PHOT_DIMENSION_PIXEL,setSignatureInvalid, props.setIsSignatureInvalid);
  }

  function checkGovernmentIdFile(inputFile) {
    if (inputFile != null) {
      let fileExtension = inputFile.type;
      let fileSize = inputFile.size;
      let validType = fileExtension.includes("pdf");
      if (!validType || fileSize > MAX_FILE_UPLOAD_SIZE_IN_BYTES) {
        setIsGovernmentIdFileInvalid(true);
        props.setIsGovernmentIdInvalid(true);
      } else {
        setIsGovernmentIdFileInvalid(false);
        props.setIsGovernmentIdInvalid(false);
      }
    }
  }

  return (
    <div>
      <Form.Group className="singleRowField mb-3" controlId="formFile">
        <Form.Label>
          Upload your photo( Size must be within 3MB and resolution can be
          maximum 600*600 pixel)
        </Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isPhotoInvalid}
          onChange={(e) => {
            let inputPhoto = e.target.files[0];
            props.setPhoto(inputPhoto);
            checkPhoto(inputPhoto);
          }}
          required
          readOnly={props.formReadOnly}
        />
        <Form.Control.Feedback type="invalid">
          {props.photo === null
            ? "Please upload a photo"
            : "Photo must be image. Size must be within 3MB and resolution can be maximum 600*600 pixel"}
        </Form.Control.Feedback>
      </Form.Group>
      <br></br>
      <Form.Group className="singleRowField mb-3" controlId="formFile">
        <Form.Label>
          Upload NID/Passport pdf(Size must be within 3MB)
        </Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isGovernmentIdFileInvalid}
          onChange={(e) => {
            let inputFile = e.target.files[0];
            props.setGovernmentIdFile(inputFile);
            checkGovernmentIdFile(inputFile);
          }}
          required
          readOnly={props.formReadOnly}
        />
        <Form.Control.Feedback type="invalid">
          {props.governmentIdFile === null
            ? "Please upload NID/Passport"
            : "NID/Passport must be  pdf. Size must be within 3MB."}
        </Form.Control.Feedback>
      </Form.Group>
      <br></br>
      <Form.Group className="singleRowField mb-3" controlId="formFile">
        <Form.Label>
          Upload Signature Image( Size must be within 3MB and resolution can be
          maximum 300*300 pixel)
        </Form.Label>{" "}
        <RequiredMarker />
        <Form.Control
          className="attachmentForm"
          type="file"
          isInvalid={isSignatureInvalid}
          onChange={(e) => {
            let inputFile = e.target.files[0];
            props.setSignature(inputFile);
            checkSignature(inputFile);
          }}
          required
          readOnly={props.formReadOnly}
        />
        <Form.Control.Feedback type="invalid">
          {props.signature === null
            ? "Please upload Signature image"
            : "Signature must be image . Size must be within 3MB and resolution can be maximum 300*300 pixel"}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
