import React, { useEffect, useState } from "react";
import { usePrevious } from "../common/UsePreviousHook";
import {
  OrderedCustomerRegistrationStatus,
  OtpType,
} from "../domainObject/DomainDataType";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { OTPGenerationForm } from "./OTPGenerationForm";

export const OTPGeneration = (props) => {
  const [contactNo, setContactNo] = useState(null);
  const [validated, setValidated] = useState(false);
  const [contactNoInvalid, setContactNoInvalid] = useState(false);

  const previousContactNo = usePrevious(contactNo);

  function extractOtpGenerationResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.OTP_REQUESTED,
        NotificationType.INFO
      );
      props.updatePhoneNumber(contactNo);
      props.updateStatus(OrderedCustomerRegistrationStatus.OTP_GENERATION);
    } else {
      let error = {};
      error.message = NotificationMessage.OTP_REQUEST_FAIL;
      props.handleServerError(error);
    }
  }

  function requestOtp() {
    let request = {
      type: OtpType.CUSTOMER_REGISTRATION,
      key: contactNo,
    };
    RestService.post(
      RemoteURL.OTP.GENERATION,
      request,
      extractOtpGenerationResponse,
      props.handleServerError,
      false,
      true
    );
  }

  function extractContactNoExistResponse(response) {
    if (response.isTrue) {
      setContactNoInvalid(true);
      setValidated(false);
      let error = {};
      error.message = "This contact is already registered.";
      props.handleServerError(error);
    } else {
      requestOtp();
    }
  }

  function checkContactNo() {
    let url = RemoteURL.CUSTOMER.REGISTRATION.CHECK_USER + "/" + contactNo;
    RestService.get(
      url,
      extractContactNoExistResponse,
      props.handleServerError,
      false
    );
  }
  useEffect(() => {
    if (
      contactNo != null &&
      contactNo !== "" &&
      contactNo !== previousContactNo
    ) {
      checkContactNo();
    }
  }, [contactNo]);

  function formSubmissionFunction(contactNo) {
    setContactNo(contactNo);
  }

  return (
    <OTPGenerationForm
      formSubmissionFunction={formSubmissionFunction}
      formValidationStatus={validated}
      contactNoInvalid={contactNoInvalid}
    />
  );
};
