import {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import { RemoteURL } from "../helper/RestService";


const CustomerCallSummaryReportDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.GET.CALL_SUMMARY_REPORT,


  defaultSorted: [
    {
      dataField: "callDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "callDate",
      text: "Call Date",
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: "iptspNumber",
      text: "IPTSP Number",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "answerCallCount",
      text: "Calls Answered",
    },
    {
      dataField: "busyCallCount",
      text: "Calls Busy",
    },
    {
      dataField: "cancelledCallCount",
      text: "Calls Cancelled",
    },
    {
      dataField: "durationAnswered",
      text: "Call Duration",
    }
  ],

};

export default CustomerCallSummaryReportDataInfo;
