import moment from "moment";
import filterFactory, {
  dateFilter,
  numberFilter,
  textFilter,
  selectFilter,
  Comparator
} from "react-bootstrap-table2-filter";

export const CheckValidityWithZeroDefault = function (data) {
  if (data == null) {
    return 0;
  }
  return data;
};

export const CheckValidityWithEmptyStringDefault = function (data) {
  if (data == null) {
    return "";
  }
  return data;
};

export const IsValidValue = function (data) {
  if (data == null) {
    return false;
  }
  return true;
};

export const sortLocationDomainObj = (a, b) => {
  var textA = a.name.toUpperCase();
  var textB = b.name.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

export const CheckEmptyObject = function (obj) {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  if (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  ) {
    return true;
  }
  return false;
};

export const MonthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


export const FormatNumber = function (number) {
  let i = 0;
  let formattedString = "";
  if (IsValidValue(number)) {
    for (i; i < number.length; i++) {
      if (i === 6) {
        formattedString += "-" + number[i];
      }
      formattedString += number;
    }
  }
  return formattedString;
};

export const ConvertSecondToMinute = function (seconds) {
  if (IsValidValue(seconds)) {
    return seconds / 60;
  } else {
    return 0;
  }
};

export const SetPageTitle = function (data = {}) {
  let defaultTitle = "Premium Connectivity Ltd.";
  data.title = defaultTitle + " " + data.title || defaultTitle;
  data.metaDescription = data.metaDescription || defaultTitle;

  document.title = data.title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", data.metaDescription);
};

export const formatAddress = function (address) {
  let formattedAddress = "";
  if (address != null) {
    if (address.flatNo != null) {
      formattedAddress += address.flatNo + ",";
    }
    if (address.houseNo != null) {
      formattedAddress += address.houseNo + ",";
    }
    if (address.flatNo != null) {
      formattedAddress += address.flatNo + ",";
    }
    if (address.area != null) {
      formattedAddress += address.area + ",";
    }
    if (address.upazila != null) {
      formattedAddress += address.upazila.name + ",";
      formattedAddress += address.upazila.district.name + ",";
      formattedAddress += address.upazila.district.division.name + ",";
    }
    if (address.postCode != null) {
      formattedAddress += address.postCode;
    }
  }
  return formattedAddress;
};

export const formatIptspNumber = (number) => {
  return number;
};

export const REGEX = {
  REGEX_ANY_ALPHABET_MATCH: /[A-Z a-z]/g,
  REGEX_ONLY_11_DIGIT_MATCH: "[0-9]{11}",
  NID_MATCH: "[0-9]{10}|[0-9]{17}",
  TIN_MATCH: "[0-9]{12}",
  PASSWORD_CHECK: "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$",
  PHONE_NUMBER_CHECK: "^01[3-9]\\d{8}",
};

export const noop = () => { };


export const downloadFile = (fileName, blob) => {
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download', fileName
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

export const CurrentYear = () => {
  return moment().format("Y");
}

export const currentMonth = () => {
  return moment().format("M");
}

export const getApplicationStartDate = () => {
  return "2021-10-01T00:00:00";
}

export const getPrevYearsUptoNow = () => {
  var year = new Date().getFullYear();
  let pervYear = [];
  pervYear.push(year);
  while (year > 2021) {
    year--;
    pervYear.push(year);
  }
  return pervYear;
}

export const getMonthNumberFilter = () => {
  return (
    selectFilter({
      options: {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
      }
    }))
}


export const getYearNumberFilter = () => {
  let years = getPrevYearsUptoNow();
  return (
    numberFilter({
      options: years,
      comparators: [Comparator.EQ],
      defaultValue: { comparator: Comparator.EQ },
      withoutEmptyComparatorOption: true,
    }))
}

export const getMonthFromNumber = (monthNumber) => {
  if (monthNumber !== null) {
    return moment(monthNumber, 'M').format('MMMM')
  } else {
    return "N/A";
  }
}

export const getYearFormatter = (year) => {
  if (year !== null) {
    return year;
  } else {
    return "N/A";
  }
}

export const roundToNDecimal = (number, n) => {
  return number.toFixed(n);
}

export const currencyFormatter = (cell, row) => {
  let number = parseFloat(cell);
  return number.toLocaleString('BDT');
}