import React from "react";
import {
  CallMinuteInfoTopicName,
  CallNetworkType,
  CallType,
} from "../../domainObject/DomainDataType";
import { RemoteURL } from "../../helper/RestService";


const IcxIptspCallDataInfo = {
  thead: (
    <thead>
      <tr>
        <td rowspan="1"></td>
        <td rowspan="1"></td>
        <td rowspan="1"></td>
        <th colspan="2" scope="colgroup">
          Outgoing Billing Calls and Minutes
        </th>
        <th colspan="2" scope="colgroup">
          Incoming Billing Calls and Minutes
        </th>
      </tr>
      <tr>
        <th>Topic</th>
        <th>Name Of ICX </th>
        <th>Number of E1 </th>
        <th>IPTSP Off-net(Termination to other network)</th>
        <th>International</th>
        <th>IPTSP Off-net(Termination to other network)</th>
        <th>International</th>
      </tr>
    </thead>
  ),

  transformRemoteDataToTableRows: function (data) {
    let rows = [];
    let icxList =new Set();
    let icxMapSumOfCallMinutes = new Map();
    let icxMapSumOfNumOfCallMinutes = new Map();
    for (let i=0; i < data.length; i++) {
      if(!icxList.has(data[i].nameOfICX)){
        icxList.add(data[i].nameOfICX);
        let sumOfCallMinutesRow = [
          CallMinuteInfoTopicName.SUM_OF_CALL_MINUTES.text,
          "",
          0,
          0,
          0,
          0,
          0,
        ];
        let sumOfNumberOfCallRow = [
          CallMinuteInfoTopicName.NUM_OF_NUMBER_OF_CALLS.text,
          "",
          0,
          0,
          0,
          0,
          0,
        ];

        icxMapSumOfCallMinutes.set(data[i].nameOfICX, sumOfCallMinutesRow);
        icxMapSumOfNumOfCallMinutes.set(data[i].nameOfICX, sumOfNumberOfCallRow);
      }
    }
    
    let i = 0;
    for (i; i < data.length; i++) {
      
      if (
        data[i].callType === CallType.OUTGOING &&
        data[i].callNetworkType === CallNetworkType.OFF_NET
      ) {
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[3] = parseFloat(data[i].noOfMinutes).toFixed(2);

        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[3] = data[i].noOfCalls;
      } else if (
        data[i].callType === CallType.OUTGOING &&
        data[i].callNetworkType === CallNetworkType.INTERNATIONAL
      ) {
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[4] = parseFloat(data[i].noOfMinutes).toFixed(2);

        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[4] = data[i].noOfCalls;
      } else if (
        data[i].callType === CallType.INCOMING &&
        data[i].callNetworkType === CallNetworkType.OFF_NET
      ) {
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[5] = parseFloat(data[i].noOfMinutes).toFixed(2);

        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[5] = data[i].noOfCalls;
      } else if (
        data[i].callType === CallType.INCOMING &&
        data[i].callNetworkType === CallNetworkType.INTERNATIONAL
      ) {
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfCallMinutes.get(data[i].nameOfICX)[6] = parseFloat(data[i].noOfMinutes).toFixed(2);

        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[1] = data[i].nameOfICX;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[2] = data[i].noOfE1;
        icxMapSumOfNumOfCallMinutes.get(data[i].nameOfICX)[6] = data[i].noOfCalls;
      }
    }
    for (const icx of icxList) {
    rows.push(icxMapSumOfCallMinutes.get(icx));
    rows.push(icxMapSumOfNumOfCallMinutes.get(icx));
    }
    return rows;
  },
  getRemoteUrl: function () {
    return RemoteURL.BTRC_PANEL.MONTHLY_REPORT.ICX_IPTSP_CALL_INFO;
  },
};

export default IcxIptspCallDataInfo;
