import React from "react";
import MonthlyReportTable from "./MonthlyReportTable";
import IcxConnectivityAndCapaCityDataInfo from "./IcxConnectivityAndCapacityDataInfo";
import PopAndMedaiaGatewayDataInfo from "./PopAndMediaGatewayDataInfo";

const CapacityOfInstallationReport = (props) => (
  <div>
    <MonthlyReportTable
      remoteApiUrl={IcxConnectivityAndCapaCityDataInfo.getRemoteUrl()}
      thead={IcxConnectivityAndCapaCityDataInfo.thead}
      transformRemoteDataToTableRows={
        IcxConnectivityAndCapaCityDataInfo.transformRemoteDataToTableRows
      }
      setSpinChild={props.setSpinChild}
    />
    <br />
    <br />
    <MonthlyReportTable
      remoteApiUrl={PopAndMedaiaGatewayDataInfo.getRemoteUrl()}
      thead={PopAndMedaiaGatewayDataInfo.thead}
      transformRemoteDataToTableRows={
        PopAndMedaiaGatewayDataInfo.transformRemoteDataToTableRows
      }
      setSpinChild={props.setSpinChild}
    />
  </div>
);

export default CapacityOfInstallationReport;
