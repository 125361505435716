import React from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  AccountType,
  CorporateVerificationType,
  CustomerStatus,
  CustomerType,
  GovernmentIdType,
  Occupation,
  OrderedCustomerRegistrationStatus,
  PersonalVerificationType,
} from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";

class CustomerDetailInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVerificationModal: false,
      connectionDetailModal: false,
      currentSelectedConnectionInfo: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.verificationData !== null) {
      if (
        prevProps.verificationData === null ||
        prevProps.verificationData.version !==
          this.props.verificationData.version
      ) {
        this.showVerificationButton();
      }
    }
  }

  personalInformation() {
    return (
      <Table responsive striped bordered hover size="sm">
        <tbody>
          {this.props.customerInfo.companyInfo !== null ? (
            <tr>
              <td>Company Name</td>
              <td>{this.props.customerInfo.companyInfo.name}</td>
            </tr>
          ) : null}
          <tr>
            <td>Name</td>
            <td>{this.props.customerInfo.personalInfo.name}</td>
          </tr>
          <tr>
            <td>Contact No</td>
            <td>{this.props.customerInfo.personalInfo.contactNo}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{this.props.customerInfo.personalInfo.emailId}</td>
          </tr>
          <tr>
            <td>Gender</td>

            <td>{this.props.customerInfo.personalInfo.gender}</td>
          </tr>
          <tr>
            <td>Date of Birth</td>

            <td>{this.props.customerInfo.personalInfo.dateOfBirth}</td>
          </tr>
          <tr>
            <td>Occupation</td>

            <td>
              {this.props.customerInfo.personalInfo.occupation !==
              Occupation.OTHER
                ? this.props.customerInfo.personalInfo.occupation
                : this.props.customerInfo.personalInfo.nameOfOccupation}
            </td>
          </tr>
          <tr>
            <td>Nationality</td>

            <td>{this.props.customerInfo.personalInfo.nationality}</td>
          </tr>
          <tr>
            <td>
              {this.props.customerInfo.personalInfo.governmentIdType ==
              GovernmentIdType.NATIONAL_ID
                ? "National Id"
                : "Passport id"}
            </td>

            <td>{this.props.customerInfo.personalInfo.governmentId}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  generalInformation() {
    let verifier = [];

    if (this.props.customerInfo.verifier !== null) {
      verifier.push(
        <tr>
          <td>Verifier Id</td>
          <td>{this.props.customerInfo.verifier.id}</td>
        </tr>
      );
      verifier.push(
        <tr>
          <td>Verifier Name</td>
          <td>{this.props.customerInfo.verifier.name}</td>
        </tr>
      );
      verifier.push(
        <tr>
          <td>Verifier employee Id</td>
          <td>{this.props.customerInfo.verifier.officeId}</td>
        </tr>
      );
      verifier.push(
        <tr>
          <td>Verifier Department</td>
          <td>{this.props.customerInfo.verifier.department}</td>
        </tr>
      );
    }
    return (
      <Table responsive striped bordered hover size="sm">
        <tbody>
          <tr>
            <td>Id</td>
            <td>{this.props.customerInfo.id}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{this.props.customerInfo.status}</td>
          </tr>
          <tr>
            <td>Registration type</td>
            <td>{this.props.customerInfo.registrationType}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>{this.props.customerInfo.customerType}</td>
          </tr>
          <tr>
            <td>User name</td>
            <td>{this.props.customerInfo.user.userName}</td>
          </tr>
          <tr>
            <td>Creation date</td>
            <td>{this.props.customerInfo.createdDate}</td>
          </tr>
          <tr>
            <td>Last Modification Date</td>
            <td>{this.props.customerInfo.modifiedDate}</td>
          </tr>
          {verifier}
        </tbody>
      </Table>
    );
  }

  generalConnectionInformation(connectionInfo) {
    let activator = [];
    if (connectionInfo.activator !== null) {
      activator.push(
        <tr>
          <td>Activator Id</td>
          <td>{connectionInfo.activator.id}</td>
        </tr>
      );
      activator.push(
        <tr>
          <td>Activator Name</td>
          <td>{connectionInfo.activator.name}</td>
        </tr>
      );
      activator.push(
        <tr>
          <td>Activator employee Id</td>
          <td>{connectionInfo.activator.officeId}</td>
        </tr>
      );
      activator.push(
        <tr>
          <td>Activator Department</td>
          <td>{connectionInfo.activator.department}</td>
        </tr>
      );
    }
    return (
      <Table responsive striped bordered hover size="sm">
        <tbody>
          <tr>
            <td>Id</td>
            <td>{connectionInfo.id}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{connectionInfo.status}</td>
          </tr>
          <tr>
            <td>Zone</td>
            <td>{connectionInfo.installationAddress.upazila.zoneInfo.zone}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>{connectionInfo.productInfo.accountType}</td>
          </tr>
          {connectionInfo.productInfo.accountType === AccountType.POST_PAID ? (
            <tr>
              <td>Credit Limit</td>
              <td>{connectionInfo.postPayCreditLimit}</td>
            </tr>
          ) : null}
          {connectionInfo.productInfo.accountType === AccountType.POST_PAID ? (
            <tr>
              <td>Security Deposit</td>
              <td>{connectionInfo.postPaySecurityDeposit}</td>
            </tr>
          ) : null}

          <tr>
            <td>Assigned Number</td>
            {connectionInfo.iptspNumber !== null ? (
              <td>{connectionInfo.iptspNumber}</td>
            ) : (
              <td>{""}</td>
            )}
          </tr>
          {activator}
        </tbody>
      </Table>
    );
  }

  handleConnectionDetailModalClose() {
    this.setState({
      connectionDetailModal: false,
    });
  }

  getConnectionDetailModal() {
    return (
      <div>
        {this.state.currentSelectedConnectionInfo !== null ? (
          <Modal
            show={this.state.connectionDetailModal}
            onHide={this.handleConnectionDetailModalClose.bind(this)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Tabs
                defaultActiveKey="generalConnectionInformation"
                className="mb-3"
              >
                <Tab
                  eventKey="generalConnectionInformation"
                  title="General Information"
                >
                  {this.generalConnectionInformation(
                    this.state.currentSelectedConnectionInfo
                  )}
                </Tab>
                <Tab
                  eventKey="installationAddress"
                  title="Installation Address"
                >
                  {this.addressTable(
                    this.state.currentSelectedConnectionInfo.installationAddress
                  )}
                </Tab>
                {this.state.currentSelectedConnectionInfo
                  .postPayBillingAddress !== null ? (
                  <Tab eventKey="billingAddress" title="Billing Address">
                    {this.addressTable(
                      this.state.currentSelectedConnectionInfo
                        .postPayBillingAddress
                    )}
                  </Tab>
                ) : null}
              </Tabs>
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
    );
  }

  showConnectionDetail(connectionInfo) {
    this.setState({
      connectionDetailModal: true,
      currentSelectedConnectionInfo: connectionInfo,
    });
  }

  showConnectionInfo(connectionInfo) {
    let properties = [];

    properties.push(
      <tr>
        <th>Id</th>
        <th>Status</th>
        <th>Concurrent channel</th>
        <th>Account type</th>
        <th>Zone</th>
        <th>Credit limit(Postpaid)</th>
        <th>Security deposit(Postpaid)</th>
        <th>Assigned number</th>
        <th></th>
      </tr>
    );

    for (let i = 0; i < connectionInfo.length; i++) {
      properties.push(
        <tr>
          <td>{connectionInfo[i].id}</td>
          <td>{connectionInfo[i].status}</td>
          <td>{connectionInfo[i].concurrentChannel}</td>
          <td>{connectionInfo[i].productInfo.accountType}</td>
          <td>{connectionInfo[i].installationAddress.upazila.zoneInfo.zone}</td>
          {connectionInfo[i].productInfo.accountType ===
          AccountType.POST_PAID ? (
            <td>{connectionInfo[i].postPayCreditLimit}</td>
          ) : (
            <td>{"N/A"}</td>
          )}
          {connectionInfo[i].productInfo.accountType ===
          AccountType.POST_PAID ? (
            <td>{connectionInfo[i].postPaySecurityDeposit}</td>
          ) : (
            <td>{"N/A"}</td>
          )}
          {connectionInfo[i].iptspNumber !== null ? (
            <td>{connectionInfo[i].iptspNumber}</td>
          ) : (
            <td>{""}</td>
          )}
          <td>
            {
              <Button
                variant="primary"
                onClick={this.showConnectionDetail.bind(
                  this,
                  connectionInfo[i]
                )}
              >
                Detail
              </Button>
            }
          </td>
        </tr>
      );
    }
    return (
      <Table responsive striped bordered hover size="sm">
        <tbody>{properties}</tbody>
      </Table>
    );
  }

  addressTable(address) {
    if (address != null) {
      return (
        <Table responsive striped bordered hover size="sm">
          <tbody>
            <tr>
              <td>Flat No.</td>
              <td>{address.flatNo}</td>
            </tr>
            <tr>
              <td>House No.</td>
              <td>{address.houseNo}</td>
            </tr>
            <tr>
              <td>Road No.</td>
              <td>{address.roadNo}</td>
            </tr>
            <tr>
              <td>Area</td>
              <td>{address.area}</td>
            </tr>
            <tr>
              <td>Division</td>
              <td>{address.upazila.district.division.name}</td>
            </tr>
            <tr>
              <td>District</td>
              <td>{address.upazila.district.name}</td>
            </tr>
            <tr>
              <td>Upazila</td>
              <td>{address.upazila.name}</td>
            </tr>
            <tr>
              <td>Post Code</td>
              <td>{address.postCode}</td>
            </tr>
          </tbody>
        </Table>
      );
    } else {
      return null;
    }
  }

  otherInformation() {
    return (
      <Table responsive striped bordered hover size="sm">
        <tbody>
          <tr>
            <td>Father's Name</td>
            <td>{this.props.customerInfo.otherInformation.fathersName}</td>
          </tr>
          <tr>
            <td>Mother's Name</td>
            <td>{this.props.customerInfo.otherInformation.mothersName}</td>
          </tr>
          <tr>
            <td>Spouse's Name</td>
            <td>{this.props.customerInfo.otherInformation.spousesName}</td>
          </tr>
          <tr>
            <td>Identifier's Name</td>
            <td>{this.props.customerInfo.otherInformation.identifiersName}</td>
          </tr>
          <tr>
            <td>Identifier's Contact No.</td>
            <td>
              {this.props.customerInfo.otherInformation.identifiersContactNo}
            </td>
          </tr>
          <tr>
            <td>Identifier's NID</td>
            <td>{this.props.customerInfo.otherInformation.identifiersNID}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  isEligibleForVerification() {
    let CustomerVerificationType = {};
    Object.assign(CustomerVerificationType, PersonalVerificationType);
    if (
      this.props.customerInfo !== null &&
      this.props.customerInfo.customerType === CustomerType.CORPORATE
    ) {
      Object.assign(CustomerVerificationType, CorporateVerificationType);
    }
    if (
      this.props.customerInfo !== null &&
      this.props.verificationData !== null &&
      this.props.customerInfo.status === CustomerStatus.REGISTERED &&
      this.props.eligibleToVerify
    ) {
      for (let [key, value] of Object.entries(CustomerVerificationType)) {
        if (!(key in this.props.verificationData.data)) {
          return false;
        } else {
          let data = this.props.verificationData.data[key];
          if (!data.status) {
            return false;
          }
        }
      }
      return true;
    }
  }

  extractCompleteVerificationResponse(response) {
    if (response.isTrue) {
      this.props.refreshCustomerInfo();
    }
  }

  errorFunction(error) {}

  verifyCustomer() {
    this.handleVerificationModalClose();
    let url =
      RemoteURL.CUSTOMER.VERIFICATION.COMPLETE +
      "/" +
      this.props.customerInfo.id;

    RestService.put(
      url,
      {},
      this.extractCompleteVerificationResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }

  showVerificationModal() {
    this.setState({
      showVerificationModal: true,
    });
  }

  handleVerificationModalClose() {
    this.setState({
      showVerificationModal: false,
    });
  }

  verificationConfirmationModal() {
    return (
      <Modal
        show={this.state.showVerificationModal}
        onHide={this.handleVerificationModalClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to submit?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={this.handleVerificationModalClose.bind(this)}
          >
            Close
          </Button>
          <Button variant="success" onClick={this.verifyCustomer.bind(this)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  isEligibleForActivation() {
    if (
      this.props.customerInfo !== null &&
      this.props.verificationData !== null &&
      this.props.customerInfo.status === CustomerStatus.VERIFIED &&
      this.props.eligibleToActivate
    ) {
      return true;
    } else {
      return false;
    }
  }

  showVerificationButton() {
    if (this.isEligibleForVerification()) {
      return (
        <Button
          variant="primary"
          onClick={this.showVerificationModal.bind(this)}
        >
          Verify
        </Button>
      );
    } else {
      return null;
    }
  }

  showAddConnectionButton() {
    let showAddConnectionButton = false;
    if (
      (this.props.registrationStatus ===
        OrderedCustomerRegistrationStatus.COMPLETE ||
        this.props.registrationStatus ===
          OrderedCustomerRegistrationStatus.FIRST_CONNECTION_CREATED ||
        this.props.registrationStatus ===
          OrderedCustomerRegistrationStatus.COMPANY_ATTACHMENT_REGISTERED ||
        (this.props.registrationStatus ===
          OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED &&
          this.props.customerInfo.type === CustomerType.INDIVIDUAL)) &&
      this.props.eligibleToVerify
    ) {
      showAddConnectionButton = true;
    }
    return (
      <div>
        {showAddConnectionButton ? (
          <Link
            to={{
              pathname:
                Routes.EMPLOYEE_PORTAL.ADD_CONNECTION +
                "/" +
                this.props.customerInfo.id,
            }}
          >
            Add Connection
          </Link>
        ) : // <Button
        //   variant="primary"
        //   onClick={this.showVerificationModal.bind(this)}
        // >
        //   Complete Registration
        // </Button>
        null}
      </div>
    );
  }

  showButtons() {
    return (
      <div>
        {this.showVerificationButton()}
        <br></br>
        <br></br>

        {this.props.customerInfo != null
          ? this.showAddConnectionButton()
          : null}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Tabs defaultActiveKey="generalInformation" className="mb-3">
          <Tab eventKey="generalInformation" title="General Information">
            {this.props.customerInfo !== null
              ? this.generalInformation()
              : null}
          </Tab>
          <Tab eventKey="personalInformation" title="Personal Information">
            {this.props.customerInfo !== null
              ? this.personalInformation()
              : null}
          </Tab>
          <Tab eventKey="activeConnections" title="Active connections">
            {this.props.customerInfo !== null
              ? this.showConnectionInfo(
                  this.props.customerInfo.activeConnections
                )
              : null}
          </Tab>
          <Tab eventKey="pendingConnections" title="Pending connections">
            {this.props.customerInfo !== null
              ? this.showConnectionInfo(
                  this.props.customerInfo.pendingConnections
                )
              : null}
          </Tab>
          <Tab eventKey="presentAddress" title="Present Address">
            {this.props.customerInfo !== null
              ? this.addressTable(this.props.customerInfo.presentAddress)
              : null}
          </Tab>
          <Tab eventKey="permanentAddress" title="Permanent Address">
            {this.props.customerInfo !== null
              ? this.addressTable(this.props.customerInfo.permanentAddress)
              : null}
          </Tab>
          <Tab eventKey="otherInformation" title="Other Information">
            {this.props.customerInfo !== null ? this.otherInformation() : null}
          </Tab>
        </Tabs>
        {this.showButtons()}
        {this.verificationConfirmationModal()}
        {this.getConnectionDetailModal()}
      </div>
    );
  }
}

export default CustomerDetailInfo;
