import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { CheckEmptyObject, IsValidValue } from "../helper/Utility";

class filterParam {
  filterField;
  filterData;
  filterComparator;
}

const emptyTableMessage = () => {
  return ("No data found");
};

export const FilterComparator = {
  LIKE: "LIKE",
  EQUAL: "EQ",
  NOT_EQUAL: "NEQ",
  LESS_THAN_EQUAL: "LEEQ",
  GREATER_THAN_EQUAL: "GEEQ",
  LESS_THAN: "LE",
  GREATER_THAN: "GE",
  getComparator: function (libraryComprator) {
    switch (libraryComprator) {
      case "LIKE": {
        return FilterComparator.LIKE;
      }
      case "=": {
        return FilterComparator.EQUAL;
      }
      case "!=": {
        return FilterComparator.NOT_EQUAL;
      }
      case "<=": {
        return FilterComparator.LESS_THAN_EQUAL;
      }
      case ">=": {
        return FilterComparator.GREATER_THAN_EQUAL;
      }
      case "<": {
        return FilterComparator.LESS_THAN;
      }
      case ">": {
        return FilterComparator.GREATER_THAN;
      }
    }
  },
};

const getSelectedFilterOption = (filterType, filterVal, SelectFilterOption) => {
  let filterObj;
  for (const key of Object.keys(SelectFilterOption)) {
    if (filterType === SelectFilterOption[key].key) {
      filterObj = SelectFilterOption[key];
    }
  }
  if (!CheckEmptyObject(filterObj)) {
    let selectFilterVal = "";
    Object.keys(filterObj.option).forEach(function (key, index) {
      if (key === filterVal) {
        selectFilterVal = filterObj.option[key];
      }
    });
    return selectFilterVal;
  }
};

export const GetFilters = function (filters, SelectFilterOption) {
  let filterArray = [];

  if (IsValidValue(filters) && !CheckEmptyObject(filters)) {
    Object.keys(filters).forEach(function (key, index) {
      let tempFilterParam = new filterParam();
      if (
        filters[key].filterType === "TEXT" &&
        filters[key].filterVal != null &&
        filters[key].filterVal != ""
      ) {
        tempFilterParam.filterField = key;
        tempFilterParam.filterData = filters[key].filterVal;
        tempFilterParam.filterComparator = FilterComparator.LIKE;
        filterArray.push(tempFilterParam);
      }
      if (filters[key].filterType === "NUMBER") {
        if (
          filters[key].filterVal.number !== "" &&
          filters[key].filterVal.comparator !== ""
        ) {
          tempFilterParam.filterField = key;
          tempFilterParam.filterData = filters[key].filterVal.number;
          tempFilterParam.filterComparator = FilterComparator.getComparator(
            filters[key].filterVal.comparator
          );
          filterArray.push(tempFilterParam);
        }
      }
      if (filters[key].filterType === "DATE") {
        if (
          filters[key].filterVal.date !== "" &&
          filters[key].filterVal.comparator !== ""
        ) {
          tempFilterParam.filterField = key;
          tempFilterParam.filterData = filters[key].filterVal.date;
          tempFilterParam.filterComparator = FilterComparator.getComparator(
            filters[key].filterVal.comparator
          );
          filterArray.push(tempFilterParam);
        }
      }
      if (filters[key].filterType === "SELECT") {
        if (
          filters[key].filterVal.date !== "" &&
          filters[key].filterVal.comparator !== ""
        ) {
          tempFilterParam.filterField = key;
          tempFilterParam.filterData = getSelectedFilterOption(
            key,
            filters[key].filterVal,
            SelectFilterOption
          );
          tempFilterParam.filterComparator = FilterComparator.EQUAL;
          filterArray.push(tempFilterParam);
        }
      }
    });
  }

  return filterArray;
};

const TableWithRemoteSearchAndFilter = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  keyField,
  columns,
  defaultSorted,
  selectRow,
  singleSelectFuntion,
  allSelectFuntion,
}) => (
  <div>
    {selectRow == null || !selectRow ?
      <BootstrapTable
        bootstrap4
        remote
        keyField={keyField}
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        filterPosition="top"
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          showTotal: true,
          hideSizePerPage: true,
        })}
        onTableChange={onTableChange}
        bodyStyle={{ overflow: "overlay" }}
        wrapperClasses="table-responsive"
        noDataIndication={emptyTableMessage}
      /> :
      <BootstrapTable
        bootstrap4
        remote
        keyField={keyField}
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        filterPosition="top"
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          showTotal: true,
          hideSizePerPage: true,
        })}
        onTableChange={onTableChange}
        bodyStyle={{ overflow: "overlay" }}
        wrapperClasses="table-responsive"
        noDataIndication={emptyTableMessage}
        selectRow={{
          mode: 'checkbox',
          clickToSelect: true,
          onSelect: (row, isSelect, rowIndex, e) => {
            return singleSelectFuntion(row, isSelect);
          },
          onSelectAll: (isSelect, rows, e) => {
           return allSelectFuntion(rows, isSelect);
          }
        }}
      />}
  </div>

);

export default TableWithRemoteSearchAndFilter;
