import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import InvoiceDetail from "../common/InvoiceDetail";
import { MFSAgent, PaymentStatus, PaymentType } from "../domainObject/DomainDataType";
import { RoleService } from "../domainObject/Role";
import NotificationService from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";

class EmployeePortalInvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceInfo: null,
      showPaymentForm: false,
      paymentFormValidated: false,
      paymentType: PaymentType.CHEQUE,
      cashAmount: null,
      cashSlipNo: null,
      cashIssueDate: null,
      chequeAmount: null,
      chequeNo: null,
      chequeBankId: null,
      chequeIssueDate: null,
      fullInvoicePayment: true,
      paymentInvoiceDetail: null,
      showPaymentSuccessModal: false,
      bankInfoList: [],
      mfsTrxId: null,
      mfsAmount: null,
    };
  }

  extractBankInfoResult(result) {
    this.setState({
      bankInfoList: result,
    });
  }

  fetchBankInfoList() {
    RestService.get(
      RemoteURL.BANK_INFO.GET.ACTIVE,
      this.extractBankInfoResult.bind(this),
      this.errorFunction.bind(this),
      false
    );
  }

  handlePaymentSuccessModalClose() {
    window.location.reload();
  }

  paymentSuccessModal() {
    return (
      <Modal
        show={this.state.showPaymentSuccessModal}
        onHide={this.handlePaymentSuccessModalClose.bind(this)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Payment successful</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handlePaymentSuccessModalClose.bind(this)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  extractPaymentResponse(response) {
    if (response.isTrue) {
      this.setState({
        showPaymentSuccessModal: true,
      });
    }
  }

  completePayment(transaction) {
    let url = RemoteURL.PAYMENT.INVOICE;
    let requestBody = {
      transactionId: transaction.id,
      invoiceId: null,
      invoiceDetailId: null,
    };
    if (this.state.fullInvoicePayment) {
      requestBody.invoiceId = this.state.invoiceInfo.id;
    } else {
      requestBody.invoiceDetailId = this.state.paymentInvoiceDetail.id;
    }

    RestService.post(
      url,
      requestBody,
      this.extractPaymentResponse.bind(this),
      this.errorFunction.bind(this)
    );
  }
  errorFunction(error) {
    NotificationService.showRestServiceError(error);
  }

  extractTransactionResponse(response) {
    this.completePayment(response);
  }

  createTransaction() {
    if (this.state.paymentType === PaymentType.CASH) {
      let url = RemoteURL.TRANSACTION.CASH;
      let requestBody = {
        customerId: this.state.invoiceInfo.customerInfo.id,
        amount: this.state.cashAmount,
        slipNo: this.state.cashSlipNo,
        issueDate: this.state.cashIssueDate,
      };

      RestService.post(
        url,
        requestBody,
        this.extractTransactionResponse.bind(this),
        this.errorFunction.bind(this)
      );
    } else if (this.state.paymentType === PaymentType.CHEQUE) {
      let url = RemoteURL.TRANSACTION.CHEQUE;
      let requestBody = {
        customerId: this.state.invoiceInfo.customerInfo.id,
        amount: this.state.chequeAmount,
        bankInfoId: this.state.chequeBankId,
        chequeNo: this.state.chequeNo,
        issueDate: this.state.chequeIssueDate,
      };

      RestService.post(
        url,
        requestBody,
        this.extractTransactionResponse.bind(this),
        this.errorFunction.bind(this)
      );
    } else if (this.state.paymentType === PaymentType.MFS) {
      let url = RemoteURL.TRANSACTION.MFS;
      let requestBody = {
        customerId: this.state.invoiceInfo.customerInfo.id,
        amount: this.state.mfsAmount,
        agent: MFSAgent.BKASH,
        trxId: this.state.mfsTrxId,
      };

      RestService.post(
        url,
        requestBody,
        this.extractTransactionResponse.bind(this),
        this.errorFunction.bind(this)
      );
    }
  }

  payment() {
    this.createTransaction();
  }

  submitPaymentForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      paymentFormValidated: true,
    });
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.payment();
      this.setState({
        showPaymentForm: false,
      });
    }
  }

  getPaymentDetailForm() {
    if (this.state.paymentType === PaymentType.CASH) {
      return (
        <div>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              value={this.state.cashAmount}
              onChange={(e) => {
                this.setState({
                  cashAmount: e.target.value,
                });
              }}
              placeholder="Amount"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input amount
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Slip no</Form.Label>
            <Form.Control
              type="text"
              value={this.state.cashSlipNo}
              onChange={(e) => {
                this.setState({
                  cashSlipNo: e.target.value,
                });
              }}
              placeholder="Slip no"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input slip no
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Issue date(mm/dd/yyyy)</Form.Label>
            <Form.Control
              type="date"
              value={this.state.cashIssueDate}
              onChange={(e) => {
                this.setState({ cashIssueDate: e.target.value });
              }}
              placeholder="Issue date"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please select issue date
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      );
    } else if (this.state.paymentType === PaymentType.CHEQUE) {
      return (
        <div>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              value={this.state.chequeAmount}
              onChange={(e) => {
                this.setState({
                  chequeAmount: e.target.value,
                });
              }}
              placeholder="Amount"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input amount
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicSelect">
            <Form.Label> Bank name</Form.Label>
            <Form.Control
              as="select"
              value={this.state.chequeBankId}
              onChange={(e) => {
                this.setState({
                  chequeBankId: e.target.value,
                });
              }}
              required
            >
              <option value={""}>Click to Select Bank</option>
              {this.state.bankInfoList.map((bankInfo, i) => (
                <option value={bankInfo.id}>{bankInfo.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a Bank
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Cheque no</Form.Label>
            <Form.Control
              type="text"
              value={this.state.chequeNo}
              onChange={(e) => {
                this.setState({
                  chequeNo: e.target.value,
                });
              }}
              placeholder="Cheque no"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input cheque no
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Issue date(mm/dd/yyyy)</Form.Label>
            <Form.Control
              type="date"
              value={this.state.chequeIssueDate}
              onChange={(e) => {
                this.setState({ chequeIssueDate: e.target.value });
              }}
              placeholder="Issue date"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please select issue date
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      );
    } else if (this.state.paymentType === PaymentType.MFS) {
      return (
        <div>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>MFS Agent</Form.Label>
            <Form.Control
              type="text"
              value="bKash"
              disabled
            />
          </Form.Group>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Transaction Id</Form.Label>
            <Form.Control
              type="text"
              value={this.state.mfsTrxId}
              onChange={(e) => {
                this.setState({
                  mfsTrxId: e.target.value,
                });
              }}
              placeholder="Transaction Id"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input transaction Id
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      );
    }
    else {
      return null;
    }
  }

  handlePaymentFormClose() {
    this.setState({
      showPaymentForm: false,
    });
  }

  getPaymentForm() {
    return (
      <Form
        noValidate
        validated={this.state.paymentFormValidated}
        onSubmit={this.submitPaymentForm.bind(this)}
      >
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Invoce no</Form.Label>
          <Form.Control
            type="text"
            value={this.state.invoiceInfo != null ?
              this.state.invoiceInfo.reference : ""}
            placeholder="Invocie no"
            disabled
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Invoice Remaining Amount</Form.Label>
          <Form.Control
            type="number"
            value={this.state.fullInvoicePayment?(
              this.state.invoiceInfo != null? (this.state.invoiceInfo.amount -this.state.invoiceInfo.paidAmount) :""
            ): (this.state.paymentInvoiceDetail != null? (this.state.paymentInvoiceDetail.amount -this.state.paymentInvoiceDetail.paidAmount) : "")}
            placeholder="Invoice amount"
            disabled
          />
        </Form.Group>
        <Form.Group controlId="formBasicSelect">
          <Form.Label> Payment type</Form.Label>
          <Form.Control
            as="select"
            value={this.state.paymentType}
            onChange={(e) => {
              this.setState({
                paymentType: e.target.value,
              });
            }}
            required
          >
            <option value={""}>Click to Select Payment type</option>
            <option value={PaymentType.CHEQUE}>Cheque</option>
            <option value={PaymentType.CASH}>Cash</option>
            <option value={PaymentType.MFS}>MFS</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select payment type
          </Form.Control.Feedback>
        </Form.Group>
        {this.getPaymentDetailForm()}
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={this.handlePaymentFormClose.bind(this)}
          >
            Close
          </Button>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    );
  }

  paymentForm() {
    return (
      <Modal
        destroyOnClose={true}
        show={this.state.showPaymentForm}
        onHide={this.handlePaymentFormClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.getPaymentForm()}</Modal.Body>
      </Modal>
    );
  }

  showPaymentModalForFullInvoice() {
    this.setState({
      showPaymentForm: true,
      fullInvoicePayment: true,
    });
    if (this.state.bankInfoList.length === 0) {
      this.fetchBankInfoList();
    }
  }

  showFullPaymentButton() {
    let paymentPending =
      this.state.invoiceInfo != null &&
      this.state.invoiceInfo.paymentStatus !== PaymentStatus.PAID;
    if (paymentPending && RoleService.isFinanceAndAccountingEmployee()) {
      return (
        <Button
          variant="primary"
          onClick={this.showPaymentModalForFullInvoice.bind(this)}
        >
          Pay all
        </Button>
      );
    }
  }

  showPaymentModalForInvoiceDetail(invoiceDetail) {
    this.setState({
      showPaymentForm: true,
      fullInvoicePayment: false,
      paymentInvoiceDetail: invoiceDetail,
    });
    if (this.state.bankInfoList.length === 0) {
      this.fetchBankInfoList();
    }
  }

  showInvoiceDetailPaymentButton(invoiceDetail) {
    let paymentPending = invoiceDetail.paymentStatus !== PaymentStatus.PAID;
    if (paymentPending && RoleService.isFinanceAndAccountingEmployee()) {
      return (
        <Button
          variant="primary"
          onClick={this.showPaymentModalForInvoiceDetail.bind(
            this,
            invoiceDetail
          )}
        >
          Pay
        </Button>
      );
    }
  }

  setInvoiceInfo(invoiceInfo) {
    this.setState({
      invoiceInfo: invoiceInfo,
    });
  }

  render() {
    return (
      <div>
        <InvoiceDetail
          invoiceId={this.props.match.params.invoiceId}
          setInvoiceInfo={this.setInvoiceInfo.bind(this)}
          fullPayment={this.showFullPaymentButton.bind(this)}
          partialPayment={this.showInvoiceDetailPaymentButton.bind(this)}
        />
        {this.paymentForm()}
        {this.paymentSuccessModal()}
      </div>
    );
  }
}

export default withRouter(EmployeePortalInvoiceDetail);
