import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { ConnectionStatus } from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";
import { noop } from "../helper/Utility";
import authService from "../login/LoginService";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Routes } from "../route/Routes";
import { Link } from "react-router-dom";
import NotificationService from "../helper/NotificationService";
class RefillPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerInfo: null,
      tableData: [],
      showRefillForm: false,
      refillAmount: null,
      selectedConnection: null,
      validated: false,
      bulkRefillList: [],
      showBulkRefillForm: false,
      bulkRefillIndividual: true,
      bulkRefillAmount: null
    };
  }

  extractCustomerDetailResponse(response) {
    let row = [];
    if (response != null) {
      for (let i = 0; i < response.prepaidConnections.length; i++) {
        let connection = response.prepaidConnections[i];
        if (connection.status === ConnectionStatus.ACTIVE) {
          row.push(connection);
        }
      }
    }
    this.setState({
      customerInfo: response,
      tableData: row,
    });
  }

  getCustomerInfo() {
    let userInfo = authService.getUserInfo();
    RestService.get(
      RemoteURL.CUSTOMER.GET.Detail + "/" + userInfo.id,
      this.extractCustomerDetailResponse.bind(this),
      noop
    );
  }

  handleRefillFormClose() {
    this.setState({
      showRefillForm: false,
      refillAmount: null,
      selectedConnection: null,
    });
  }


  handleBulkRefillFormClose() {
    this.setState({
      showBulkRefillForm: false,
    });
  }


  extractRefillInvoiceResponse(response) {
    this.props.history.push(
      Routes.CUSTOMER.PORTAL.INVOICE_DETAIL + "/" + response.id
    );
  }

  generateRefillInvoice() {
    let req = {};
    req.connectionId = this.state.selectedConnection.id;
    req.amount = this.state.refillAmount;
    let reqArray = [];
    reqArray.push(req);
    RestService.post(
      RemoteURL.INVOICE.CREATE.REFILL,
      reqArray,
      this.extractRefillInvoiceResponse.bind(this),
      NotificationService.showRestServiceError
    );
  }

  refillFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true,
    });

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.generateRefillInvoice();
    }
  }


  generateBulkRefillInvoice() {
    let reqArray = [];

    for (let i = 0; i < this.state.bulkRefillList.length; i++) {
      let req = {};
      req.connectionId = this.state.bulkRefillList[i].id;
      if (this.state.bulkRefillIndividual) {
        req.amount = this.state.bulkRefillList[i].refillAmount;
      } else {
        req.amount = this.state.bulkRefillAmount;
      }
      reqArray.push(req);
    }

    RestService.post(
      RemoteURL.INVOICE.CREATE.REFILL,
      reqArray,
      this.extractRefillInvoiceResponse.bind(this),
      NotificationService.showRestServiceError
    );
  }

  bulkRefillFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      validated: true,
    });

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.generateBulkRefillInvoice();
    }
  }


  refillModal() {
    return (
      <Modal
        show={this.state.showRefillForm}
        onHide={this.handleRefillFormClose.bind(this)}
        onSubmit={this.refillFormSubmit.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Refill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.refillFormSubmit.bind(this)}
          >
            <Form.Group controlId="formGridAddress2">
              <Form.Label>Number</Form.Label>
              <Form.Control
                type="text"
                placeholder={this.state.selectedConnection!=null?
                  this.state.selectedConnection.iptspNumber : 
                ''}
                disabled
              />
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={this.state.refillAmount}
                onChange={(e) => {
                  this.setState({
                    refillAmount: e.target.value,
                  });
                }}
                placeholder="amount"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please input amount
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button type="submit" className="btn btn-info mr-auto">
                Refill
              </Button>
              <Button
                className="btn btn-info"
                variant="danger"
                onClick={this.handleRefillFormClose.bind(this)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  showRefillModal(connection) {
    this.setState({
      showRefillForm: true,
      selectedConnection: connection,
    });
  }

  bulkRefillModal() {
    const formElement = [];
    if (this.state.bulkRefillIndividual) {
      for (let i = 0; i < this.state.bulkRefillList.length; i++) {
        formElement.push(
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Number</Form.Label>
            <Form.Control
              type="text"
              placeholder={this.state.bulkRefillList[i].iptspNumber}
              disabled
            />
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={this.state.bulkRefillList[i].refillAmount}
              onChange={(e) => {
                let index = i;
                let connectionList = this.state.bulkRefillList;
                let connection = connectionList[index];
                connection["refillAmount"] = e.target.value;
                this.setState({
                  bulkRefillList: connectionList,
                });
              }}
              placeholder="amount"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input amount
            </Form.Control.Feedback>
          </Form.Group>
        )
      }
    } else {
      for (var i = 0; i < this.state.bulkRefillList.length; i++) {
        formElement.push(
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Number</Form.Label>
            <Form.Control
              type="text"
              placeholder={this.state.bulkRefillList[i].iptspNumber}
              disabled
            />
          </Form.Group>
        )
      }
      formElement.push(
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Bulk Amount</Form.Label>
          <Form.Control
            type="number"
            min="1"
            value={this.state.bulkRefillAmount}
            onChange={(e) => {
              this.setState({
                bulkRefillAmount: e.target.value,
              });
            }}
            placeholder="amount"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please input amount
          </Form.Control.Feedback>
        </Form.Group>
      )
    }

    return (
      <Modal
        show={this.state.showBulkRefillForm}
        onHide={this.handleBulkRefillFormClose.bind(this)}
        onSubmit={this.bulkRefillFormSubmit.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Refill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.bulkRefillFormSubmit.bind(this)}
          >
            <Form.Check
              type="checkbox"
              label="Different Amount"
              checked={this.state.bulkRefillIndividual}
              onChange={() => {
                this.setState({
                  bulkRefillIndividual: !this.state.bulkRefillIndividual
                })
              }}
            />
            {formElement}
            <Modal.Footer>
              <Button type="submit" className="btn btn-info mr-auto">
                Refill
              </Button>
              <Button
                className="btn btn-info"
                variant="danger"
                onClick={this.handleBulkRefillFormClose.bind(this)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal >
    );
  }

  showBulkRefillModal(connection) {
    this.setState({
      showBulkRefillForm: true
    });
  }

  refillButton(cell, row) {
    return (
      <Button onClick={this.showRefillModal.bind(this, row)}>Refill</Button>
    );
  }

  detailButtonAdd(cell, row) {
    return (
      <Link
        to={{
          pathname: Routes.CUSTOMER.PORTAL.CONNECTION_DETAIL + "/" + row.id,
        }}
      >
        Detail
      </Link>
    );
  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  CUSTOMER_CONNECTION_REFILL_PANEL_COLUMNS = [
    {
      dataField: "reference",
      text: "Connection Id",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "iptspNumber",
      text: "IPTSP number",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: this.detailButtonAdd,
    },
    {
      dataField: "",
      text: "Refill",
      isDummyField: true,
      formatter: this.refillButton.bind(this),
    },
  ];

  CUSTOMER_CONNECTION_REFILL_PANEL_SORTED = [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ];

  CONNECTION_PANEL_EMPTY_TABLE_MESSAGE = () => {
    return "No connection found";
  };

  bulkSelectSignleRowSelection(connection, isSelect) {
    let existingConnectionList = this.state.bulkRefillList;
    existingConnectionList = existingConnectionList.filter(function (existingConnection) {
      return existingConnection.id !== connection.id;
    })
    if (isSelect) {
      existingConnectionList.push(connection);
    }

    this.setState({
      bulkRefillList: existingConnectionList
    })
  }

  bulkSelectAllRowSelection(connectionList, isSelect) {
    if (isSelect) {
      this.setState({
        bulkRefillList: connectionList
      })

    } else {
      this.setState({
        bulkRefillList: []
      })
    }
  }

  render() {
    return (
      <div>
        <Card>
          <Card.Header>
            {this.state.bulkRefillList.length != 0 ?
              <Button onClick={this.showBulkRefillModal.bind(this)}>Bulk Refill</Button>
              : null}
          </Card.Header>
          <Card.Body>
            <div>
              <BootstrapTable
                bootstrap4
                keyField={"id"}
                data={this.state.tableData}
                columns={this.CUSTOMER_CONNECTION_REFILL_PANEL_COLUMNS}
                defaultSorted={this.CUSTOMER_CONNECTION_REFILL_PANEL_SORTED}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory({
                  showTotal: true,
                  hideSizePerPage: true,
                })}
                bodyStyle={{ overflow: "overlay" }}
                wrapperClasses="table-responsive"
                noDataIndication={this.CONNECTION_PANEL_EMPTY_TABLE_MESSAGE}
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: true,
                  onSelect: (row, isSelect, rowIndex, e) => {
                    this.bulkSelectSignleRowSelection(row, isSelect);
                  },
                  onSelectAll: (isSelect, rows, e) => {
                    this.bulkSelectAllRowSelection(rows, isSelect);
                  }
                }}
              />
            </div>
          </Card.Body>
        </Card>

        {this.refillModal()}
        {this.bulkRefillModal()}
      </div>
    );
  }
}

export default withRouter(RefillPanel);
