import React, { useState } from "react";
import { FormGroup } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { AccountType, RegistrationType } from "../domainObject/DomainDataType";
import { REGEX } from "../helper/Utility";

const PostPayCreditAndSecurityForm = (props) => {
  const [creditLimit, setCreditLimit] = useState(0);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  let securityDepositEnteredManually = false;
  return (
    <Form.Group className="mb-3 singleRowField" controlId="formGridAddress2">
      <Form.Label>Credit Limit (Max {props.maxCreditLimit})</Form.Label>
      <Form.Control
        type="number"
        min="0"
        max={props.maxCreditLimit}
        value={creditLimit}
        onChange={(e) => {
          setCreditLimit(e.target.value);
          props.addToCreditLimit(props.index, e.target.value);
          if (!securityDepositEnteredManually) {
            setSecurityDeposit(e.target.value);
          }
        }}
        required
      />
      <Form.Control.Feedback type="invalid">
        Please input credit limit. Credit limit must be between 0 to
        {props.maxCreditLimit}
      </Form.Control.Feedback>
      <Form.Label>Security Deposit(Max {props.maxCreditLimit})</Form.Label>
      <Form.Control
        type="number"
        min="0"
        max={props.maxCreditLimit}
        value={securityDeposit}
        onChange={(e) => {
          securityDepositEnteredManually = true;
          setSecurityDeposit(e.target.value);
          props.addToSecurityDeposit(props.index, e.target.value);
        }}
        readOnly={
          props.registrationType === RegistrationType.OFFLINE ? false : true
        }
        required
      />
      <Form.Control.Feedback type="invalid">
        Please input security deposit. Security deposit must be between 0 to
        {props.maxCreditLimit}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const NumberForm = (props) => {
  const [value, setValue] = useState(null);
  function checkAndSetValue(value) {
    if (
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
      value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
    ) {
      if (value.length >= 11) {
        value = value.slice(0, 11);
      }
      setValue(value);
      props.addToNumber(props.index, value);
    }
  }
  return (
    <div>
      {props.inputNumber ? (
        <Form.Group
          className="mb-3 singleRowField"
          controlId="formGridAddress2"
        >
          <Form.Label>Number</Form.Label>
          <Form.Control
            type="tel"
            value={value}
            onChange={(e) => {
              checkAndSetValue(e.target.value);
            }}
            pattern={REGEX.REGEX_ONLY_11_DIGIT_MATCH}
            required
          />
          <Form.Control.Feedback type="invalid">
           Please input number
          </Form.Control.Feedback>
        </Form.Group>
      ) : null}
    </div>
  );
};

const ConcurrentChannelForm = (props) => {
  const [value, setValue] = useState(null);
  let form = [];
  if (props.maxConcurrentChannelValue === 1) {
    props.addToConcurrentChannel(props.index, 1);
    form.push(
      <Form.Group className="mb-3 singleRowField" controlId="formGridAddress2">
        <Form.Label>
          Concurrent Channel (Max {props.maxConcurrentChannelValue})
        </Form.Label>
        <Form.Control
          type="number"
          value={1}
          readOnly
          required
          min="1"
          max={props.maxConcurrentChannelValue}
        />

        <Form.Control.Feedback type="invalid">
          Please input Concurrent channel. It must be between 1 to{" "}
          {props.maxConcurrentChannelValue}
        </Form.Control.Feedback>
      </Form.Group>
    );
  } else {
    form.push(
      <Form.Group className="mb-3 singleRowField" controlId="formGridAddress2">
        <Form.Label>
          Concurrent Channel (Max {props.maxConcurrentChannelValue})
        </Form.Label>
        <Form.Control
          type="number"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            props.addToConcurrentChannel(props.index, e.target.value);
          }}
          required
          min="1"
          max={props.maxConcurrentChannelValue}
        />

        <Form.Control.Feedback type="invalid">
          Please input Concurrent channel. It must be between 1 to{" "}
          {props.maxConcurrentChannelValue}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  return <div>{form}</div>;
};

export const ConnectionRegistrationForm = (props) => {
  return (
    <fieldset className="scheduler-border">
      <legend className="scheduler-border">
        Connection no : {props.index + 1}
      </legend>
      <FormGroup>
        {props.registrationType === RegistrationType.OFFLINE ? (
          <NumberForm
            addToNumber={props.addToNumber}
            index={props.index}
            inputNumber={props.inputNumber}
          />
        ) : null}
        <div controlId="formGridAddress2">
          <ConcurrentChannelForm
            maxConcurrentChannelValue={props.maxConcurrentChannelValue}
            addToConcurrentChannel={props.addToConcurrentChannel}
            index={props.index}
          />
          {props.accountType === AccountType.POST_PAID ? (
            <PostPayCreditAndSecurityForm
              maxCreditLimit={props.maxCreditLimit}
              addToCreditLimit={props.addToCreditLimit}
              addToSecurityDeposit={props.addToSecurityDeposit}
              index={props.index}
              registrationType={props.registrationType}
            />
          ) : null}
        </div>
      </FormGroup>
    </fieldset>
  );
};
