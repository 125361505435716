export const Gender = {
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
};

export const Nationality = {
  BANGLADESHI: "BANGLADESHI",
  FOREIGNER: "FOREIGNER",
};

export const GovernmentIdType = {
  NATIONAL_ID: "NATIONAL_ID",
  PASSPORT_ID: "PASSPORT_ID",
};

export const Occupation = {
  GOVERNMENT_SERVICE: "GOVERNMENT_SERVICE",
  PRIVATE_SERVICE: "PRIVATE_SERVICE",
  BUSINESS: "BUSINESS",
  STUDENT: "STUDENT",
  OTHER: "OTHER",
};

export const AccountType = {
  PRE_PAID: "PREPAID",
  POST_PAID: "POSTPAID",
};

export const CustomerType = {
  INDIVIDUAL: "INDIVIDUAL",
  CORPORATE: "CORPORATE",
};

export const CustomerStatus = {
  ON_BOARDING: "ON_BOARDING",
  REGISTERED: "REGISTERED",
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  DELETED: "DELETED",
};

export const CorporateVerificationType = {
  AUTHORIZATION_TO_PERSONNEL: "AUTHORIZATION_TO_PERSONNEL",
  TRADE_LICENCE: "TRADE_LICENCE",
  TIN: "TIN",
  BIN: "BIN",
};
export const PersonalVerificationType = {
  //NID: "NID",
  PHOTO: "PHOTO",
  SIGNATURE: "SIGNATURE",
  GOVERNMENT_ID: "GOVERNMENT_ID",
};

export const FileType = {
  PHOTO: "PHOTO",
  SIGNATURE: "SIGNATURE",
  GOVERNMENT_ID: "GOVERNMENT_ID",
  AUTHORIZATION_TO_PERSONNEL: "AUTHORIZATION_TO_PERSONNEL",
  TRADE_LICENCE: "TRADE_LICENCE",
  TIN: "TIN",
  BIN: "BIN",
  TERMS_AND_CONDITION: "TERMS_AND_CONDITION",
};

export const CallType = {
  OUTGOING: "OUTGOING",
  INCOMING: "INCOMING",
};

export const CallNetworkType = {
  ON_NET: "ON_NET",
  OFF_NET: "OFF_NET",
  INTERNATIONAL: "INTERNATIONAL",
};

export const IcxConnectivityAndCapacityDataField = {
  NAME_OF_ICX: "Name_of_ICX",
  Number_OF_Eone: "Number_OF_Eone",
};

export const CallMinuteInfoTopicName = {
  SUM_OF_CALL_MINUTES: { key: "noOfMinutes", text: "Sum of Call Minutes*" },
  NUM_OF_NUMBER_OF_CALLS: { key: "noOfCalls ", text: "Sum of No of Call*" },
  TOTAL_SUM_OF_CALL_MINUTES: {
    key: "TOTAL_SUM_OF_CALL_MINUTES",
    text: "Total of Call Minutes*",
  },
  TOTAL_NUM_OF_NUMBER_OF_CALLS: {
    key: "TOTAL_NUM_OF_NUMBER_OF_CALLS",
    text: "Total of No of Call*",
  },
};

export const ZoneKeyToView = {
  CENTRAL: "Central",
  NORTH_WEST: "North-West",
  NORTH_EAST: "North-East",
  SOUTH_WEST: "South-West",
  SOUTH_EAST: "South-East",
};

export const OtpType = {
  CUSTOMER_REGISTRATION: "CUSTOMER_REGISTRATION",
  FORGET_PASSWORD: "FORGET_PASSWORD",
};

export const RegistrationType = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

export const OrderedCustomerRegistrationStatus = {
  NEW_REGISTRATION: "NEW_REGISTRATION",
  OTP_GENERATION: "OTP_GENERATION",
  OTP_VERIFICATION: "OTP_VERIFICATION",
  PHONE_NUMBER_REGISTERED: "PHONE_NUMBER_REGISTERED",
  COMPANY_REGISTERED: "COMPANY_REGISTERED",
  PERSONAL_INFO_REGISTERED: "PERSONAL_INFO_REGISTERED",
  PERSONAL_ATTACHMENT_REGISTERED: "PERSONAL_ATTACHMENT_REGISTERED",
  COMPANY_ATTACHMENT_REGISTERED: "COMPANY_ATTACHMENT_REGISTERED",
  FIRST_CONNECTION_CREATED: "FIRST_CONNECTION_CREATED",
  COMPLETE: "COMPLETE",
};

export const ConnectionStatus = {
  WAITING_PRE_PAYMENT: "WAITING_PRE_PAYMENT",
  WAITING_ACTIVATION: "WAITING_ACTIVATION",
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  DELETED: "DELETED",
  DISCARDED: "DISCARDED"
};

export const NumberExistenceStatus = {
  FOUND: "FOUND",
  FOUND_IN_EXTERNAL: "FOUND_IN_EXTERNAL",
  NOT_FOUND: "NOT_FOUND",
};

export const InvoiceType = {
  SET_UP_CHARGE: "SET_UP_CHARGE",
  SECURITY_DEPOSIT: "SECURITY_DEPOSIT",
  MONTHLY_BILL: "MONTHLY_BILL",
  REFILL: "REFILL",
  CANCEL: "CANCEL",
};

export const PaymentStatus = {
  UNPAID: "UNPAID",
  PARTIAL_PAID: "PARTIAL_PAID",
  PAID: "PAID",
};

export const PaymentType = {
  CASH: "CASH",
  CHEQUE: "CHEQUE",
  MFS: "MFS"
};

export const MFSAgent = {
  BKASH: "BKASH",
};

export const CustomerSupportIssueType = {
  GENERAL: {
    key: "GENERAL",
    label: "General",
  },
  CONNECTION: {
    key: "CONNECTION",
    label: "Connection Specific",
  },
};

export const CustomerSupportIssueSummery = {
  CUSTOMER_REGISTRATION: {
    key: "CUSTOMER_REGISTRATION",
    label: "Facing problem to register",
  },
  ACCOUNT_CONVERSION: {
    key: "ACCOUNT_CONVERSION",
    label: "Conversion: Prepaid/postpaid",
  },
  CREDIT_LIMIT_INCREASE: {
    key: "CREDIT_LIMIT_INCREASE",
    label: "Increase credit limit",
  },
  REFILL_ISSUE: { key: "REFILL_ISSUE", label: "Refill issue" },
  BILL_PAYMENT_ISSUE: {
    key: "BILL_PAYMENT_ISSUE",
    label: "Bill payment issue",
  },
  OTHERS: { key: "OTHERS", label: "Others" },
};

export const CustomerSupportIssueStatus = {
  PENDING: {
    key: "PENDING",
    label: "Pending",
  },
  RESOLVED: {
    key: "RESOLVED",
    label: "Resolved",
  },
  REJECTED: {
    key: "REJECTED",
    label: "Rejected",
  },
};
