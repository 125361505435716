// @ts-nocheck

/**
 * In case of error : <Bkash was not found in window, please make sure you have provided the correct bkash.js  script url in	BkashComponent config prop>
 * Add 
 * window.bKash = bKash; 
 * in /node_modules/react-bkash/dist/index.js 
 * after 
 * yield loadDependencies(config.bkashScriptURL);
 */

import React, { useCallback, CSSProperties, FC, useState } from "react";
import {
  BkashButton,
  BkashComponentConfig,
  BkashSuccessFunction,
  post,
} from "react-bkash";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { RemoteURL } from "../helper/RestService";
import authService from "../login/LoginService";
import bkashLogo from "../image/bkash_payment_logo.png";



const buttonStyle: CSSProperties = {
  minWidth: "170px",
  height: "38px",
  border: "1px solid transparent",
  background: "transparent",
};

interface IProps {
  amount: string;
  invoiceNumber: string;
  invoiceDetailId: number;
  connectionId: number;
}

export const BkashPayment: FC<IProps> = (props) => {
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const [bkashButtonHidden, setBkashButtonHidden] = useState(false);
  const [bkashButtonDisable, setBkashButtonDisable] = useState(false);
  const IPTSP_BKASH_PAYMENT_ID = "IPTSP_BKASH_PAYMENT_ID";
  const IPTSP_BKASH_TRANSACTION_ID = "IPTSP_BKASH_TRANSACTION_ID";
  const BKASH_CHECKOUT_SCRIPT_URL = process.env.REACT_APP_BKASH_SCRIPT_URL;
  const config: BkashComponentConfig = {
    amount: props.amount,
    bkashScriptURL: BKASH_CHECKOUT_SCRIPT_URL,
    additionalHeaders: {},
    onCreatePayment: async (request) => {
      setBkashButtonDisable(true);
      let reqObj = {};
      reqObj.amount = props.amount;
      reqObj.invoiceNumber = props.invoiceNumber;
      reqObj.invoiceDetailId = props.invoiceDetailId;
      reqObj.connectionId = props.connectionId;
      reqObj.refreshToken = authService.getRefreshToken();

      let bearerToken = authService.getToken();
      let header = { Authorization: "Bearer " + bearerToken };

      const result = await post(
        RemoteURL.IPTSP_SERVER_BASE_URL +
          RemoteURL.PAYMENT.BKASH.CREATE_PAYMENT,
        reqObj,
        header
      );
      if (result.data.errorCode != null) {
        throw new Error(result.data.errorMessage);
      } else {
        localStorage.setItem(IPTSP_BKASH_PAYMENT_ID, result.data.paymentID);
        return result.data;
      }
    },
    //paymentID is string and will be needed by your backend API
    onExecutePayment: async (paymentID) => {
      let paymentId = localStorage.getItem(IPTSP_BKASH_PAYMENT_ID);
      let reqObj = {};
      reqObj.paymentId = paymentId;

      let bearerToken = authService.getToken();
      let header = { Authorization: "Bearer " + bearerToken };

      const result = await post(
        RemoteURL.IPTSP_SERVER_BASE_URL +
          RemoteURL.PAYMENT.BKASH.EXECUTE_PAYMENT,
        reqObj,
        header
      );
      if (result.data.errorCode != null) {
        throw new Error(result.data.errorMessage);
      } else {
        localStorage.setItem(IPTSP_BKASH_TRANSACTION_ID, result.data.trxID);
        return result.data;
      }
    },
  };

  const onSuccess: BkashSuccessFunction = useCallback((data) => {
    $("#bKashFrameWrapper").hide();
    setBkashButtonHidden(true);
    setPaymentSuccessModal(true);
  }, []);

  const onClose = useCallback(() => {
    console.log("Bkash iFrame closed");

    let paymentId = localStorage.getItem(IPTSP_BKASH_PAYMENT_ID);
    let reqObj = {};
    reqObj.paymentId = paymentId;
    reqObj.reason = "Cancel by user";

    let bearerToken = authService.getToken();
    let header = { Authorization: "Bearer " + bearerToken };

    const result = post(
      RemoteURL.IPTSP_SERVER_BASE_URL + RemoteURL.PAYMENT.BKASH.CANCEL_PAYMENT,
      reqObj,
      header
    );
    window.location.reload();
  }, []);

  const closePaymentSuccessModal = () => {
    setPaymentSuccessModal(false);
    window.location.reload();
  };

  const getPaymentSuccessModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={paymentSuccessModal}
        onHide={closePaymentSuccessModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          Payment successful. Your bKash transaction id is{" "}
          {localStorage.getItem(IPTSP_BKASH_TRANSACTION_ID)}.
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      <BkashButton
        config={config}
        onClose={onClose}
        onSuccess={onSuccess}
        loader={<p>loading...</p>} //optional
        renderError={(error) => <p>{error.message}</p>}
        debug={false}
      >
        <button hidden={bkashButtonHidden} disabled={bkashButtonDisable} style={buttonStyle}>
          <img src={bkashLogo} minWidth="2000px" height="38px" />
        </button>
      </BkashButton>
      {getPaymentSuccessModal()}
    </div>
  );
};
