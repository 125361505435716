import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { CompanyAttachment } from "../domainObject/CustomerInfo";
import { OrderedCustomerRegistrationStatus } from "../domainObject/DomainDataType";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { CompanyAttachmentForm } from "./CompanyAttachmentForm";

export const CompanyAttachmentRegistration = (props) => {
  const [validated, setValidated] = useState(false);

  const [authorizationToPersonnel, setAuthorizationToPersonnel] =
    useState(null);
  const [tradeLicense, setTradeLicense] = useState(null);
  const [tin, setTin] = useState(null);
  const [bin, setBin] = useState(null);

  const [
    isAuthorizationToPersonnelInvalid,
    setIsAuthorizationToPersonnelInvalid,
  ] = useState(null);
  const [isTradeLicenseInvalid, setIsTradeLicenseInvalid] = useState(null);
  const [isTinInvalid, setIsTinInvalid] = useState(null);
  const [isBinInvalid, setIsBinInvalid] = useState(null);

  const COMPANY_ATTACHMENT_UPLOAD_API_URL =
    RemoteURL.CUSTOMER.REGISTRATION.UPLOAD_COMPANY_ATTACHMENT;

  function extractResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.FILE_UPLOAD_SUCCESS,
        NotificationType.SUCCESS
      );
      props.updateStatus(
        OrderedCustomerRegistrationStatus.COMPANY_ATTACHMENT_REGISTERED
      );
    } else {
      let error = {};
      error.message = "Customer Attachment upload failed !!";
      props.handleServerError(error);
    }
  }

  const createCompanyAttachment = () => {
    let companyAttachment = new CompanyAttachment();
    companyAttachment.authorizationToPersonnel = authorizationToPersonnel;
    companyAttachment.tradeLicense = tradeLicense;
    companyAttachment.tin = tin;
    companyAttachment.bin = bin;
    return companyAttachment;
  };

  function submitCompanyAttachment(companyId, companyAttachment) {
    var data = new FormData();
    data.append("authorization", companyAttachment.authorizationToPersonnel);
    data.append("tradeLicense", companyAttachment.tradeLicense);
    data.append("tin", companyAttachment.tin);
    data.append("bin", companyAttachment.bin);
    data.append("id", companyId);

    RestService.postFileUpload(
      COMPANY_ATTACHMENT_UPLOAD_API_URL,
      data,
      extractResponse,
      props.handleServerError,
      false
    );
  }

  function registerCompanyAttachment() {
    let companyAttachment = createCompanyAttachment();
    submitCompanyAttachment(props.companyId, companyAttachment);
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      if (
        !isAuthorizationToPersonnelInvalid &&
        !isTradeLicenseInvalid &&
        !isTinInvalid &&
        !isBinInvalid
      ) {
        registerCompanyAttachment();
      } else {
        NotificationService.show(
          NotificationMessage.CHECK_FORM_INPUT,
          NotificationType.ERROR
        );
      }
    }
  }
  function companyAttachmentForm() {
    return (
      <CompanyAttachmentForm
        authorizationToPersonnel={authorizationToPersonnel}
        setAuthorizationToPersonnel={setAuthorizationToPersonnel}
        setIsAuthorizationToPersonnelInvalid={
          setIsAuthorizationToPersonnelInvalid
        }
        tradeLicense={tradeLicense}
        setTradeLicense={setTradeLicense}
        setIsTradeLicenseInvalid={setIsTradeLicenseInvalid}
        tin={tin}
        setTin={setTin}
        setIsTinInvalid={setIsTinInvalid}
        bin={bin}
        setBin={setBin}
        setIsBinInvalid={setIsBinInvalid}
        formReadOnly={false}
      />
    );
  }

  return (
    <div className="Registration h-100">
      <br></br>
      <br></br>
      <h5 className="singleRowField">
        <strong>Upload company documents</strong>
      </h5>
      <br></br>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {companyAttachmentForm()}
        <Button className="singleRowField" block size="lg" type="submit">
          Upload
        </Button>
      </Form>
      <br></br>
      <br></br>
    </div>
  );
};
