import React from "react";
import { Route, Switch } from "react-router";
import ProtectedRoute from "../common/route/ProtectedRoute";
import PublicRoute from "../common/route/PublicRoute";
import RedirectRoute from "../common/route/RedirectRoute";
import { CustomerRegistrationPage } from "../customerRegistration/CustomerRegistrationPage";
import { Role } from "../domainObject/Role";
import ForgetPasswordPage from "../login/ForgetPassword";
import LoginPage from "../login/LoginPage";
import { BtrcPortalRoutes } from "./BtrcPortalRoutes";
import { CompanyPortalRoutes } from "./CompanyPortalRoutes";
import { CustomerPortalRoutes } from "./CustomerPortalRoutes";
import { EmployeePortalRoutes } from "./EmployeePortalRoutes";
import { Routes } from "./Routes";

const getRoutes = () => {
  return (
    <Switch>
      <RedirectRoute exact path={"/portal"} />
      <PublicRoute exact path={Routes.LOG_IN}>
        <LoginPage />
      </PublicRoute>

      <PublicRoute exact path={Routes.FORGET_PASSWORD}>
        <ForgetPasswordPage />
      </PublicRoute>

      <PublicRoute exact path={Routes.REGISTRATION.CUSTOMER}>
        <CustomerRegistrationPage />
      </PublicRoute>

      <ProtectedRoute
        path={Routes.BTRC_PORTAL.HOME}
        roles={[Role.ADMIN, Role.BTRC]}
        component={BtrcPortalRoutes}
      />

      <ProtectedRoute
        path={Routes.EMPLOYEE_PORTAL.HOME}
        roles={[
          Role.ADMIN,
          Role.EMPL_SALES_AND_MARKETING,
          Role.EMPL_TECHNICAL,
          Role.EMPL_ACCOUNT_AND_FINANCE,
        ]}
        component={EmployeePortalRoutes}
      />

      <ProtectedRoute
        path={Routes.CUSTOMER.PORTAL.HOME}
        roles={[Role.CUST_AUTHORIZED, Role.CUST_INDIVIDUAL]}
        component={CustomerPortalRoutes}
      />

      <ProtectedRoute
        path={Routes.COMPANY.PORTAL.HOME}
        roles={[Role.CUST_COMPANY]}
        component={CompanyPortalRoutes}
      />
    </Switch>
  );
};

export const PortalRoutes = (props) => {
  return (
    <div>
      <Switch>{getRoutes()}</Switch>
    </div>
  );
};
