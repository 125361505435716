import React, { useState } from "react";
import { Button, Card, CardGroup, Modal } from "react-bootstrap";
import {
  CustomerType,
  OrderedCustomerRegistrationStatus,
  RegistrationType,
} from "../domainObject/DomainDataType";

import { CustomerRegistration } from "./CustomerRegistration";

export const CustomerOfflineRegistration = (props) => {
  const [customerType, setCustomerType] = useState(null);
  const [showCustomerTypeSelectionModal, setShowCustomerTypeSelectionModal] =
    useState(true);

  function selectCustomerType(customerType) {
    setCustomerType(customerType);
    setShowCustomerTypeSelectionModal(false);
  }

  function getCustomerTypeSelectionModal() {
    return (
      <Modal show={showCustomerTypeSelectionModal}>
        <Modal.Header>
          <Modal.Title>Customer offline registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please choose customer type</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-info mr-auto"
            onClick={() => selectCustomerType(CustomerType.INDIVIDUAL)}
          >
            Individual
          </Button>
          <Button
            className="btn btn-info"
            onClick={() => selectCustomerType(CustomerType.CORPORATE)}
          >
            Corporate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      {getCustomerTypeSelectionModal()}
      {customerType != null ? (
        <CustomerRegistration
          status={OrderedCustomerRegistrationStatus.NEW_REGISTRATION}
          registrationType={RegistrationType.OFFLINE}
          customerType={customerType}
        />
      ) : null}
    </div>
  );
};
