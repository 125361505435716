import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AddressForm } from "../customerRegistration/AddressForm";
import { useHistory, useParams } from "react-router";
import { Address } from "../domainObject/CustomerInfo";
import LocationDomain from "../domainObject/Location";
import RestService, { RemoteURL } from "../helper/RestService";
import { sortLocationDomainObj } from "../helper/Utility";
import { Routes } from "../route/Routes";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";

export const AddressRegistration = (props) => {
  const DIVISION_API_URL = RemoteURL.LOCATION.GET.ALL_DIVISION;
  const [validated, setValidated] = useState(false);

  const [allDivision, setAllDivision] = useState([]);
  const [name, setName] = useState("");
  const [flatNo, setFlatNo] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [roadNo, setRoadNo] = useState("");
  const [area, setArea] = useState("");
  const [upazilaId, setUpazilaId] = useState("");
  const [postCode, setPostCode] = useState("");
  let { customerId } = useParams();
  const history = useHistory();

  function extractResponse(response) {
    NotificationService.show(
      NotificationMessage.ADDRESS_REGISTERED,
      NotificationType.SUCCESS
    );
    history.replace(Routes.CUSTOMER.PORTAL.ADDRESS_LIST);
  }

  const createAddressRequest = () => {
    let address = new Address();
    address.name = name;
    address.flatNo = flatNo;
    address.houseNo = houseNo;
    address.roadNo = roadNo;
    address.area = area;
    address.upazilaId = upazilaId;
    address.postCode = postCode;
    return address;
  };

  function errorFunction(error) {
    NotificationService.showRestServiceError(error);
  }

  function registerAddress() {
    let request = createAddressRequest();
    RestService.post(
      RemoteURL.CUSTOMER.ADDRESS.CREATE + "/" + customerId,
      request,
      extractResponse,
      errorFunction
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      registerAddress();
    }
  }

  function errorHandler(error) {}

  function getAllDivision() {
    function extractData(result) {
      let i = 0;
      let divisions = [];
      for (i; i < result.length; i++) {
        divisions.push(Object.assign(new LocationDomain.Division(), result[i]));
      }
      divisions.sort(sortLocationDomainObj);
      setAllDivision(divisions);
    }

    RestService.get(DIVISION_API_URL, extractData, errorHandler, false);
  }

  useEffect(() => {
    if (allDivision.length === 0) {
      getAllDivision();
    }
  });

  return (
    <div className="Registration h-100">
      <br></br>
      <br></br>
      <h4 className="singleRowField">
        <strong>Add new Address:</strong>
      </h4>
      <br></br>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <fieldset className="scheduler-border">
          <legend className="scheduler-border"> Address:</legend>
          <Form.Group size="lg">
            <Form.Label>Address</Form.Label>
            <AddressForm
              allDivision={allDivision}
              showName={true}
              name={name}
              setName={setName}
              flatNo={flatNo}
              setFlatNo={setFlatNo}
              houseNo={houseNo}
              setHouseNo={setHouseNo}
              roadNo={roadNo}
              setRoadNo={setRoadNo}
              area={area}
              setArea={setArea}
              upazilaId={upazilaId}
              setUpazilaId={setUpazilaId}
              isZoneNeeded={true}
              postCode={postCode}
              setPostCode={setPostCode}
              formReadOnly={false}
            />
          </Form.Group>
        </fieldset>
        <Button className="singleRowField" block size="lg" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};
