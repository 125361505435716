import React from 'react'
import useQuery from '../../@commlink/hooks/useQuery'
import Tabs from '../../@commlink/components/Tabs';
import Last24Hours from './tabs/Last24Hours';
import Weekly from './tabs/Weekly';
import MonthlyMaxUtilization from './tabs/MonthlyMaxUtilization';
const tabConfig = [
    {
        key: "LAST_24_HOURS",
        label: "Last 24 hours",
    },
    {
        key: "WEEKLY",
        label: "Weekly",
    },
    {
        key: "MONTHLY_MAX_UTILIZATIONLY",
        label: "Max Utilization",
    }
];

export default function Utilization() {
    const [searchParams, setSearchParams] = useQuery();
    let activeTab = searchParams.get("tab") ;
    if(!tabConfig.find(tab => tab.key === activeTab)){
        activeTab = tabConfig[0].key;
    }
  return (
    <div>
        <Tabs config={tabConfig} activeTab={activeTab} onTabChange={(activeTabKey) => {setSearchParams({tab: activeTabKey})}} />
        <div>
            {activeTab === "LAST_24_HOURS" && (<Last24Hours />)}
            {activeTab === "WEEKLY" && (<Weekly />)}
            {activeTab === "MONTHLY_MAX_UTILIZATIONLY" && (<MonthlyMaxUtilization />)}
        </div>
    </div>
  )
}


