import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import CustomerSupportDetail from "../common/CustomerSupportDetail";
import { RequiredMarker } from "../common/form/RequiredMarker";
import { CustomerSupportIssueStatus } from "../domainObject/DomainDataType";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";

class EmployeePortalCustomerSupportDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supportInfo: null,
      showSupportCloseForm: false,
      supportCloseFormValidate: false,
      status: null,
      remark: null,
    };
  }

  extractSuccessResponse(response) {
    NotificationService.show(
      NotificationMessage.CUSTOMER_SUPPORT_ISSUE_CLOSED,
      NotificationType.SUCCESS
    );
    window.location.reload();
  }

  submit() {
    let url =
      RemoteURL.CUSTOMER.SUPPORT.CLOSE + "/" + this.state.supportInfo.id;
    let requestBody = {
      status: this.state.status,
      remark: this.state.remark,
    };

    RestService.put(
      url,
      requestBody,
      this.extractSuccessResponse.bind(this),
      NotificationService.showRestServiceError
    );
  }

  submitSupportForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      supportCloseFormValidate: true,
    });
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      this.submit();
    }
  }

  handleSupportFormClose() {
    this.setState({
      showSupportCloseForm: false,
    });
  }

  getSupportCloseForm() {
    return (
      <Form
        noValidate
        validated={this.state.supportCloseFormValidate}
        onSubmit={this.submitSupportForm.bind(this)}
      >
        <Form.Group controlId="formBasicSelect">
          <Form.Label> Status</Form.Label>
          <RequiredMarker />
          <Form.Control
            as="select"
            value={this.state.status}
            onChange={(e) => {
              this.setState({
                status: e.target.value,
              });
            }}
            required
          >
            <option value={""}>Click to Select Status</option>
            <option value={CustomerSupportIssueStatus.RESOLVED.key}>Resolve</option>
            <option value={CustomerSupportIssueStatus.REJECTED.key}>Reject</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select status
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Remark</Form.Label> <RequiredMarker />
          <Form.Control
            as="textarea"
            rows={10}
            value={this.state.remark}
            onChange={(e) => {
              this.setState({
                remark: e.target.value,
              });
            }}
            required
            maxLength="1000"
          />
          <Form.Control.Feedback type="invalid">
            Please input remark
          </Form.Control.Feedback>
        </Form.Group>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={this.handleSupportFormClose.bind(this)}
          >
            Close
          </Button>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    );
  }

  supportCloseForm() {
    return (
      <Modal
        show={this.state.showSupportCloseForm}
        onHide={this.handleSupportFormClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Close</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.getSupportCloseForm()}</Modal.Body>
      </Modal>
    );
  }

  setSupportInfo(supportInfo) {
    this.setState({
      supportInfo: supportInfo,
    });
  }
  showSupportCloseForm() {
    this.setState({
      showSupportCloseForm: true,
    });
  }

  closeSupport() {
    if (
      this.state.supportInfo != null &&
      this.state.supportInfo.status === CustomerSupportIssueStatus.PENDING.key
    ) {
      return (
        <Button
          variant="primary"
          onClick={this.showSupportCloseForm.bind(this)}
        >
          Close
        </Button>
      );
    }
  }

  render() {
    return (
      <div>
        <CustomerSupportDetail
          supportId={this.props.match.params.supportId}
          setSupportInfo={null}
          closeIssue={null}
        />
        {this.supportCloseForm()}
      </div>
    );
  }
}

export default withRouter(EmployeePortalCustomerSupportDetail);
