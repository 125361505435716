import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { withRouter } from "react-router";
import CustomerDetailInfo from "./CustomerDetailInfo";
import CustomerAttachmentView from "./CustomerAttachmentView";
import { RoleService } from "../domainObject/Role";
import RestService, { RemoteURL } from "../helper/RestService";
import {
  CustomerStatus,
  OrderedCustomerRegistrationStatus,
} from "../domainObject/DomainDataType";
import NotificationService from "../helper/NotificationService";

const CUSTOMER_INFO_API = RemoteURL.CUSTOMER.GET.Detail;

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationData: null,
      customerInfo: null,
      eligibleToVerify: false,
      eligibleToActivate: false,
      registrationStatus: OrderedCustomerRegistrationStatus.COMPLETE,
    };
  }

  extractRegistrationStatus(response) {
    this.setState({
      registrationStatus: response.status,
    });
  }

  getRegistrationStatus() {
    RestService.get(
      RemoteURL.CUSTOMER.REGISTRATION.STATUS + "/" + this.state.customerInfo.id,
      this.extractRegistrationStatus.bind(this),
      NotificationService.showRestServiceError
    );
  }

  extractCustomerInfoResponse(result) {
    this.setState({
      customerInfo: result,
    });
    this.extractVerificationData(result);
    if (result.status === CustomerStatus.ON_BOARDING) {
    }
  }

  refreshCustomerInfo() {
    this.fetchCustomerInfo();
  }

  updateVerificationData() {
    this.fetchCustomerInfo();
  }

  extractVerificationData(customerInfo) {
    if (this.state.verificationData == null) {
      this.setState({
        verificationData: {
          version: 0,
          data: customerInfo.attachmentVerificationLog,
        },
      });
    } else {
      this.setState((prevState) => ({
        verificationData: {
          data: customerInfo.attachmentVerificationLog,
          version: prevState.verificationData.version + 1,
        },
      }));
    }
  }

  errorResponse(error) {}

  fetchCustomerInfo() {
    let fullUrl = CUSTOMER_INFO_API + "/" + this.props.match.params.customerId;
    RestService.get(
      fullUrl,
      this.extractCustomerInfoResponse.bind(this),
      this.errorResponse.bind(this)
    );
  }

  componentDidMount() {
    this.fetchCustomerInfo();

    this.setState({
      eligibleToVerify: RoleService.isSalesAndMarketingEmployee(),
    });
  }

  render() {
    return (
      <div>
        <CardGroup>
          <Card body>
            <CustomerDetailInfo
              verificationData={this.state.verificationData}
              customerInfo={this.state.customerInfo}
              eligibleToVerify={this.state.eligibleToVerify}
              refreshCustomerInfo={this.refreshCustomerInfo.bind(this)}
              registrationStatus={this.state.registrationStatus}
            />
          </Card>

          <Card body>
            <CustomerAttachmentView
              verificationData={this.state.verificationData}
              updateVerificationData={this.updateVerificationData.bind(this)}
              customerInfo={this.state.customerInfo}
              eligibleToVerify={this.state.eligibleToVerify}
            />
          </Card>
        </CardGroup>
      </div>
    );
  }
}

export default withRouter(CustomerDetail);
