import React from "react";
import { Route, Switch } from "react-router";

import CustomerActivationPanel from "../employeePortal/customerActivation/ConnectionActivationPanel";
import CustomerDetail from "../employeePortal/CustomerDetail";
import CustomerReportDataInfo from "../employeePortal/CustomerReportDataInfo";
import EmployeeInvoiceListDataInfo from "../employeePortal/EmployeeInvoiceListDataInfo";
import EmployeePortalPage, {
  SidebarMenuItem,
} from "../employeePortal/EmployeePortalPage";
import EmployeePortalInvoiceDetail from "../employeePortal/EmployeePortalInvoiceDetail";
import { Routes } from "./Routes";
import { CustomerOfflineRegistration } from "../customerRegistration/CustomerOfflineRegistration";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import ConnectionActivationRequestPanel from "../employeePortal/ConnectionActivationRequestPanel";
import CustomerVerificationReqDataInfo from "../employeePortal/CustomerVerificationReqDataInfo";
import { EmployeeAddConnection } from "../employeePortal/EmployeeAddConnection";
import EmployeeUnpaidInvoiceListDataInfo from "../employeePortal/EmployeeUnpaidInvoiceListDataInfo";
import EmplPorCustSupportListDataInfo from "../employeePortal/EmplPortCustomerSupportListDataInfo";
import EmployeePortalCustomerSupportDetail from "../employeePortal/EmployeePortalCustomerSupportDetail";
import EmployeeDiscardedConnectionListPanel from "../employeePortal/EmployeeDiscardedConnectionListPanel";
import EmployeeMonthlyBillList from "../employeePortal/EmployeeMonthlyBillList";
import EmployeeConnectionListPanel from "../employeePortal/EmployeeConnectionListPanel";
import EmployeePrepaidConnectionListPanel from "../employeePortal/EmployeePrepaidConnectionListPanel";
import ConnectionDetailPanel from "../common/ConnectionDetailPanel";
import CustomerAllInvoiceList from "../employeePortal/CustomerAllInvoiceList";
import Utilization from "../btrcPortal/utilization";

export const EmployeePortalRoutes = (props) => {
  return (
    <div>
      <Switch>
        <Route path={Routes.EMPLOYEE_PORTAL.HOME} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={CustomerReportDataInfo}
              />
            }
          />
        </Route>
        <Route path={Routes.EMPLOYEE_PORTAL.CUSTOMER_LIST} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={CustomerReportDataInfo}
              />
            }
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CUSTOMER_VERIFICATION_REQUEST}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_REQUEST_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={CustomerVerificationReqDataInfo}
              />
            }
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CUSTOMER_DETAIL + "/:customerId"}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_DETAIL}
            currentPanel={<CustomerDetail />}
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CONNECTION_LIST}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CONNECTION_LIST}
            currentPanel={<EmployeeConnectionListPanel />}
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CONNECTION_ACTIVATION_REQUEST}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CONNECTION_ACTIVATION_LIST}
            currentPanel={<ConnectionActivationRequestPanel />}
          />
        </Route>

        <Route
          path={Routes.EMPLOYEE_PORTAL.CONNECTION_PREPAID_LIST}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CONNECTION_PREPAID_LIST}
            currentPanel={<EmployeePrepaidConnectionListPanel />}
          />
        </Route>

        <Route
          path={Routes.EMPLOYEE_PORTAL.CONNECTION_DISCARDED_LIST}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CONNECTION_DISCARDED_LIST}
            currentPanel={<EmployeeDiscardedConnectionListPanel />}
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.ADD_CONNECTION + "/:customerId"}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.ADD_CONNECTION}
            currentPanel={<EmployeeAddConnection />}
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CONNECTION_DETAIL + "/:connectionId"}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CONNECTION_DETAIL}
            currentPanel={<ConnectionDetailPanel />}
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CUSTOMER_ACTIVATION + "/:customerId"}
          exact
        >
          <EmployeePortalPage
            activePanel={""}
            currentPanel={<CustomerActivationPanel />}
          />
        </Route>

        <Route path={Routes.EMPLOYEE_PORTAL.CUSTOMER_REGISTRATION} exact>
          <EmployeePortalPage
            activePanel={""}
            currentPanel={<CustomerOfflineRegistration />}
          />
        </Route>

        <Route path={Routes.EMPLOYEE_PORTAL.INVOICE_LIST} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.INVOICE_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={EmployeeInvoiceListDataInfo}
              />
            }
          />
        </Route>
        <Route path={Routes.EMPLOYEE_PORTAL.UNPAID_INVOICE_LIST} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.UNPAID_INVOICE_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={EmployeeUnpaidInvoiceListDataInfo}
              />
            }
          />
        </Route>

        <Route path={Routes.EMPLOYEE_PORTAL.MONTHLY_BILL_LIST} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.MONTHLY_BILL_LIST}
            currentPanel={
              <EmployeeMonthlyBillList />
            }
          />
        </Route>
        <Route path={Routes.EMPLOYEE_PORTAL.CUSTOMER_INVOICE_LIST} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_INVOICE_LIST}
            currentPanel={
              <CustomerAllInvoiceList />
            }
          />
        </Route>

        <Route
          path={Routes.EMPLOYEE_PORTAL.INVOICE_DETAIL + "/:invoiceId"}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.INVOICE_DETAIL}
            currentPanel={<EmployeePortalInvoiceDetail />}
          />
        </Route>
        <Route path={Routes.EMPLOYEE_PORTAL.CUSTOMER_SUPPORT_LIST} exact>
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_SUPPORT_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={EmplPorCustSupportListDataInfo}
              />
            }
          />
        </Route>
        <Route
          path={Routes.EMPLOYEE_PORTAL.CUSTOMER_SUPPORT_DETAIL + "/:supportId"}
          exact
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.CUSTOMER_SUPPORT_DETAIL}
            currentPanel={<EmployeePortalCustomerSupportDetail />}
          />
        </Route>
        <Route
          exact
          path={Routes.EMPLOYEE_PORTAL.BTRC_UTILIZATION}
        >
          <EmployeePortalPage
            activePanel={SidebarMenuItem.BTRC_UTILIZATION}
            currentPanel={<Utilization />}
          />
        </Route>
      </Switch>
    </div>
  );
};
