
import React from "react";
import { withRouter } from "react-router";
import { RemoteURL } from "../helper/RestService";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import { AccountType, ConnectionStatus } from "../domainObject/DomainDataType";
import {
    textFilter,
    dateFilter,
    selectFilter,
} from "react-bootstrap-table2-filter";
import { numberNotAssignedFormatter } from "../customerPortal/CustomerConnectionPanel";
import { Routes } from "../route/Routes";
import { Link } from "react-router-dom";



export function employeeConnectionDetailButtonAdd(cell, row) {
    return (
        <Link
            to={{
                pathname: Routes.EMPLOYEE_PORTAL.CONNECTION_DETAIL + "/" + row.id,
            }}
        >
            Detail
        </Link>);
}

export const CONNECTION_TABLE_FILTER_OPTION = {
    AccountType: {
        key: "productInfo.accountType",
        option: {
            0: AccountType.PRE_PAID,
            1: AccountType.POST_PAID
        },
    },
    StatusType: {
        key: "status",
        option: {
            0: ConnectionStatus.WAITING_PRE_PAYMENT,
            1: ConnectionStatus.WAITING_ACTIVATION,
            2: ConnectionStatus.ACTIVE,
            3: ConnectionStatus.SUSPENDED,
            4: ConnectionStatus.DELETED,
        },
    },
}

const CONNECTION_TABLE_COLUMNS = [
    {
        dataField: "createdDate",
        text: "Creation time",
        filter: dateFilter(),
        sort: true,
    },
    {
        dataField: "customer.user.userName",
        text: "Customer id",
        sort: true,
        formatter: numberNotAssignedFormatter,
        filter: textFilter(),
    },
    {
        dataField: "iptspNumber",
        text: "IPTSP number",
        sort: true,
        formatter: numberNotAssignedFormatter,
        filter: textFilter(),
    },
    {
        dataField: "productInfo.accountType",
        text: "Product Type",
        sort: true,
        filter: selectFilter({
            options: CONNECTION_TABLE_FILTER_OPTION.AccountType.option,
        }),
    },

    {
        dataField: "status",
        text: "Status",
        sort: true,
        filter: selectFilter({
            options: CONNECTION_TABLE_FILTER_OPTION.StatusType.option,
        }),

    },
    {
        dataField: "",
        text: "Detail",
        isDummyField: true,
        formatter: employeeConnectionDetailButtonAdd
    },
];

export const CONNECTION_TABLE_DEFAULT_SORTED = [
    {
        dataField: "createdDate",
        order: "desc",
    },
];

export const EMPLOYEE_CONNECTION_LIST_DATA_INFO = {
    remoteApiUrl: RemoteURL.CONNECTION.ALL,
    SelectFilterOption: CONNECTION_TABLE_FILTER_OPTION,

    defaultSorted: CONNECTION_TABLE_DEFAULT_SORTED,
    columns: CONNECTION_TABLE_COLUMNS,
};

class EmployeeConnectionListPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    render() {
        return (
            <div>
                <ReportWithSearchAndFilterTable
                    dataInfo={EMPLOYEE_CONNECTION_LIST_DATA_INFO} />
            </div>
        );
    }
}

export default withRouter(EmployeeConnectionListPanel);
