import React from "react";
import BtrcPortal, { SidebarMenuItem } from "../btrcPortal/BtrcPortal";

import { Switch } from "react-router-dom";
import MonthlyReport from "../btrcPortal/monthlyReport/MonthlyReport";
import CdrReportDataInfo from "../btrcPortal/CdrReportDataInfo";

import ClientReportDataInfo from "../btrcPortal/ClientReportDataInfo";
import ActiveRegistrationDataInfo from "../btrcPortal/ActiveRegistrationDataInfo";
import ProtectedRoute from "../common/route/ProtectedRoute";
import ActiveCallReport from "../btrcPortal/ActiveCallReport";
import { Role } from "../domainObject/Role";
import { Routes } from "./Routes";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import Utilization from "../btrcPortal/utilization";

export const BtrcPortalRoutes = (props) => {
  return (
    <div>
      <Switch>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.HOME}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.MONTHLY_REPORT}
            currentPanel={<MonthlyReport />}
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.MONTHLY_REPORT}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.MONTHLY_REPORT}
            currentPanel={<MonthlyReport />}
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.CDR_REPORT}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.CDR_REPORT}
            currentPanel={
              <ReportWithSearchAndFilterTable dataInfo={CdrReportDataInfo} />
            }
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.CLIENT_REPORT}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.CLIENT_REPORT}
            currentPanel={
              <ReportWithSearchAndFilterTable dataInfo={ClientReportDataInfo} />
            }
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.ACTIVE_REGISTRATION}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.ACTIVE_REGISTRATION}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={ActiveRegistrationDataInfo}
              />
            }
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.ACTIVE_CALLS}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.ACTIVE_CALLS}
            currentPanel={<ActiveCallReport />}
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.BTRC_PORTAL.UTILIZATION}
          roles={[Role.BTRC]}
        >
          <BtrcPortal
            activePanel={SidebarMenuItem.UTILIZATION}
            currentPanel={<Utilization />}
          />
        </ProtectedRoute>
      </Switch>
    </div>
  );
};
