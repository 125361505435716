

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router";


export const ModalType = {
    CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
    SUCCESS_MODAL: "SUCCESS_MODAL",
    LOADING_MODAL: "LOADING_MODAL"
}

class CustomModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.openModal
        }
    }

    confiramtionFunction() {
        if (this.props.confiramtionFunction != null) {
            this.props.confiramtionFunction();
        }
        this.closeModal();
    }

    openModal() {
        this.setState({
            showModal: true
        })
    }

    closeModal() {
        if (this.props.cancelFunction != null) {
            this.props.cancelFunction();
        }
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <div>
                {this.props.modalType === ModalType.CONFIRMATION_MODAL ?
                    <div>
                        <Button onClick={this.openModal.bind(this)}>
                            {this.props.buttonText != null ? this.props.buttonText : 'Open Modal'}</Button>

                        <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                            <Modal.Header closeButton>
                                {this.props.heading != null ? this.props.heading : 'Confirm'}
                            </Modal.Header>
                            <Modal.Body>
                                {this.props.message != null ?
                                    this.props.message
                                    : 'Do you want to continue?'}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn btn-info mr-auto"
                                    onClick={this.confiramtionFunction.bind(this)}
                                >
                                    {this.props.successButtonText != null ?
                                        this.props.successButtonText
                                        : 'Continue'}
                                </Button>
                                <Button
                                    className="btn btn-info"
                                    variant="danger"
                                    onClick={this.closeModal.bind(this)}
                                >
                                    {this.props.cancelButtonText != null ?
                                        this.props.cancelButtonText
                                        : 'Cancel'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    :

                    <div>
                        {this.props.modalType === ModalType.LOADING_MODAL ?
                            <div>
                                <Modal show={this.props.openControl != null ?
                                    this.props.openControl
                                    : false}>
                                    <Modal.Body>
                                        {this.props.message != null ?
                                            this.props.message
                                            : 'Working.....'}
                                    </Modal.Body>
                                </Modal>
                            </div>
                            : null}
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(CustomModal);