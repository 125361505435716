import { RoleService } from "../domainObject/Role";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";

const IPTSP_AUTH_TOKEN = "pcl.iptsp.auth.token";
const IPTSP_REFRESH_TOKEN = "pcl.iptsp.refresh.token";
const IPTSP_USER_INFO = "pcl.iptsp.user.info";

function checkLoggedIn() {
  try {
    let token = getAuthToken();
    if (token != null) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function hasAuthTokenExpired() {
  try {
    let token = getAuthToken();
    let payload = token.split(".")[1];
    payload = JSON.parse(atob(payload));
    let tokenIsNotExpired =
      payload["exp"] && payload["exp"] > Date.now() / 1000;
    console.log("logged in status =" + payload["exp"]);
    console.log("current time =:" + Date.now() / 1000);
    console.log("logged in status =" + tokenIsNotExpired);
    if (!tokenIsNotExpired) {
      removeAuthToken();
      return true;
    }
    return false;
  } catch (e) {
    return true;
  }
}

function refreshAuthToken(functionToCall) {
  function extractResponse(response) {
    setAuthToken(response.authToken);
    functionToCall();
  }

  function errorHandler(error) {
    function cleanUp() {
      window.location.reload();
    }
    logout(cleanUp, true);
  }

  let req = {};
  req.token = getRefreshToken();

  RestService.post(
    RemoteURL.USER.REFRESH_TOKEN,
    req,
    extractResponse,
    errorHandler,
    false
  );
}

function setAuthToken(token) {
  localStorage.setItem(IPTSP_AUTH_TOKEN, token);
}

function setRefreshToken(token) {
  localStorage.setItem(IPTSP_REFRESH_TOKEN, token);
}

function setUserInfo(userInfo) {
  localStorage.setItem(IPTSP_USER_INFO, JSON.stringify(userInfo));
}

function getUserInfo() {
  return JSON.parse(localStorage.getItem(IPTSP_USER_INFO));
}

function getAuthToken() {
  return localStorage.getItem(IPTSP_AUTH_TOKEN);
}

function getRefreshToken() {
  return localStorage.getItem(IPTSP_REFRESH_TOKEN);
}

function removeAuthToken() {
  localStorage.removeItem(IPTSP_AUTH_TOKEN);
}

function logout(logoutCleanUp, isSystemLogout = false) {
  function cleanUp() {
    removeAuthToken();
    if (logoutCleanUp != null) {
      logoutCleanUp();
    }
    NotificationService.show(
      NotificationMessage.USER_LOGGED_OUT,
      NotificationType.INFO
    );
  }
  if (!isSystemLogout) {
    RestService.post(RemoteURL.USER.LOG_OUT, {}, cleanUp, cleanUp);
  } else {
    cleanUp();
  }
}

const checkUserRole = (role) => {
  let userInfo = authService.getUserInfo();
  if (userInfo.roles.indexOf(role) !== -1) {
    return true;
  } else {
    return false;
  }
};


const authService = {
  logout,
  setAuthToken: setAuthToken,
  setRefreshToken,
  getToken: getAuthToken,
  setUserInfo: setUserInfo,
  getUserInfo,
  checkUserRole,
  isLoggedIn: checkLoggedIn,
  hasAuthTokenExpired,
  refreshAuthToken,
  getRefreshToken,
};

export default authService;
