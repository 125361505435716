import React from "react";
import { CommonFooter } from "../common/CommonFooter";
import { CommonHeader } from "../common/CommonHeader";


class CompanyPortal extends React.Component {
 

  render() {
    return (
      <div>
        <CommonHeader />
        <div>
         <p className="text-xl-center font-weight-bold">This service is not available. Try again later...</p>
        </div>
        <CommonFooter />
      </div>
    );
  }
}

export default CompanyPortal;
