import React from "react";

import {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import {
  CustomerSupportIssueStatus,
  CustomerSupportIssueSummery,
  CustomerSupportIssueType,
} from "../domainObject/DomainDataType";
import { RemoteURL } from "../helper/RestService";
import { Routes } from "../route/Routes";

function buttonAdd(cell, row) {
  return (
    <Link
      to={{
        pathname: Routes.CUSTOMER.PORTAL.SUPPORT_DETAIL + "/" + row.id,
      }}
    >
      Detail
    </Link>
  );
}

export const CustomerSupportFilter = {
  IssueType: {
    key: "type",
    option: {
      0: CustomerSupportIssueType.GENERAL.key,
      1: CustomerSupportIssueType.CONNECTION.key,
    },
  },
  IssueSummery: {
    key: "summery",
    option: {
      0: CustomerSupportIssueSummery.CUSTOMER_REGISTRATION.key,
      1: CustomerSupportIssueSummery.ACCOUNT_CONVERSION.key,
      2: CustomerSupportIssueSummery.CREDIT_LIMIT_INCREASE.key,
      3: CustomerSupportIssueSummery.BILL_PAYMENT_ISSUE.key,
      4: CustomerSupportIssueSummery.REFILL_ISSUE.key,
      5: CustomerSupportIssueSummery.OTHERS.key,
    },
  },
  IssueStatus: {
    key: "status",
    option: {
      0: CustomerSupportIssueStatus.PENDING.key,
      1: CustomerSupportIssueStatus.RESOLVED.key,
      2: CustomerSupportIssueStatus.REJECTED.key,
    },
  },
};

export const CustomerSupportListDataInfo = {
  remoteApiUrl: RemoteURL.CUSTOMER.GET.SUPPORT_SEARCH,
  SelectFilterOption: CustomerSupportFilter,

  defaultSorted: [
    {
      dataField: "createdDate",
      order: "desc",
    },
  ],
  columns: [
    {
      dataField: "createdDate",
      text: "Creation time",
      filter: dateFilter(),
      sort: true,
    },

    {
      dataField: "reference",
      text: "Reference",
      filter: textFilter(),
    },
    {
      dataField: "connectionInfo.iptspNumber",
      text: "Connection number",
      filter: textFilter(),
    },
    {
      dataField: "type",
      text: "Issue type",
      filter: selectFilter({
        options: CustomerSupportFilter.IssueType.option,
      }),
    },
    {
      dataField: "summery",
      text: "Issue summery",
      filter: selectFilter({
        options: CustomerSupportFilter.IssueSummery.option,
      }),
      sort: true,
    },
    // {
    //   dataField: "status",
    //   text: "Issue status",
    //   filter: selectFilter({
    //     options: CustomerSupportFilter.IssueStatus.option,
    //   }),
    //   sort: true,
    // },

    {
      dataField: "",
      text: "Detail",
      isDummyField: true,
      formatter: buttonAdd,
    },
  ],
};

export default CustomerSupportListDataInfo;
