import React, { useCallback, useState } from 'react'
import Chart from "react-apexcharts";
import RestService, { RemoteURL } from '../../../helper/RestService';
import NotificationService from '../../../helper/NotificationService';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';

export default function Last24Hours() {
    const [chartData, setChartData] = useState([]);
    const [spin, setSpin] = useState(false);


    const initData = useCallback(() => {
        setSpin(true);
        const endDate = moment().format('YYYY-MM-DD HH:mm:ss');
        const startDate = moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss');

        let url = RemoteURL.BTRC_PANEL.UTILIZATION_DATE_RANGE + `?startDate=${startDate}&endDate=${endDate}`;

        RestService.get(
            url,
            (data) => {
                updateChartData(data);
            },
            (error) => {
                NotificationService.showRestServiceError(error);
                setSpin(false);
            }
        );


        function updateChartData(data) {
            const _data = data.map((item) => (
                {
                    x: new Date(item.timestamp).getTime(),
                    y: item.count
                }
            ))
            setChartData(_data);
            setSpin(false);
        }
    }, [])
    React.useEffect(() => {
        initData();

        // Update data every 1 minute
        const interval = setInterval(() => {
            initData();
        }, 1000 * 60);
        return () => clearInterval(interval);
    }, [initData]);
    return (
        <div>
            <Card bg="light" text="dark">
                <Card.Header>
                    <div class="row">
                        <div class=" row col-md-12 boxlayout">
                            <div class=" col-md-4"> <span >
                                <FontAwesomeIcon icon={faSync} spin={spin} size="2x" />
                            </span>
                            </div>

                        </div>
                    </div>

                </Card.Header>
                <Card.Body>
                    <div>
                        <Chart
                            options={{
                                noData: {
                                    text: "No data found",
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    offsetX: 0,
                                    offsetY: 0,
                                    style: {
                                        color: "#000000",
                                        fontSize: '14px',
                                        fontFamily: "Helvetica"
                                    }
                                },
                                colors: ['#2E93fA'],
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    title: {
                                        text: 'Date'
                                    },
                                    type: 'datetime',
                                    labels: {
                                        datetimeUTC: false
                                    }
                                },
                                yaxis: {
                                    title: {
                                        text: 'Active Calls'
                                    },
                                    min: 0
                                    // labels: {
                                    //     formatter: function (value, timestamp) {
                                    //         return value
                                    //     }
                                    // }
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 1
                                },
                                tooltip: {
                                    x: {
                                        format: 'dd MMM yyyy HH:mm',
                                    }
                                }
                            }
                            }
                            series={[
                                {
                                    color: '#008FFB',
                                    name: 'Utilization',
                                    data: chartData,
                                }
                            ]}
                            type='area'
                            width="100%"
                            height={520}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
