import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  OrderedCustomerRegistrationStatus,
  OtpType,
} from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";

import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import { OTPInputForm } from "../common/form/OTPInputForm";
import { RequiredMarker } from "../common/form/RequiredMarker";

export const OTPVerification = (props) => {
  const [otp, setOTP] = useState("");
  const [validated, setValidated] = useState(false);

  function extractResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.OTP_VERIFICATION_SUCCESS,
        NotificationType.SUCCESS
      );
      props.updateStatus(OrderedCustomerRegistrationStatus.OTP_VERIFICATION);
    } else {
      let error = {};
      error.message = NotificationMessage.OTP_VERIFICATION_FAIL;
      props.handleServerError(error);
    }
  }

  function verifyOtp() {
    let request = {
      key: props.phoneNumber,
      type: OtpType.CUSTOMER_REGISTRATION,
      otp: otp,
    };
    RestService.post(
      RemoteURL.OTP.VERIFICATION,
      request,
      extractResponse,
      props.handleServerError,
      false,
      true
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      verifyOtp();
    }
  }

  function handleRequestOtpResponse() {}

  function requestOtp() {
    let request = {
      type: OtpType.CUSTOMER_REGISTRATION,
      key: props.phoneNumber,
    };
    RestService.post(
      RemoteURL.OTP.GENERATION,
      request,
      handleRequestOtpResponse,
      NotificationService.showRestServiceError,
      false,
      true
    );
  }

  return (
    <div className="Login h-100">
      <h5 className="singleRowField">
        <strong>Input one time password</strong> <RequiredMarker />
      </h5>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <OTPInputForm value={otp} setValue={setOTP} />
        <div class="container">
          <div class="row">
            <div class="col-xs-8">
              <Button block size="lg" type="submit">
                Verify
              </Button>
            </div>
            <br></br>
            <p>
            If otp is not sent within 5 minutes, request again {" "}
              <Button onClick={() => requestOtp()}>Request otp</Button>
            </p>
          </div>
        </div>
      </Form>
    </div>
  );
};
