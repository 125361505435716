import React from 'react'

// **** Example Config ****
// const config = [
//     {
//         key: "LAST_24_HOURS",
//         label: "Last 24 hours",
//     }
// ]

export default function Tabs({config, activeTab, onTabChange}) {
  return (
    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
            {
                config.map(tab => (
                <button
                    className={
                        "btn btn-sm btn-outline-secondary " + 
                        (activeTab === tab.key ? "active" : "")
                    }
                    onClick={() => {
                        onTabChange(tab.key);
                    }}
                >
                    {tab.label}
                </button>
                ))
            }
            
        </div>
    </div>
  )
}
