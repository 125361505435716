import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import PromotionalWebsite from "./promotionalWebsite/PromotionalWebsite";

import { Routes } from "./portal/route/Routes";

import RedirectRoute from "./portal/common/route/RedirectRoute";
import { SetPageTitle } from "./portal/helper/Utility";
import { PortalRoutes } from "./portal/route/PortalRoutes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



class App extends React.Component {
  constructor(props) {
    super(props);
    SetPageTitle({
      title: "Premium Connectivity Ltd.",
      metaDescription: "Premium Connectivity Ltd.",
    });
  }

  getRoutes() {
    return (
      <Switch>
        <Route exact path={Routes.HOME}>
          <PromotionalWebsite />
        </Route>

        <RedirectRoute path={"/BTRCPortal"} />

        <Route path={"/portal"} component={PortalRoutes} />
      </Switch>
    );
  }

  render() {
    return (
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="App">{this.getRoutes()}</div>
      </BrowserRouter>
    );
  }
}

export default App;
