import React from "react";
import moment from 'moment';

export const EmployeePortalFooter = (props) => {
  return (
    //<footer class="fixed-bottom footer bg-secondary text-center text-lg-start ">
    <footer className="container-fluid w-100 bg-secondary text-light py-3">
      <div class=" text-center p-3 text-white">
        © {moment().format('YYYY')} Copyright:
        <a class="text-white pl-2" style={{paddingLeft:"10px"}} href="https://iptsp.pmcon.net" target="_blank" rel="noopener noreferrer" >
           Premium Connectivity Ltd.
        </a>
      </div>
    </footer>
  );
};
