import React from "react";

import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import {
  CustomerType,
  Gender,
  GovernmentIdType,
  Nationality,
  Occupation,
} from "../domainObject/DomainDataType";
import { REGEX } from "../helper/Utility";
import { RequiredMarker } from "../common/form/RequiredMarker";


export const PersonalInformationForm = (props) => {
  function otherOccupationForm() {
    if (props.occupation === Occupation.OTHER) {
      return (
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Name of Occupation</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            value={props.nameOfOccupation}
            onChange={(e) => {
              props.setNameOfOccupation(e.target.value);
            }}
            placeholder="Name of Occupation"
            required
            readOnly={props.formReadOnly}
            maxLength="30"
          />
          <Form.Control.Feedback type="invalid">
            Please input name of occupation
          </Form.Control.Feedback>
        </Form.Group>
      );
    } else {
      return null;
    }
  }

  function nationalityIdForm() {
    if (props.nationality === Nationality.BANGLADESHI) {
      return (
        <Form.Group controlId="formGridAddress2">
          <Form.Label>National Id</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            placeholder="National Id"
            value={props.governmentId}
            onChange={(e) => {
              let value = e.target.value;
              if (
                value.match(REGEX.REGEX_ANY_ALPHABET_MATCH) === null ||
                value.match(REGEX.REGEX_ANY_ALPHABET_MATCH).length === 0
              ) {
                if (value.length >= 17) {
                  value = value.slice(0, 17);
                }
                props.setGovernmentId(value);
                props.setGovernmentIdType(GovernmentIdType.NATIONAL_ID);
              }
            }}
            required
            readOnly={props.formReadOnly}
            maxLength="17"
            minLength="10"
            pattern={REGEX.NID_MATCH}
          />
          <Form.Control.Feedback type="invalid">
            Please input national id. It must be 10 or 17 digit long. Incase of
            13 digit add your 4 digit birth year infront.
          </Form.Control.Feedback>
        </Form.Group>
      );
    } else if (props.nationality === Nationality.FOREIGNER) {
      return (
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Passport No</Form.Label>
          {" "}<RequiredMarker/>
          <Form.Control
            placeholder="Passport No"
            value={props.governmentId}
            onChange={(e) => {
              props.setGovernmentId(e.target.value);
              props.setGovernmentIdType(GovernmentIdType.PASSPORT_ID);
            }}
            required
            readOnly={props.formReadOnly}
            maxLength="30"
          />
          <Form.Control.Feedback type="invalid">
            Please input passport id
          </Form.Control.Feedback>
        </Form.Group>
      );
    } else {
      return null;
    }
  }

  return (
    <fieldset className="scheduler-border">
      <legend className="scheduler-border">Personal Information:</legend>
      <Row>
        <Col>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>
              {props.customerType === CustomerType.INDIVIDUAL
                ? "Name of Subscriber"
                : "Authorized person"}
            </Form.Label>
            {" "}<RequiredMarker/>
            <Form.Control
              value={props.nameOfSubscriber}
              onChange={(e) => {
                props.setNameOfSubscriber(e.target.value);
              }}
              placeholder="Name"
              required
              readOnly={props.formReadOnly}
              maxLength="49"
            />
            <Form.Control.Feedback type="invalid">
              Please input full name
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formGridAddress2 form-floating">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={props.emailId}
              onChange={(e) => {
                props.setEmailId(e.target.value);
              }}
              placeholder="Email"
              readOnly={props.formReadOnly}
              maxLength="49"
            />
            <Form.Control.Feedback type="invalid">
              Please input email
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicSelect">
            <Form.Label> Occupation</Form.Label>
            {" "}<RequiredMarker/>
            <Form.Control
              as="select"
              value={props.occupation}
              onChange={(e) => {
                props.setOccupation(e.target.value);
              }}
              readOnly={props.formReadOnly}
              disabled={props.formReadOnly}
              required
            >
              <option value={""}>Click to Select Occupation</option>
              <option value={Occupation.GOVERNMENT_SERVICE}>
                Government Service
              </option>
              <option value={Occupation.PRIVATE_SERVICE}>
                Private Service
              </option>
              <option value={Occupation.BUSINESS}>Business</option>
              <option value={Occupation.STUDENT}>Student</option>
              <option value={Occupation.OTHER}>Other</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select an occupation
            </Form.Control.Feedback>
          </Form.Group>
          {otherOccupationForm()}
        </Col>
        <Col>
          <Form.Group controlId="formBasicSelect">
            <Form.Label>Gender</Form.Label>
            {" "}<RequiredMarker/>
            <Form.Control
              as="select"
              value={props.gender}
              onChange={(e) => {
                props.setGender(e.target.value);
              }}
              readOnly={props.formReadOnly}
              disabled={props.formReadOnly}
              required
            >
              <option value={""}>Click to Select Gender</option>
              <option value={Gender.MALE}>Male</option>
              <option value={Gender.FEMALE}>Female</option>
              <option value={Gender.OTHER}>Other</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select gender
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGridAddress2">
            <Form.Label>Date Of Birth(mm/dd/yyyy)</Form.Label>
            {" "}<RequiredMarker/>
            <Form.Control
              type="date"
              value={props.dateOfBirth}
              onChange={(e) => {
                props.setDateOfBirth(e.target.value);
              }}
              placeholder="Date of birth"
              required
              readOnly={props.formReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              Please select date of birth
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicSelect">
            <Form.Label> Nationality</Form.Label>
            {" "}<RequiredMarker/>
            <Form.Control
              as="select"
              value={props.nationality}
              onChange={(e) => {
                props.setNationality(e.target.value);
              }}
              required
              readOnly={props.formReadOnly}
              disabled={props.formReadOnly}
            >
              <option value={""}>Click to Select Nationality</option>
              <option value={Nationality.BANGLADESHI}>Bangladeshi</option>
              <option value={Nationality.FOREIGNER}>Foreigner</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select nationality
            </Form.Control.Feedback>
          </Form.Group>
          {nationalityIdForm()}
        </Col>
      </Row>
    </fieldset>
  );
};
