import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
export default function useQuery() {
    const history = useHistory();
    const location = useLocation();
    const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const setSearchParams = (queryObj, options) => {
        if(options?.replace){
            history.replace({
                pathname: location.pathname,
                search: new URLSearchParams(queryObj).toString()
            });
        }else{
            history.push({
                pathname: location.pathname,
                search: new URLSearchParams(queryObj).toString()
            });
        }
    }
    return [searchParams, setSearchParams]
}