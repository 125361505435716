import React, { useState } from "react";

//********* COMMON CSS BUNDLE */
import "../css/App.css";
import "../css/dashboard.css";
import "../css/RegistrationForm.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-datetime/css/react-datetime.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "../css/Login.css";
//************************* *******************************************************/

import { CommonHeader } from "../common/CommonHeader";
import { CustomerRegistration } from "./CustomerRegistration";
import { CommonFooter } from "../common/CommonFooter";
import {
  CustomerType,
  OrderedCustomerRegistrationStatus,
  RegistrationType,
} from "../domainObject/DomainDataType";
import { Button, Modal } from "react-bootstrap";

export const CustomerRegistrationPage = (props) => {
  const [showCustomerTypeSelectionModal, setShowCustomerTypeSelectionModal] =
    useState(true);
  const [customerType, setCustomerType] = useState(null);

  function selectCustomerType(type) {
    setCustomerType(type);
    setShowCustomerTypeSelectionModal(false);
  }

  function getChoiceModal() {
    return (
      <Modal show={showCustomerTypeSelectionModal}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
         I will use this service:
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-info mr-auto"
            onClick={() => selectCustomerType(CustomerType.INDIVIDUAL)}
          >
            For me
          </Button>
          <Button
            className="btn btn-info"
            onClick={() => selectCustomerType(CustomerType.CORPORATE)}
          >
            For my company(required TIN, BIN and Trade license)
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      <CommonHeader />
      {customerType != null ? (
        <CustomerRegistration
          registrationType={RegistrationType.ONLINE}
          status={OrderedCustomerRegistrationStatus.NEW_REGISTRATION}
          customerType={customerType}
        />
      ) : null}
      <CommonFooter />
      {getChoiceModal()}
    </div>
  );
};
