import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { PersonalAttachment } from "../domainObject/CustomerInfo";
import { OrderedCustomerRegistrationStatus } from "../domainObject/DomainDataType";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { PersonalAttachmentForm } from "./PersonalAttachmentForm";

export const PersonalAttachmentRegistration = (props) => {
  const [validated, setValidated] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [governmentIdFile, setGovernmentIdFile] = useState(null);
  const [signature, setSignature] = useState(null);
  const [isPhotoInvalid, setIsPhotoInvalid] = useState(false);
  const [isSignatureInvalid, setIsSignatureInvalid] = useState(false);
  const [isGovernmentIdInvalid, setIsGovernmentIdInvalid] = useState(false);

  const PERSONAL_ATTACHMENT_UPLOAD_API_URL =
    RemoteURL.CUSTOMER.REGISTRATION.UPLOAD_PERSONAL_ATTACHMENT;

  function extractResponse(response) {
    if (response.isTrue) {
      NotificationService.show(
        NotificationMessage.FILE_UPLOAD_SUCCESS,
        NotificationType.SUCCESS
      );
      props.updateStatus(
        OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED
      );
    } else {
      props.handleServerError();
    }
  }

  function createPersonalAttachment() {
    let personalAttachment = new PersonalAttachment();
    personalAttachment.photo = photo;
    personalAttachment.signature = signature;
    personalAttachment.governmentIdFile = governmentIdFile;
    return personalAttachment;
  }

  function submitPersonalAttachment(customerId, personalAttachment) {
    var data = new FormData();
    data.append("photo", personalAttachment.photo);
    data.append("signature", personalAttachment.signature);
    data.append("governmentId", personalAttachment.governmentIdFile);
    data.append("id", customerId);

    RestService.postFileUpload(
      PERSONAL_ATTACHMENT_UPLOAD_API_URL,
      data,
      extractResponse,
      props.handleServerError,
      false
    );
  }

  function registerPersonalAttachment() {
    let newPersonalAttachment = createPersonalAttachment();
    submitPersonalAttachment(props.customerId, newPersonalAttachment);
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      if (!isPhotoInvalid && !isSignatureInvalid && !isGovernmentIdInvalid) {
        registerPersonalAttachment();
      }else{
        NotificationService.show(NotificationMessage.CHECK_FORM_INPUT, NotificationType.ERROR);
      }
    }
  }

  function personalAttachmentForm() {
    return (
      <PersonalAttachmentForm
        photo={photo}
        setPhoto={setPhoto}
        setIsPhotoInvalid={setIsPhotoInvalid}
        signature={signature}
        setSignature={setSignature}
        setIsSignatureInvalid={setIsSignatureInvalid}
        governmentIdFile={governmentIdFile}
        setIsGovernmentIdInvalid={setIsGovernmentIdInvalid}
        setGovernmentIdFile={setGovernmentIdFile}
        formReadOnly={false}
      />
    );
  }

  return (
    <div className="Registration h-100">
      <br></br>
      <br></br>
      <h5 className="singleRowField">
        <strong>Upload personal documents</strong>
      </h5>
      <br></br>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {personalAttachmentForm()}
        <Button className="singleRowField" block size="lg" type="submit">
          Upload
        </Button>
      </Form>
      <br></br>
      <br></br>
    </div>
  );
};
