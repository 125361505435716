import React from "react";
import { EmployeePortalFooter } from "../common/EmployeePortalFooter";
import { CommonHeader } from "../common/CommonHeader";
import SideBar from "../common/Sidebar";
import { RoleService } from "../domainObject/Role";
import { SetPageTitle } from "../helper/Utility";
import { Routes } from "../route/Routes";

//********* COMMON CSS BUNDLE */
import "../css/App.css";
import "../css/dashboard.css";
import "../css/RegistrationForm.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-datetime/css/react-datetime.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "../css/Login.css";
//************************* *******************************************************/

export const SidebarMenuItem = {
  CUSTOMER_LIST: "CUSTOMER_LIST",
  CUSTOMER_REQUEST_LIST: "CUSTOMER_REQUEST_LIST",
  CUSTOMER_DETAIL: "CUSTOMER_DETAIL",
  CONNECTION_LIST: "CONNECTION_LIST",
  CONNECTION_DISCARDED_LIST: "CONNECTION_DISCARDED_LIST",
  CONNECTION_PREPAID_LIST: "CONNECTION_PREPAID_LIST",
  CONNECTION_DETAIL: "CONNECTION_DETAIL",
  CONNECTION_ACTIVATION_LIST: "CONNECTION_ACTIVATION_LIST",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  ADD_CONNECTION: "ADD_CONNECTION",
  MONTHLY_BILL_LIST: "MONTHLY_BILL_LIST",
  INVOICE_LIST: "INVOICE_LIST",
  UNPAID_INVOICE_LIST: "UNPAID_INVOICE_LIST",
  CUSTOMER_INVOICE_LIST: "CUSTOMER_INVOICE_LIST",
  INVOICE_DETAIL: "INVOICE_DETAIL",
  CUSTOMER_SUPPORT_LIST: "CUSTOMER_SUPPORT_LIST",
  CUSTOMER_SUPPORT_DETAIL: "CUSTOMER_SUPPORT_DETAIL",
  BTRC_UTILIZATION: "BTRC_UTILIZATION"
};

function checkCurrentActive(callingPanel, activePanel) {
  if (callingPanel === activePanel) {
    return "nav-link active";
  } else {
    return "nav-link ";
  }
}

const SideBarMenu = (props) => {
  return (
    <div>
      <li>
        Customer
        <ul>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CUSTOMER_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CUSTOMER_LIST}
            >
              <span data-feather="home"></span>
              All
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CUSTOMER_REQUEST_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CUSTOMER_VERIFICATION_REQUEST}
            >
              <span data-feather="home"></span>
              Verify
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CUSTOMER_DETAIL,
                props.activePanel
              )}
            >
              <span data-feather="home"></span>
              Detail
            </a>
          </li>
          {RoleService.isSalesAndMarketingEmployee() ? (
            <li>
              <a
                className={checkCurrentActive(
                  SidebarMenuItem.ADD_CUSTOMER,
                  props.activePanel
                )}
                href={Routes.EMPLOYEE_PORTAL.CUSTOMER_REGISTRATION}
              >
                <span data-feather="home"></span>
                Add
              </a>
            </li>
          ) : null}
        </ul>
      </li>
      <li>
        Connection
        <ul>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CONNECTION_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CONNECTION_LIST}
            >
              <span data-feather="home"></span>
              All
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CONNECTION_PREPAID_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CONNECTION_PREPAID_LIST}
            >
              <span data-feather="home"></span>
              Prepaid Refill
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CONNECTION_DISCARDED_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CONNECTION_DISCARDED_LIST}
            >
              <span data-feather="home"></span>
              Discarded
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CONNECTION_ACTIVATION_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CONNECTION_ACTIVATION_REQUEST}
            >
              <span data-feather="home"></span>
              Activate
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.ADD_CONNECTION,
                props.activePanel
              )}
            >
              <span data-feather="home"></span>
              Add
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CONNECTION_DETAIL,
                props.activePanel
              )}
            >
              <span data-feather="home"></span>
              Detail
            </a>
          </li>
        </ul>
      </li>
      <li>
        Invoice
        <ul>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.INVOICE_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.INVOICE_LIST}
            >
              <span data-feather="home"></span>
              All
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.UNPAID_INVOICE_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.UNPAID_INVOICE_LIST}
            >
              <span data-feather="home"></span>
              Unpaid
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.MONTHLY_BILL_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.MONTHLY_BILL_LIST}
            >
              <span data-feather="home"></span>
              Monthly bill
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CUSTOMER_INVOICE_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CUSTOMER_INVOICE_LIST}
            >
              <span data-feather="home"></span>
              Search By Customer
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.INVOICE_DETAIL,
                props.activePanel
              )}
            >
              <span data-feather="home"></span>
              Detail
            </a>
          </li>
        </ul>
      </li>
      <li>
        Customer support
        <ul>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CUSTOMER_SUPPORT_LIST,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.CUSTOMER_SUPPORT_LIST}
            >
              <span data-feather="home"></span>
              All
            </a>
          </li>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.CUSTOMER_SUPPORT_DETAIL,
                props.activePanel
              )}
            >
              <span data-feather="home"></span>
              Detail
            </a>
          </li>
        </ul>
      </li>
      <li>
        Btrc
        <ul>
          <li>
            <a
              className={checkCurrentActive(
                SidebarMenuItem.BTRC_UTILIZATION,
                props.activePanel
              )}
              href={Routes.EMPLOYEE_PORTAL.BTRC_UTILIZATION}
            >
              <span data-feather="home"></span>
              Utilization
            </a>
          </li>
        </ul>
      </li>
    </div>
  );
};

class EmployeePortalPage extends React.Component {
  constructor(props) {
    super(props);
    SetPageTitle({
      title: "Employee portal",
      metaDescription: "Employee portal",
    });
  }

  render() {
    return (
      <div>
        <CommonHeader />
        <div class="container-fluid">
          <div class="row ">
            <SideBar
              children={<SideBarMenu activePanel={this.props.activePanel} />}
            />
            <main
              role="main"
              class="mainBlock col-md-9 ml-sm-auto col-lg-10 pt-3 px-4"
            >
              {this.props.currentPanel}
            </main>
            <EmployeePortalFooter/>
          </div>
        </div>
        
      </div>
    );
  }
}

export default EmployeePortalPage;
