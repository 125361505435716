import React from "react";
import { Redirect, Route } from "react-router-dom";
import authService from "../../login/LoginService";
import { Routes } from "../../route/Routes";

function ProtectedRoute(props) {
  let currentUser = authService.getUserInfo();
  if (!authService.isLoggedIn()) {
    return (
      <Redirect
        to={{
          pathname: Routes.LOG_IN,
          state: { from: props.location },
        }}
      />
    );
  }

  if (props.roles && !authorized()) {
    if (currentUser.roles.length > 0) {
      return (
        <Redirect
          to={{
            pathname: currentUser.roles[0].defaultPath,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: Routes.LOG_IN,
            state: { from: props.location },
          }}
        />
      );
    }
  }

  return <Route {...props} />;

  function authorized() {
    let usrRoleIndex = 0;
    let authRoleIndex = 0;
    for (
      usrRoleIndex;
      usrRoleIndex < currentUser.roles.length;
      usrRoleIndex++
    ) {
      for (authRoleIndex; authRoleIndex < props.roles.length; authRoleIndex++) {
        if (
          currentUser.roles[usrRoleIndex].value.toUpperCase() ===
          props.roles[authRoleIndex].value.toUpperCase()
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

export default ProtectedRoute;
