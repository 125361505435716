import React from "react";
import { Form } from "react-bootstrap";
import { REGEX } from "../../helper/Utility";
import { RequiredMarker } from "./RequiredMarker";

export const PasswordForm = (props) => {
  function checkAndSetPassword(value) {
    props.setValue(value);
  }

  return (
    <div>
      <Form.Group size="lg" controlId="password">
        <Form.Label>
          {props.label != null ? props.label : "Password"}
        </Form.Label>
        {" "}<RequiredMarker/>
        <Form.Control
          type="password"
          value={props.value}
          required
          onChange={(e) => checkAndSetPassword(e.target.value)}
          pattern={REGEX.PASSWORD_CHECK}
        />
        <Form.Control.Feedback type="invalid">
          Please input password. It must be between 6 to 20 characters which
          contain at least one numeric digit, one uppercase and one lowercase
          letter
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
