import React from "react";

export const VerticalTableRow = (props) => {
    return(
        <tr>
        <td>{props.name}</td>
        <td>{props.data}</td>
      </tr>
    )
}
