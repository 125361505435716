import React from "react";
import { RemoteURL } from "../../helper/RestService";
import {
  CheckValidityWithEmptyStringDefault, CheckValidityWithZeroDefault,
} from "../../helper/Utility";

class ViewClass {
  nameOfIcx;
  numberOfEone;
}

const IcxConnectivityAndCapaCityDataInfo = {
  thead: (
    <thead>
      <tr>
        <th>Name Of ICX </th>
        <th>Number of E1 </th>
      </tr>
    </thead>
  ),

  transformRemoteDataToTableRows: function (data) {
    let rows = convertToArray(data);
    return rows;

    function convertToArray(data) {
      let rows = [];
      let i = 0;
      for (i; i < data.length; i++) {
        let row = [];
        row.push(CheckValidityWithEmptyStringDefault(data[i].name));
        row.push(CheckValidityWithZeroDefault(data[i].noOfE1));
        rows.push(row);
      }

      return rows;
    }
  },
  getRemoteUrl: function (year, month) {
    return RemoteURL.BTRC_PANEL.MONTHLY_REPORT.ICX_CONNECTIVITY_CAPACITY;
  },
};

export default IcxConnectivityAndCapaCityDataInfo;
