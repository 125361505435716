import React from "react";
import { withRouter } from "react-router";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import CustomerExistingConnectionReqDataInfo from "./CustomerExistingConnectionReqDataInfo";

class CustomerInvoiceListPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <ReportWithSearchAndFilterTable dataInfo={CustomerExistingConnectionReqDataInfo} />
    );
  }
}

export default withRouter(CustomerInvoiceListPanel);
