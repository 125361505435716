import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { PhoneNumberForm } from "../common/form/PhoneNumberForm";

export const OTPGenerationForm = (props) => {
  const [contactNo, setContactNo] = useState("");
  const [validated, setValidated] = useState(props.formValidationStatus);

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      props.formSubmissionFunction(contactNo);
    }
  }

  return (
    <div className="Login h-100">
      <h5 className="singleRowField">
        <strong>
          To verify it is you, an one time password will be sent to below number
          via SMS
        </strong>
      </h5>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <PhoneNumberForm
          value={contactNo}
          setValue={setContactNo}
          isInvalid={props.contactNoInvalid}
          readOnly={false}
        />

        <Button block size="lg" type="submit">
          Request
        </Button>
      </Form>
    </div>
  );
};
