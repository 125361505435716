import { toast } from "react-toastify";

export const NotificationType = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INFO: "INFO",
};

export const NotificationMessage = {
  FORM_SUBMISSION_SUCCESS: "Submitted successfully",
  SOMETHING_WENT_WRONG: "!! Something went wrong !!!",
  OTP_REQUESTED: "OTP requested",
  OTP_REQUEST_FAIL: "OTP generation failed. Please try again",
  OTP_VERIFICATION_SUCCESS: "OTP verification success",
  OTP_VERIFICATION_FAIL:
    "OTP verification failed. Please try again or click resend.",
  COMPANY_CREATION_SUCCESS: "Successfully created new company",
  PERSONAL_INFORMATION_REGISTRATION_SUCCESS:
    "Successfully registered personal information",
  FILE_UPLOAD_SUCCESS: "Successfully uploaded",
  CONNECTION_REQUESTED_SUCCESS: "Successfully requested connection",
  CUSTOMER_ATTACHMENT_VERIFICATION_SUCCESS: "Successfully verified",
  PASSWORD_CHANGE_SUCCESSFUL: "Successfully changed password",
  PASSWORD_CHANGE_FAILED: "Failed to change password. Please check form inputs",
  ADDRESS_REGISTERED: "Successfully registered address",
  CUSTOMER_SUPPORT_ISSUE_CLOSED: "Successfully closed issue",
  USER_LOGGED_OUT: "You have been logged out. Please log in again",
  CHECK_FORM_INPUT: "Check form input",
};

const show = (message, type) => {
  let toastType = toast.TYPE.DEFAULT;
  switch (type) {
    case NotificationType.SUCCESS:
      toastType = toast.TYPE.SUCCESS;
      break;
    case NotificationType.ERROR:
      toastType = toast.TYPE.ERROR;
      break;
    case NotificationType.INFO:
      toastType = toast.TYPE.INFO;
      break;
    default:
      break;
  }
  toast(message, {
    type: toastType,
  });
};

const showRestServiceError = (error) => {
  if (error.message != null) {
    toast(error.message, {
      type: toast.TYPE.ERROR,
    });
  } else {
    toast(NotificationMessage.SOMETHING_WENT_WRONG, {
      type: toast.TYPE.ERROR,
    });
  }
};

const NotificationService = {
  show,
  showRestServiceError,
};

export default NotificationService;
