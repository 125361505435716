import React from "react";
import { Card, CardGroup, Col, Row, Table } from "react-bootstrap";
import { withRouter } from "react-router";
import NotificationService from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";

class CustomerSupportDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supportInfo: null,
    };
  }

  extractSupportDetailResponse(result) {
    this.setState({
      supportInfo: result,
    });
    if (this.props.setSupportInfo != null) {
      this.props.setSupportInfo(result);
    }
  }

  errorResponse(error) {
    NotificationService.showRestServiceError(error);
  }

  fetchSupportDetail() {
    let fullUrl =
      RemoteURL.CUSTOMER.SUPPORT.DETAIL + "/" + this.props.supportId;
    RestService.get(
      fullUrl,
      this.extractSupportDetailResponse.bind(this),
      this.errorResponse.bind(this)
    );
  }

  componentDidMount() {
    this.fetchSupportDetail();
  }

  showGeneralInfo() {
    if (this.state.supportInfo !== null) {
      return (
        <CardGroup>
          <Card>
            <Card.Header>
              <p>
                Reference: <strong>{this.state.supportInfo.reference}</strong>
              </p>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <p>
                  Name of customer:{" "}
                  <strong>
                    {this.state.supportInfo.customerInfo.personalInfo.name}
                  </strong>
                </p>
                <p>
                  Status: <strong>{this.state.supportInfo.status}</strong>
                </p>
                <p>
                  Issue type: <strong>{this.state.supportInfo.type}</strong>
                </p>
                <p>
                  Issue summery:{" "}
                  <strong>{this.state.supportInfo.summery}</strong>
                </p>
                {this.state.supportInfo.connectionInfo != null ? (
                  <p>
                    Connection reference:{" "}
                    <strong>
                      {this.state.supportInfo.connectionInfo.reference}
                    </strong>
                  </p>
                ) : null}
                <p>
                  Detail: <strong>{this.state.supportInfo.detail}</strong>
                </p>
                <p>
                  Remark: <strong>{this.state.supportInfo.remark}</strong>
                </p>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {this.props.closeIssue != null ? this.props.closeIssue() : null}
            </Card.Footer>
          </Card>
        </CardGroup>
      );
    }
  }

  render() {
    return (
      <div>
        <Row xs={1} md={1} className="g-4">
          <Col>{this.showGeneralInfo()}</Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CustomerSupportDetail);
